<template>
  <div :class="['time-scale-input', setClass]">
    <input
      :ref="$id('input')"
      :value="currentValue"
      type="number"
      :min="minPeriodValue"
      :max="maxPeriodValue"
      @input="setDataFromInput"
    />

    <div class="period">
      <button :class="getClassList('days')" @click="setPeriodType('days')">days</button>
      <span class="separator"></span>
      <button :class="getClassList('months')" @click="setPeriodType('months')">months</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputPeriod',

  props: {
    selectedPeriod: {
      type: Object,
      required: true,
    },

    widthScaleLine: {
      type: Number,
      required: true,
    },

    minPeriodValue: {
      type: Number,
      required: true,
    },

    maxPeriodValue: {
      type: Number,
      required: true,
    },
  },

  emits: ['changeDataInput', 'changePeriodType'],

  data: () => ({
    inputVal: null,
    inputTimeout: null,
  }),

  computed: {
    currentValue() {
      return this.selectedPeriod.periodType === 'months'
        ? this.selectedPeriod.valueInMonths
        : this.selectedPeriod.valueInDays;
    },

    setClass() {
      if (this.widthScaleLine < 15) {
        return 'left';
      }

      return this.widthScaleLine > 85 ? 'right' : '';
    },
  },

  created() {
    this.inputVal = this.currentValue;
  },

  mounted() {
    this.$refs[this.$id('input')].focus();
  },

  methods: {
    getClassList(id) {
      return [id, { 'active': this.selectedPeriod.periodType === id }];
    },

    setDataFromInput($event) {
      const val = $event.target.value;

      if (this.inputVal != val) {
        this.inputVal = val;
        if (this.inputTimeout) {
          clearTimeout(this.inputTimeout);
        }

        this.inputTimeout = setTimeout(() => {
          this.$emit('changeDataInput', this.removeLeadingZero(this.inputVal));
        }, 500);
      }
    },

    setPeriodType(periodType) {
      if (this.selectedPeriod.periodType === periodType) {
        return;
      }

      this.$emit('changePeriodType', periodType);
    },

    removeLeadingZero(value) {
      const regex = new RegExp('^0+(?!$)', 'g');
      const result = String(value).replaceAll(regex, '');

      return +result || '';
    },
  },
};
</script>
