<template>
  <div class="ido-step-fcfs">
    <div class="row m-h--40 m-b--28">
      <div class="col-6 col-md-12 p-h-40 m-b-28">
        <template v-if="isActive && !isTimeFcfs">
          <div class="flex-direction__column flex-h__center flex-v__center height-full">
            <div class="m-b-16">
              <img src="/static/images/clockInCircle.svg" alt="Upcoming" />
            </div>

            <div class="ido-step-title m-b-0 text-center">FCFS not started yet</div>
          </div>
        </template>

        <template v-else-if="isActive && isTimeFcfs">
          <div v-if="launchInLabel" class="info-box-transp">
            <img class="flex-sh__0 m-r-16" src="/static/images/ido/clock.svg" alt="clock" />
            <span v-html="launchInLabel"></span>
          </div>

          <div class="ido-step-title">Available to buy</div>

          <div class="flex-v__baseline flex-h__between flex-wrap__wrap">
            <div class="head">
              <span>
                {{ $formatUnitsToken(availableToBuy, poolInfo.buyTokenDecimals) }}
                {{ poolInfo.buyTokenSymbol }}
              </span>
              <g-tooltip
                position="bottom"
                class="inline-block m-r-8"
                :custom-icon="false"
                :content="`<span class='text-multicolor'>${percentFCFS}%</span> of the remaining <span class='text-multicolor'>111 ${poolInfo.buyTokenSymbol}</span>`"
                animation
              >
                <div class="icon-info"></div>
              </g-tooltip>
              <div class="inline-block">
                ({{ $formatUnitsToken(maxSellToken.toString(), poolInfo.sellTokenDecimals) }}
                {{ poolInfo.sellTokenSymbol }})
              </div>
            </div>

            <div class="user-usdt-balance">
              Balance: {{ $formatUnitsToken(poolInfo?.sellTokenBalance, poolInfo.sellTokenDecimals) }}
              {{ poolInfo.sellTokenSymbol }}
            </div>
          </div>

          <div class="form-field m-t-12 ido-step-form-field" :class="{ 'field-error': isAmountInvalid }">
            <g-autonumeric v-model="amount" placeholder="Enter amount" />

            <div class="form-name-token">{{ poolInfo.sellTokenSymbol }}</div>

            <button
              class="form-btn btn-transp"
              :class="{ 'disabled': $formatUnitsToken(availableToBuy.toString(), poolInfo.buyTokenDecimals) == 0 }"
              @click="setMax()"
            >
              Max
            </button>

            <div class="error">Insufficient balance</div>

            <div v-if="willGetToken" class="predict-amount">
              You will get&nbsp;
              <div class="inline-block text-multicolor">
                {{ $formatPrice(willGetToken, null, true, 6) }} {{ poolInfo.buyTokenSymbol }}
              </div>
            </div>
          </div>

          <div class="row m-t-36">
            <div class="col-6">
              <div
                v-if="isApproveTransactionInProgress"
                class="btn-big btn-approve width-full no-click animation-blink"
              >
                Approving...
              </div>
              <button
                v-else
                class="btn-big btn-approve width-full"
                :class="{
                  'disabled':
                    amount <= 0 ||
                    isSellTokenAllowanceGiven ||
                    isAmountInvalid ||
                    isApproveTransactionInProgress ||
                    isBuyTransactionInProgress,
                }"
                @click="approve()"
              >
                Approve
              </button>
            </div>

            <div class="col-6">
              <div
                v-if="isBuyTransactionInProgress"
                class="btn-big btn-multicolor width-full btn-font-normal no-click animation-blink"
              >
                Processing...
              </div>
              <button
                v-else
                class="btn-big btn-multicolor width-full btn-font-normal"
                :class="{
                  'disabled':
                    amount <= 0 ||
                    !isSellTokenAllowanceGiven ||
                    isAmountInvalid ||
                    isApproveTransactionInProgress ||
                    isBuyTransactionInProgress,
                }"
                @click="buy()"
              >
                Buy
              </button>
            </div>
          </div>
        </template>

        <template v-else-if="isUpcoming">
          <div class="flex-direction__column flex-h__center flex-v__center height-full">
            <div class="m-b-16">
              <img src="/static/images/clockInCircle.svg" alt="Upcoming" />
            </div>

            <div class="ido-step-title m-b-0">Upcoming</div>
          </div>
        </template>

        <template v-else>
          <div class="flex-direction__column flex-h__center flex-v__center height-full">
            <div class="m-b-16">
              <img src="/static/images/doneInCircle.svg" alt="OK" />
            </div>

            <div class="ido-step-title m-b-0 text-center">First Come First Serve phase round ends</div>
          </div>
        </template>
      </div>

      <div class="col-6 col-md-12 p-h-40 ido-step-border-left flex-direction__column flex-h__center m-b-28">
        <ido-progress-bar :options="progressBarOptions" />

        <div class="row m-b-16">
          <div class="col-6">
            <div class="field-info-title">Allocation:</div>

            <div v-if="whitelistAllocation" class="basic-info">
              {{ $formatPrice(whitelistAllocation, 'n/a', true, 0) }} {{ poolInfo.buyTokenSymbol }}
            </div>
            <template v-else>n/a</template>
          </div>

          <div class="col-6">
            <div class="field-info-title">Purchased:</div>

            <div v-if="whitelistAllocation" class="basic-info">
              {{ $formatUnitsToken(poolInfo.boughtAmount?.toString(), poolInfo.buyTokenDecimals) }}
              {{ poolInfo.buyTokenSymbol }}
            </div>

            <div v-else>n/a</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from '@IdoPage/steps/mixinsLiveFcfs.js';
import BigNumber from 'bignumber.js/bignumber.mjs';
import WhitelistAbi from '@/artifacts/Whitelist.json';
import { ethers } from 'ethers';

const ONE_TRANSACTION_PERCENT = 1;

export default {
  name: 'IdoStepFcfs',

  mixins: [mixins],

  data: () => ({
    maxFCFS: 0,
    percentFCFS: ONE_TRANSACTION_PERCENT,
  }),

  computed: {
    availableToBuy() {
      // if (!this.maxFCFS) {
      //   return BigNumber(0);
      // }
      return this.maxFCFS || BigNumber(0);
      // return BigNumber(this.maxFCFS).shiftedBy(this.poolInfo.buyTokenDecimals);
    },
  },

  created() {
    this.$eventBus.on('update-max-fcfs', () => {
      this.getDataFCFS();
    });
  },
  mounted() {
    this.getDataFCFS();

    this.refreshCountdown = setInterval(() => this.getLaunchInLabel(), 1000);
    this.getLaunchInLabel();
  },

  beforeUnmount() {
    clearInterval(this.refreshCountdown);
    this.$eventBus.off('update-max-fcfs', () => {
      this.getDataFCFS();
    });
  },

  methods: {
    async getDataFCFS() {
      const signer = this.$root.provider?.getSigner();
      const whitelistContract = new ethers.Contract(this.poolInfo.whitelistAddress, WhitelistAbi, signer);

      const maxBuyAmountAtLastHour = await whitelistContract.maxBuyAmountAtLastHour();

      // if (maxBuyAmountAtLastHour == 0) {
      //   console.log('11');
      //   console.log(this.poolInfo.distributedBuyAmount);
      //   console.log(this.poolInfo.totalBuyAmount);
      //   // this.maxFCFS = (this.poolInfo.distributedBuyAmount - this.poolInfo.totalBuyAmount) / 100;
      // }

      // const p = BigNumber(maxBuyAmountAtLastHour.toString())
      //   .dividedBy(10 ** this.poolInfo.buyTokenDecimals)
      //   .toString();

      this.maxFCFS = maxBuyAmountAtLastHour != 0 ? BigNumber(maxBuyAmountAtLastHour.toString()) : this.poolInfo.maxFCFS;
      // this.percentFCFS = maxBuyAmountAtLastHour != 0 ? 100 : ONE_TRANSACTION_PERCENT;
    },
  },
};
</script>
