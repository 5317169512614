<template>
  <div class="proposals-list">
    <div class="proposals-list-head">
      <div></div>
      <div class="cursor-pointer" @click="sort('name')">
        Project
        <span class="sort" :class="{ 'asc': isSortOptions.name.asc, 'desc': isSortOptions.name.desc }"></span>
      </div>
      <div class="cursor-pointer" @click="sort('date')">
        TGE date
        <span class="sort" :class="{ 'asc': isSortOptions.date.asc, 'desc': isSortOptions.date.desc }"></span>
      </div>
      <div class="cursor-pointer" @click="sort('tge')">
        Available at TGE
        <span class="sort" :class="{ 'asc': isSortOptions.tge.asc, 'desc': isSortOptions.tge.desc }"></span>
      </div>
      <div>Vesting schedule</div>
      <div class="cursor-pointer" @click="sort('votes')">
        <div class="proposals-list-head-votes">
          Votes
          <span class="sort" :class="{ 'asc': isSortOptions.votes.asc, 'desc': isSortOptions.votes.desc }"></span>
        </div>
      </div>
    </div>

    <proposals-list-row v-for="(item, key) in list" :key="key" :item="item" />
  </div>
</template>

<script>
import ProposalsListRow from './component/ProposalsListRow';

export default {
  name: 'ProposalsList',

  components: {
    ProposalsListRow,
  },

  props: {
    list: {
      type: Array,
      default: () => {
        [];
      },
    },
  },

  emits: ['sort-column'],

  data: () => ({
    isSortOptions: {
      name: {
        asc: true,
        desc: false,
      },
      date: {
        asc: false,
        desc: false,
      },
      tge: {
        asc: false,
        desc: false,
      },
      votes: {
        asc: false,
        desc: false,
      },
    },
  }),

  mounted() {
    this.$emit('sort-column', ['name', true]);
  },

  methods: {
    sort(flag) {
      let nameSortField = 'name';
      switch (flag) {
        case 'tge':
          nameSortField = 'tgePercent';
          break;
        case 'votes':
          nameSortField = 'votes';
          break;
        case 'date':
          nameSortField = 'tgeAtTimestamp';
          break;
        default:
          nameSortField = 'name';
          break;
      }

      const choiceColumn = this.isSortOptions[flag];
      if (choiceColumn.asc) {
        this.breakAllSort(this.isSortOptions);
        choiceColumn.desc = true;
        this.$emit('sort-column', [nameSortField, false]);
      } else {
        this.breakAllSort(this.isSortOptions);
        choiceColumn.asc = true;
        this.$emit('sort-column', [nameSortField, true]);
      }
    },

    breakAllSort(objSort) {
      Object.keys(objSort).forEach((item) =>
        Object.keys(objSort[item]).forEach((elem) => (objSort[item][elem] = false))
      );
    },
  },
};
</script>
