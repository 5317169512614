<template>
  <div class="ido-step-upcoming">
    <div class="row m-h--40 m-b--28">
      <div class="col-6 col-md-12 p-h-40 m-b-28">
        <!-- Register 1 -->
        <template v-if="!isRegistrationOver && !isRegistered">
          <div class="ido-step-title">Register for whitelist</div>

          <div class="ido-step-subtitle">Register your wallet with button below this text:</div>

          <div class="form-field ido-step-upcoming-form-field">
            <input v-model="address" disabled type="text" placeholder="Insert the wallet address here" />
          </div>

          <button class="btn-big btn-multicolor width-full btn-font-normal" @click="register">Register</button>
        </template>

        <!-- Register 2 -->
        <template v-if="!isRegistrationOver && isRegistered">
          <div class="text-center">
            <div class="m-b-16">
              <img src="/static/images/doneInCircle.svg" alt="OK" />
            </div>

            <div class="ido-step-title">Already registered</div>

            <div class="adress">{{ $root.account }}</div>
          </div>
        </template>

        <!-- Register 3 -->
        <template v-if="!isRegistrationOver && isNotHaveEnoughAPad">
          <div class="ido-step-title">Register for whitelist</div>

          <div class="need-aPad">
            You need to have
            <span class="text-multicolor">7300 aPad</span>
            for registration
          </div>

          <button class="btn-medium btn-multicolor invert btn-getaPad" @click="getAPad">Get aPad</button>
        </template>

        <!-- registration is over -->
        <template v-if="isRegistrationOver">
          <div class="text-center">
            <div class="m-b-16">
              <img src="/static/images/closeInCircle.svg" alt="OK" />
            </div>

            <div class="ido-step-title">Registration is closed</div>
          </div>
        </template>
      </div>

      <div class="col-6 col-md-12 p-h-40 ido-step-border-left flex-v__center m-b-28">
        <div v-if="poolInfo.type == 'whitelist'" class="row width-full">
          <div class="col-6">
            <div class="field-info-title">Registration end date (local):</div>

            <template v-if="poolInfo.registrationEndsAtTimestamp">
              <div class="date-main">
                <span>
                  <g-date :timestamp="poolInfo.registrationEndsAtTimestamp" part="date" />
                </span>
                <g-date :timestamp="poolInfo.registrationEndsAtTimestamp" part="time" />
              </div>

              <div class="date-UTC">
                <g-date :timestamp="poolInfo.registrationEndsAtTimestamp" part="datetime" :utc="true" />
                (UTC)
              </div>
            </template>
            <span v-else>n/a</span>
          </div>

          <div class="col-6">
            <div class="field-info-title">Registered:</div>

            <div v-if="registeredCount || poolInfo?.participants?.length" class="basic-info">
              {{ registeredCount || poolInfo?.participants?.length }}
            </div>
            <span v-else>0</span>
          </div>
        </div>

        <div v-else class="row width-full">
          <div class="col-12">
            <div class="field-info-title">Lottery pools doesn't have registration</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IdoStepUpcoming',

  props: {
    poolInfo: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    address: '',
    isRegistered: false,
    registeredCount: null,
    isNotHaveEnoughAPad: false,
  }),

  computed: {
    isRegistrationOver() {
      const currentTimeMs = Date.now() / 1000;

      if (!this.poolInfo?.registrationEndsAtTimestamp || currentTimeMs < this.poolInfo?.registrationEndsAtTimestamp) {
        return false;
      }

      return true;
    },
  },

  watch: {
    '$root.account': {
      async handler(val) {
        if (val) {
          this.address = val;
          await this.checkRegistration();
        }
      },
    },
  },

  async mounted() {
    this.address = this.$root.account;
    await this.checkRegistration();
  },

  methods: {
    getAPad() {
      const url =
        'https://www.trisolaris.io/#/swap?inputCurrency=0xC42C30aC6Cc15faC9bD938618BcaA1a1FaE8501d&outputCurrency=0x0fAD0ED848A7A16526E8a7574e418B015Dbf41B5';
      window.open(url, '_blank');
    },

    async register() {
      await this.$json.post({
        url: process.env.VUE_APP_API_URL_PREFIX + '/registration',
        data: {
          idoAddress: this.poolInfo.poolAddress,
          userAddress: this.$root.account,
        },
      });

      await this.checkRegistration();

      // FIXME: isNotHaveEnoughAPad set vaiable if not have enough aPad
      this.isNotHaveEnoughAPad = !this.isRegistered && false;
    },

    async checkRegistration() {
      if (!this.address) return;

      const response = await this.$json.get(
        `${process.env.VUE_APP_API_URL_PREFIX}/registration/${this.poolInfo.poolAddress}/${this.address}`
      );

      if (response.json?.success) {
        this.isRegistered = response.json.isRegistered;
        this.registeredCount = response.json.registeredCount;
      }
    },
  },
};
</script>
