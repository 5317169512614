<template>
  <div class="proposal-step">
    <div class="box-header">Token details</div>

    <div class="row">
      <div class="col-6 col-md-12">
        <g-form-field
          :id="$id('tokenSymbol')"
          :label="tokenSymbol.label"
          :type="tokenSymbol.type"
          :placeholder="tokenSymbol.placeholder"
          :value="tokenSymbol.value"
          :error-text="getErrorMessage(v$.tokenSymbol.value.$errors[0])"
          @input="tokenSymbol.value = $event"
        />
      </div>

      <div class="col-6 col-md-12">
        <g-form-field
          :id="$id('tokenLogo')"
          :label="tokenLogo.label"
          :hint="'Please provide a direct link to the token\'s logo in high resolution (150x150 pixels minimum)'"
          :type="tokenLogo.type"
          icon="/static/images/proposals/link.svg"
          :placeholder="tokenLogo.placeholder"
          :value="tokenLogo.value"
          :error-text="getErrorMessage(v$.tokenLogo.value.$errors[0])"
          @input="tokenLogo.value = $event"
        />
      </div>
    </div>

    <div class="btns flex-h__center m-t-32">
      <button
        class="btn btn-bigger btn-multicolor invert"
        :class="{ 'disabled': isFieldsProjectError }"
        @click="goNextStep"
      >
        Next
      </button>
      <button
        class="btn btn-bigger btn-approve invert btn-monument font-normal"
        :class="{ 'disabled': isFieldsProjectError || isErrorProposalFields }"
        @click="$emit('show-preview')"
      >
        Preview
      </button>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, url, maxLength, minLength } from '@vuelidate/validators';

export default {
  name: 'ProposalStepToken',

  components: {},

  props: {
    proposalFields: {
      type: Object,
      required: true,
    },

    isErrorProposalFields: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['next-step', 'fields-error', 'show-preview'],

  data() {
    return {
      v$: useVuelidate(),
      tokenSymbol: this.proposalFields.tokenSymbol,
      tokenLogo: this.proposalFields.tokenLogo,
    };
  },

  computed: {
    isFieldsProjectError() {
      if (this.v$?.$invalid) {
        this.v$.$validate();
      }

      const isError = this.v$.$invalid && (this.v$.tokenSymbol.$error || this.v$.tokenLogo.$error);
      this.$emit('fields-error', isError);
      return isError;
    },
  },

  validations() {
    return {
      tokenSymbol: {
        value: {
          required,
          minLengthValue: minLength(3),
          maxLengthValue: maxLength(6),
        },
      },
      tokenLogo: {
        value: {
          required,
          url,
          maxLengthValue: maxLength(2083),
        },
      },
    };
  },

  methods: {
    goNextStep() {
      this.v$.$validate();

      if (!this.isFieldsProjectError) {
        this.$emit('next-step');
      }
    },

    getErrorMessage(error) {
      if (!error) {
        return null;
      }

      if (error.$validator == 'required') {
        return 'This field is required to proceed to the next step';
      }

      return error.$message;
    },
  },
};
</script>
