<template>
  <div class="ido-prime">
    <div class="ido-prime_header">
      <div class="ido-prime_header-logo">
        <img :src="poolInfo.logoUrl" :alt="poolInfo.name" class="width-full" />
      </div>

      <div class="ido-prime_header-info">
        <div
          class="label-medium label-bg-noborder fw-500 m-b-8"
          :class="{ 'label-upcoming': poolInfo.status == 'upcoming' }"
        >
          {{ poolStatus }}
        </div>

        <div class="ido-prime_header-title">
          {{ poolInfo.name }}
          <span v-if="poolInfo.buyTokenSymbol">/ {{ poolInfo.buyTokenSymbol }}</span>
        </div>
      </div>
    </div>

    <div class="ido-prime_social-button">
      <social-menu :list-options="socialList" class="m-b-8 m-r-20" />

      <div v-if="poolInfo.buyTokenAddress" class="btns m-b-8">
        <button class="btn btn-small btn-bg-noborder" @click="tokenLinkClicked">Token address</button>
      </div>
    </div>

    <div class="prime_description">
      <div class="prime_description-title">About the project</div>

      <div class="prime_description-text">
        {{ description }}
      </div>
    </div>

    <a :href="poolInfo.websiteLink" class="btn-big btn-transp" target="_blank">More details</a>
  </div>
</template>

<script>
import axios from 'axios';
import SocialMenu from '@/components/SocialMenu';

export default {
  name: 'IdoPrime',

  components: {
    SocialMenu,
  },

  props: {
    poolInfo: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    description: '',
  }),

  computed: {
    poolStatus() {
      if (this.poolInfo.status == 'filled') {
        return 'closed';
      }

      return this.poolInfo.status;
    },

    socialList() {
      return [
        {
          icon: 'twitter',
          url: this.poolInfo?.twitterLink,
        },
        {
          icon: 'telegram',
          url: this.poolInfo?.telegramLink,
        },
        {
          icon: 'medium',
          url: this.poolInfo?.mediumLink,
        },
      ];
    },
  },

  async mounted() {
    await this.loadDescription();
  },

  methods: {
    tokenLinkClicked() {
      const url = this.$root.allowedChains[this.$root.chainId].explorerUrl + 'token/' + this.poolInfo.buyTokenAddress;
      window.open(url, '_blank');
    },

    async loadDescription() {
      const responseDescription = await axios.get(this.poolInfo.descriptionLink).catch((err) => {
        console.log('Description ERR', err);
        this.description = 'Error loading data';
        return;
      });

      if (responseDescription?.status !== 200 && responseDescription?.statusText === 'OK') {
        this.description = 'Error loading data';
        return;
      } else if (!responseDescription?.data) {
        this.description = 'No info about project';
        return;
      } else {
        this.description = responseDescription.data;
        return;
      }
    },
  },
};
</script>
