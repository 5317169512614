<template>
  <teleport to="#endofbody">
    <vue-final-modal
      v-model="show"
      :name="name"
      :classes="classes"
      :overlay-class="overlayClass"
      :click-to-close="clickToClose"
      :esc-to-close="escToClose"
      :prevent-click="preventClick"
      :transition="transition"
      :overlay-transition="overlayTransition"
      :drag="drag"
      :resize="resize"
      :min-width="minWidth"
      :min-height="minHeight"
      :max-width="maxWidth"
      :max-height="maxHeight"
      :styles="styles"
      @before-close="$emit('before-close'), () => $refs.focusTrap.deactivate()"
      @before-open="beforeOpen"
      @opened="$emit('opened'), () => $refs.focusTrap.activate()"
    >
      <focus-trap ref="focusTrap" v-model:active="isActiveFocusTrap">
        <div class="vfm--modal-container" :style="[`max-width: ${width}px; max-height: ${height}px;`]">
          <div v-if="$slots['header']" class="vfm--modal-header" :class="headerClasses">
            <slot name="header"></slot>
          </div>

          <div v-if="$slots['content']" class="vfm--modal-content" :class="contentClasses">
            <slot name="content"></slot>
          </div>

          <div v-if="$slots['footer']" class="vfm--modal-footer" :class="footerClasses">
            <slot name="footer"></slot>
          </div>

          <slot></slot>

          <button
            v-if="isShowCloseButton"
            type="button"
            class="vfm--modal-close"
            title="Close"
            @click="$emit('close-modal'), () => $refs.focusTrap.deactivate()"
          ></button>
        </div>
      </focus-trap>
    </vue-final-modal>
  </teleport>
</template>

<script>
import { nextTick } from 'vue';

export default {
  name: 'Gmodal',

  inheritAttrs: false,

  props: {
    name: {
      type: String,
      required: true,
    },
    classes: {
      type: [String, Object, Array],
      default: 'vfm--modal',
    },
    contentClass: {
      type: [String, Object, Array],
      default: '',
    },
    overlayClass: {
      type: [String, Object, Array],
      default: '',
    },
    styles: {
      type: [Object, Array],
      default: () => {},
    },
    transition: {
      type: [String, Object],
      default: 'vfm',
    },
    overlayTransition: {
      type: String,
      default: 'vfm',
    },
    clickToClose: {
      type: Boolean,
      default: true,
    },
    escToClose: {
      type: Boolean,
      default: true,
    },
    preventClick: {
      type: Boolean,
      default: false,
    },
    drag: {
      type: Boolean,
      default: false,
    },
    resize: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [Number, String],
      default: 500,
    },
    height: {
      type: [Number, String],
      default: 'auto',
    },
    minWidth: {
      type: Number,
      default: 0,
    },
    minHeight: {
      type: Number,
      default: 0,
    },
    maxWidth: {
      type: Number,
      default: 950,
    },
    maxHeight: {
      type: Number,
      default: Infinity,
    },
    headerClasses: {
      type: [String, Array],
      default: '',
    },
    contentClasses: {
      type: [String, Array],
      default: '',
    },
    footerClasses: {
      type: [String, Array],
      default: '',
    },
    isShowCloseButton: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['before-open', 'before-close', 'close-modal', 'opened'],

  data: () => ({
    show: false,
    isActiveFocusTrap: false,
  }),

  watch: {
    show: {
      immediate: true,
      handler(val) {
        nextTick(() => {
          this.isActiveFocusTrap = val;
        });
      },
    },
  },

  methods: {
    beforeOpen(event) {
      () => this.$refs.focusTrap.activate();
      this.$emit('before-open', event);
    },
  },
};
</script>
