<template>
  <div class="banner">
    <img class="banner-bg" src="/static/images/banner-bg.png" alt="banner background" />
    <div class="banner-close" @click="$emit('on-close')"></div>

    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'GBanner',

  emits: ['on-close'],
};
</script>
