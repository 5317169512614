<template>
  <div class="form-field" :class="classes">
    <label v-if="label" :for="$id(id)">
      {{ label }}
    </label>

    <div v-if="hint" class="hint">
      {{ hint }}
    </div>

    <div v-if="icon" class="prefix-icon" :class="{ 'prefix-icon-without-hint': !hint }">
      <img :src="icon" :alt="label" />
    </div>

    <input
      :id="$id(id)"
      autocomplete="off"
      :class="getInputClass"
      :type="type"
      :placeholder="placeholder"
      :disabled="disabled"
      :value="value"
      @input="$emit('input', $event.target.value)"
    />

    <div v-if="errorText" class="error">
      {{ errorText }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'GFormField',

  props: {
    value: {
      type: [String, Number, null],
      required: true,
    },

    id: {
      type: String,
      required: true,
    },

    label: {
      type: String,
      default: '',
    },

    hint: {
      type: String,
      default: '',
    },

    inputClass: {
      type: String,
      default: '',
    },

    type: {
      type: String,
      default: 'text',
    },

    icon: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    errorText: {
      type: String,
      default: '',
    },
  },

  emits: ['input'],

  computed: {
    classes() {
      return {
        'field-error': this.errorText,
      };
    },

    getInputClass() {
      return [this.inputClass, { 'p-l-24': this.icon }];
    },
  },
};
</script>
