<template>
  <header class="header-menu">
    <div class="container">
      <div class="header-wrap">
        <div class="logo">
          <img src="/static/images/logo-long.svg" alt="logo" height="35" />
        </div>
      </div>
    </div>
  </header>

  <div class="container ball-shadow maintenance-page">
    <div class="">
      <div class="maintenance-icon">
        <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M40.5856 25.2521C40.6841 24.5333 40.7333 23.7906 40.7333 23C40.7333 22.2333 40.6841 21.4667 40.5609 20.7479L45.5607 16.9625C45.7762 16.7898 45.9234 16.5497 45.9773 16.2832C46.0312 16.0167 45.9884 15.7403 45.8563 15.501L41.1274 7.54688C40.9882 7.30625 40.7659 7.12132 40.4999 7.02485C40.2339 6.92838 39.9415 6.92659 39.6743 7.0198L33.7878 9.3198C32.5563 8.40938 31.2509 7.64271 29.7978 7.06771L28.9111 0.982298C28.8675 0.708048 28.7242 0.458107 28.5073 0.277866C28.2904 0.0976256 28.0142 -0.000961938 27.7289 7.07585e-06H18.2711C17.68 7.07585e-06 17.212 0.407298 17.1135 0.982298L16.2268 7.06771C14.7737 7.64271 13.4437 8.43334 12.2368 9.3198L6.35036 7.0198C5.80851 6.82813 5.19277 7.0198 4.89722 7.54688L0.192956 15.501C-0.1026 16.0042 -0.0040813 16.6271 0.488511 16.9625L5.48833 20.7479C5.36518 21.4667 5.26666 22.2573 5.26666 23C5.26666 23.7427 5.31592 24.5333 5.43907 25.2521L0.439252 29.0375C0.223802 29.2102 0.0765899 29.4503 0.0226974 29.7168C-0.031195 29.9833 0.0115668 30.2597 0.143697 30.499L4.87259 38.4531C5.16814 38.9802 5.78388 39.1479 6.32574 38.9802L12.2122 36.6802C13.4437 37.5906 14.7491 38.3573 16.2022 38.9323L17.0889 45.0177C17.212 45.5927 17.68 46 18.2711 46H27.7289C28.32 46 28.8126 45.5927 28.8865 45.0177L29.7731 38.9323C31.2263 38.3573 32.5563 37.5906 33.7632 36.6802L39.6496 38.9802C40.1915 39.1719 40.8072 38.9802 41.1028 38.4531L45.8317 30.499C46.1272 29.9719 46.0041 29.3729 45.5361 29.0375L40.5856 25.2521ZM23 31.625C18.1233 31.625 14.1333 27.7438 14.1333 23C14.1333 18.2563 18.1233 14.375 23 14.375C27.8767 14.375 31.8667 18.2563 31.8667 23C31.8667 27.7438 27.8767 31.625 23 31.625Z"
            fill="url(#paint0_linear_3077_33090)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_3077_33090"
              x1="23"
              y1="-3.54171e-06"
              x2="45.7565"
              y2="9.63944"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#33B415" />
              <stop offset="1" stop-color="#237A0E" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div class="maintenance-title">The website is under maintenance</div>

      <div v-if="time" class="maintenance-subtitle">
        Approximately will end at
        <div class="date-main">
          <span class="p-l-8 p-r-4">
            <g-date :timestamp="+time" part="date" :utc="true" />
          </span>
          <g-date :timestamp="+time" part="time" :utc="true" />
          <div class="date-UTC inline-block p-l-8">(UTC)</div>
        </div>
      </div>
    </div>

    <div class="marquee-wrap">
      <div v-for="m in 2" :key="m" class="marquee-line">
        <span v-for="i in 20" :key="i" class="marquee-text">maintenance</span>
      </div>
    </div>
  </div>

  <the-footer />
</template>

<script>
import TheFooter from '@/views/TheFooter';

export default {
  name: 'Maintenance',

  components: {
    TheFooter,
  },

  props: {
    time: {
      type: [String, Number],
      default: '',
    },
  },
};
</script>
