<template>
  <div v-if="web3Instance.account" class="label-wrap flex-sh__0">
    <div class="flex-v__center cursor-pointer" @click="$vfm.show('transactionList')">
      <div v-if="$root.transactions.pendingTransactionsCount" class="label-big p-h-4 text-lightcolor">
        {{ $root.transactions.pendingTransactionsCount }} pending
      </div>

      <button
        v-if="chainName"
        class="label-big label-bg-noborder m-l-4 p-h-12 view-tx"
        :class="{ 'label-chain': !isDesktop }"
      >
        <template v-if="!$root.transactions.pendingTransactionsCount">
          <img class="m-b-12" :src="$root.allowedChains[$root.chainId]?.icon" :alt="chainName" />
        </template>
        <template v-else>
          <img class="transaction-list-width-loader m-b-4" src="/static/images/loader.svg" alt="loader" />
        </template>
      </button>
    </div>

    <button
      v-if="web3Instance.account"
      class="label-big label-bg-noborder invert label-icon-left hidden-btn-wrap"
      :class="{ 'label-chain': !isDesktop }"
      @click="copy"
    >
      <img src="/static/images/wallets.svg" alt="" />
      <span>{{ shortAddress }}</span>
      <div class="hidden-btn">
        <div class="hidden-btn-item">
          <img v-if="!isTextCopied" src="/static/images/copy.svg" alt="logo" height="16" />
          <img v-else src="/static/images/done.svg" alt="logo" height="16" />
        </div>
        <!-- <div class="hidden-btn-item" @click="change">
          <img src="/static/images/change.svg" alt="logo" height="16" />
        </div> -->
      </div>
    </button>

    <button
      v-if="web3Instance.account"
      class="label-big label-bg-noborder invert hidden-btn-wrap p-h-12 lavel-hover-rotate"
      @click="connect"
    >
      <img src="/static/images/sync.svg" alt="sync" height="20" />
    </button>
  </div>

  <div v-else class="wrap-wallet">
    <div v-if="isMetaMaskInstalled" class="btn-big btn-transp m-l-8 m-r-8" @click="connect">
      {{ innerText }}
    </div>

    <div v-else class="not-installed">Metamask not installed</div>
  </div>
</template>

<script>
import { useWeb3 } from '@/store/web3.js';
export default {
  props: {
    isDesktop: {
      type: Boolean,
      default: false,
    },

    innerText: {
      type: String,
      default: 'Connect wallet',
    },
  },

  emits: ['account', 'chain', 'connect', 'disconnect', 'block', 'error-info'],

  data() {
    const isMetaMaskInstalled = Boolean(window.ethereum?.isMetaMask);

    return {
      accounts: null,
      account: null,
      chainId: null,
      error: null,
      newBlockHeadersSubscription: null,
      isTextCopied: false,
      isWrongNetwork: false,
      isMetaMaskInstalled,

      web3Instance: useWeb3(),
    };
  },

  computed: {
    shortAddress() {
      const instance = this.web3Instance;
      if (!instance) {
        return null;
      }

      if (instance.user) {
        return instance.user.sub;
      }

      if (!instance.account) {
        return null;
      }

      return instance.account.substring(0, 6) + '...' + instance.account.substring(38);
    },

    chainName() {
      if (!this.chainId) {
        return;
      }

      return this.$root.allowedChains[this.chainId]?.name;
    },

    possibleNetworkNames() {
      return Object.values(this.$root.allowedChains)
        .map((c) => c.name)
        .join(' / ');
    },
  },

  async mounted() {
    if (!Array.isArray(Object.keys(this.$root.allowedChains))) {
      throw new Error('allowed-chain-ids must be an array of numbers');
    }

    await this.web3Instance.connect(true);

    if (!this.isMetaMaskInstalled) {
      return;
    }

    const instance = this.web3Instance;

    if (!instance) {
      return;
    }

    this.chainId = parseInt(instance.chainId);

    if (instance.user) {
      this.account = instance.user.wallet_address;
    } else {
      this.account = instance.account;
    }
  },

  methods: {
    async connect() {
      await this.web3Instance.resetApp();
      await this.web3Instance.connect();
    },

    change() {
      window.ethereum
        .request({
          method: 'wallet_requestPermissions',
          params: [
            {
              eth_accounts: {},
            },
          ],
        })
        .catch(() => {
          /* do nothing */
        });
    },

    copy() {
      const instance = this.web3Instance;

      navigator.clipboard
        .writeText(instance.user?.sub || instance.account)
        .then(() => {
          this.isTextCopied = true;
          setTimeout(() => (this.isTextCopied = false), 3000);
        })
        .catch(() => {
          /* do nothing */
        });
    },
  },
};
</script>
