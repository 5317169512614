<template>
  <div class="form-field" :class="classes">
    <label v-if="label" class="mt-4">
      {{ label }}
    </label>

    <div v-if="hint" class="hint">
      {{ hint }}
    </div>

    <datepicker
      v-model="dateTime"
      utc
      dark
      format="dd.MM.yyyy HH:mm"
      :min-date="new Date()"
      :time-picker-component="timePicker"
      :action-row-component="actionRow"
    />

    <div v-if="errorText" class="error">
      {{ errorText }}
    </div>
  </div>
</template>

<script>
import ActionRow from './ActionRow.vue';
import TimePicker from './TimePicker.vue';
import Datepicker from '@vuepic/vue-datepicker';

export default {
  name: 'GDatePicker',

  components: {
    Datepicker,
  },

  props: {
    value: {
      type: [String, Number, null],
      required: true,
    },

    label: {
      type: String,
      default: '',
    },

    hint: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: 'Select date and time',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    errorText: {
      type: String,
      default: '',
    },
  },

  emits: ['changed'],

  data: () => ({
    dateTime: null,
  }),

  computed: {
    classes() {
      return {
        'field-error': this.errorText,
      };
    },

    timePicker() {
      return TimePicker;
    },
    actionRow() {
      return ActionRow;
    },
  },

  watch: {
    'dateTime': {
      immediate: true,
      async handler(val) {
        if (!val) {
          return;
        }

        this.$emit('changed', typeof val == 'number' ? val : val.getTime());
      },
    },

    'value': {
      handler(val) {
        if (val == Math.round(this.dateTime)) {
          return;
        }

        this.dateTime = val;
      },
    },
  },

  created() {
    this.dateTime = this.value;
  },
};
</script>
