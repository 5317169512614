<template>
  <div class="proposal-page">
    <section class="first-screen ball-shadow">
      <div class="container">
        <div class="row">
          <div class="col-9 col-sm-12">
            <div class="main-title">
              <h2 class="m-b-0">{{ getTitile }}</h2>
              <div v-if="$root.errorInfo" class="m-t-16" v-html="$root.errorInfo"></div>
            </div>
          </div>
          <div v-if="!isPreview" class="col-3 col-sm-12">
            <div class="flex-h__end">
              <img src="/static/images/pen.png" class="first-screen-img" alt="edit" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <template v-if="!isShowPage">
      <div class="container">
        <template v-if="!web3Instance.active">
          <div class="text-multicolor animation-blink">Please, connect wallet first.</div>

          <div class="btn-big btn-transp m-t-24 m-r-a" @click="connect">Connect wallet</div>
        </template>

        <template v-else>
          <div class="text-multicolor animation-blink">
            Please, connect to {{ $root.allowedChains[homeChain].name }} network first.
          </div>

          <div class="btn-big btn-transp m-t-24 m-r-a" @click="connectToHomeChain">
            Connect to {{ $root.allowedChains[homeChain].name }} network
          </div>
        </template>
      </div>
    </template>

    <template v-else>
      <div class="container">
        <proposal-preview
          v-if="isPreview"
          :proposal-info="proposalInfo"
          :fee-info="feeInfo"
          @edit="isPreview = false"
          @create-proposal="createProposal"
        />
        <proposal-steps v-else :proposal-fields="proposalFields" @show-preview="isPreview = true" />
      </div>
    </template>
  </div>

  <fee-modal :proposal-info="proposalInfo" :fee-info="feeInfo" />
</template>

<script>
import { proposalFieldsJson } from './proposals.mjs';
import ProposalSteps from './ProposalSteps';
import ProposalPreview from './ProposalPreview';
import FeeModal from './component/FeeModal';
import { useWeb3 } from '@/store/web3';
import { ethers } from 'ethers';
import GovernanceAbi from '@/artifacts/Governance.json';

export default {
  name: 'CreateProposalsIDO',

  components: {
    ProposalSteps,
    ProposalPreview,
    FeeModal,
  },

  props: ['proposalAddress'],

  data: () => ({
    homeChain: process.env.VUE_APP_HOME_CHAIN,
    web3Instance: useWeb3(),

    proposalFields: {},
    proposalInfo: {},
    isPreview: false,

    feeInfo: {},
  }),

  computed: {
    isShowPage() {
      if (!this.$root.account) {
        return false;
      }

      if (this.$root.chainId != this.homeChain) {
        return false;
      }

      return true;
    },

    getTitile() {
      return `IDO proposal ${this.proposalAddress !== 'new' ? 'edit' : 'creation'}`;
    },
  },

  watch: {
    isPreview: {
      immediate: true,
      handler(newVal) {
        if (!newVal) {
          return;
        }

        this.updateProposalInfo();
      },
    },

    '$root.account': {
      immediate: true,
      async handler(account) {
        if (!account) {
          return;
        }

        await this.loadFeeData();
      },
    },
    '$root.chainId': {
      immediate: true,
      async handler(chainId) {
        if (!chainId) {
          return;
        }

        await this.loadFeeData();
      },
    },
  },

  created() {
    this.proposalFields = JSON.parse(JSON.stringify(proposalFieldsJson)) || {};
  },

  methods: {
    async connect() {
      await this.web3Instance.resetApp();
      await this.web3Instance.connect();
    },

    async connectToHomeChain() {
      if (this.$root.chainId != this.homeChain) {
        await this.$root.setActiveChain(parseInt(this.homeChain));
      }
    },

    createProposal() {
      this.updateProposalInfo();

      this.$vfm.show('fee');
    },

    updateProposalInfo() {
      const proposal = JSON.parse(JSON.stringify(this.proposalFields));

      this.proposalInfo = {
        //FIXME, now constants
        distributionNetworks: [1313161554],
        homeNetwork: 1313161554,
        poolAddress: null,
        sellTokenDecimals: 6,

        /* START of STEP 1*/
        name: proposal.projectName.value,
        mediumLink: proposal.mediumLink.value,
        telegramLink: proposal.telegramLink.value,
        twitterLink: proposal.twitterLink.value,
        description: proposal.aboutProject.value,
        websiteLink: proposal.websiteLink.value,
        /* END of STEP 1*/

        /* START of STEP 2*/
        buyTokenSymbol: proposal.tokenSymbol.value,
        logoUrl: proposal.tokenLogo.value,

        buyTokenDecimals: 18, //FIXME
        /* END of STEP 2*/

        /* START of STEP 3*/

        /* END of STEP 3*/

        currentPriceBuyInSellUQ: proposal.priceOneToken.value,
        endedAtTimestamp: null, //parseInt(proposal.endDateUTC.value / 1000, 10),
        distributedBuyAmount: proposal.totalAllocation.value,

        createdAtTimestamp: parseInt(new Date().getTime() / 1000, 10),

        tgePercent: proposal.tgePercent.value,
        tgeAtTimestamp: parseInt(proposal.tgeDateUTC.value / 1000, 10),

        // descriptionLink: proposal.linkGDPR.value,
        startedAtTimestamp: null, //parseInt(proposal.startDateUTC.value / 1000, 10),
        totalSellAmount: proposal.priceOneToken.value,
        vesting: proposal.vesting,

        // cookyLink: proposal.cookyLink.value,
        votingDateUTC: parseInt(proposal.votingDateUTC.value / 1000, 10),
        // discordLink: proposal.discordLink.value,
        // grants: proposal.grants.value,

        contactEmail: proposal.contactEmail.value,
        contactTG: proposal.contactTg.value,
      };
    },

    async loadFeeData() {
      const signer = this.$root.provider?.getSigner();

      const governanceContract = new ethers.Contract(
        this.$root.allowedChains[this.$root.chainId]?.governanceContractAddress,
        GovernanceAbi,
        signer
      );

      const padFee = await governanceContract.getFeeInPAD();
      const ethFee = await governanceContract.getFeeInETH();

      this.feeInfo = {
        padFee,
        ethFee,
      };
    },
  },
};
</script>
