<template>
  <section class="card-list">
    <div class="container">
      <div class="tabs scrollable m-b-40">
        <button
          v-for="(item, index) in contentTabs"
          :key="index"
          :class="{ 'active': item.name == activeTabName }"
          @click="activeTabName = item.name"
        >
          {{ item.nameHR }}
        </button>
      </div>

      <div class="card-row">
        <div v-if="isEmpty" :key="$id(activeTabName)" class="width-full no-pools">
          <img src="/static/images/magnifier.svg" alt="Magnifier" />
          <div>No {{ activeTabName }} IDOs yet</div>
        </div>

        <template v-else>
          <card-item v-for="item in poolsOfActiveTab" :key="$id(item.poolAddress)" :pool-address="item.poolAddress" />
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import CardItem from '@PoolsPage/CardItem';

const DEFAULT_TAB_NAME = 'active';

export default {
  name: 'CardList',

  components: {
    CardItem,
  },

  data: () => ({
    contentTabs: [
      { name: 'active', nameHR: 'Active IDOs' },
      { name: 'upcoming', nameHR: 'Coming Soon' },
      { name: 'filled', nameHR: 'Finished IDOs' },
    ],
    activeTabName: DEFAULT_TAB_NAME,
    activePools: [],
    upcomingPools: [],
    filledPools: [],
  }),

  computed: {
    poolsOfActiveTab() {
      return this[this.activeTabName + 'Pools'];
    },

    isEmpty() {
      return Object.keys(this.poolsOfActiveTab)?.length == 0;
    },
  },

  created() {
    this.loadPools();
  },

  methods: {
    loadPools() {
      this.activePools = this.$root.activePools;
      this.upcomingPools = this.$root.upcomingPools;
      this.filledPools = this.$root.filledPools;
    },
  },
};
</script>
