<template>
  <div class="container ball-shadow stake-page">
    <div class="flex-wrap__wrap">
      <h2 class="stake-page-title">Stake PAD token</h2>
    </div>

    <template v-if="!isShowStakingPage">
      <template v-if="!web3Instance.active">
        <div class="text-multicolor animation-blink">Please, connect wallet first.</div>

        <div class="btn-big btn-transp m-t-24 m-r-a" @click="connect">Connect wallet</div>
      </template>

      <template v-else>
        <div class="text-multicolor animation-blink">
          Please, connect to {{ $root.allowedChains[homeChain].name }} network first.
        </div>

        <div class="btn-big btn-transp m-t-24 m-r-a" @click="connectToHomeChain">
          Connect to {{ $root.allowedChains[homeChain].name }} network
        </div>
      </template>
    </template>

    <template v-else-if="poolInfoByChainId">
      <div class="row stake-page-row">
        <div class="stake-page-box-info">
          <div class="visual-box box-shadow height-full">
            <div class="flex-v__center flex-h__between flex-wrap__wrap m-b-12">
              <div class="head">Info</div>
              <div class="btns">
                <button class="btn-text m-b-8" @click="$vfm.show('poolInfo')">More</button>
              </div>
            </div>

            <div class="info-mobile-row">
              <div class="info-mobile-item">
                <div class="title">APR</div>

                <transition name="toLeft">
                  <div>
                    <span class="text-darker fw-300">≈</span>
                    {{ $formatPercent(poolInfoByChainId[$root.chainId]?.aprPercent) }}
                  </div>
                </transition>
              </div>

              <div class="info-mobile-item">
                <div class="title">1 PAD</div>

                <transition name="toLeft">
                  <div>
                    <span class="text-darker fw-300">≈</span>
                    {{ $formatPrice(poolInfoByChainId[$root.chainId]?.onePadInAPad, 'n/a', true, 6) }}
                    <span v-if="poolInfoByChainId[$root.chainId]?.onePadInAPad" class="small">aPAD</span>
                  </div>
                </transition>
              </div>

              <div class="info-mobile-item">
                <div class="title">1 PAD</div>

                <transition name="toLeft">
                  <div>
                    <span class="text-darker fw-300">≈</span>
                    {{ $formatPrice(poolInfoByChainId[homeChain]?.padPrice, 'n/a', false, 4) }}
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>

        <div class="stake-page-box-stake">
          <div class="visual-box box-shadow height-full flex-direction__column">
            <div class="flex-v__center flex-h__between flex-wrap__wrap">
              <div class="head">Stake</div>

              <div class="token-adress">
                <img src="/static/images/pad-coin.svg" alt="PAD" />

                <span class="name-token">PAD</span>

                <div class="btns">
                  <div class="btn">
                    <g-tooltip position="left" content="Copy adress" :custom-icon="false" animation>
                      <button
                        class="btn-only-icon-copy"
                        :class="{ 'copied': isPadCopied }"
                        @click="
                          (e) => {
                            setIsCopied('PAD');
                            return copy($root?.allowedChains[$root.chainId]?.padTokenContract, e);
                          }
                        "
                      ></button>
                    </g-tooltip>
                  </div>

                  <div class="btn">
                    <g-tooltip position="left" content="Add to Metamask" :custom-icon="false" animation>
                      <button class="btn-only-icon-metamask" @click="() => addToMetamask('PAD')"></button>
                    </g-tooltip>
                  </div>
                </div>
              </div>
            </div>

            <div class="head-subtitle">
              You have
              <span>{{ $formatUnitsToken(maxAmountToStake || null) }}</span>
              PAD
            </div>

            <div
              class="form-field m-t-32 stake-page-form-field"
              :class="{ 'field-error': isPadAmountInvalid, 'show-predict-amount': willStakedAPad }"
            >
              <g-auto-numeric v-model="amountToStake" />

              <button class="form-btn btn-transp" :class="{ 'disabled': maxAmountToStake == 0 }" @click="setMaxPad()">
                Max
              </button>

              <div class="error">Insufficient balance</div>

              <div v-if="willStakedAPad" class="predict-amount">
                You will have ≈
                <span>{{ $formatPrice(willStakedAPad, null, true, 6) }}</span>
                aPAD
              </div>
            </div>

            <div
              v-if="isApproveTransactionInProgress"
              class="info-box-big info-box-multicolor animation-blink m-t-8 m-b--8"
            >
              Approving
            </div>

            <button
              v-else-if="!isPadAllowanceGiven"
              class="btn-big btn-multicolor width-full m-t-8 m-b--8"
              :class="{
                'disabled': amountToStake <= 0 || isPadAmountInvalid || isApproveTransactionInProgress,
              }"
              @click="approvePad()"
            >
              Approve
            </button>

            <div
              v-else-if="isStakeTransactionInProgress"
              class="info-box-big info-box-multicolor animation-blink m-t-8 m-b--8"
            >
              Staking
            </div>

            <button
              v-else
              class="btn-big btn-multicolor width-full m-t-8 m-b--8"
              :class="{ 'disabled': amountToStake <= 0 || isPadAmountInvalid }"
              @click="stakePad()"
            >
              Stake
            </button>
          </div>
        </div>

        <div class="stake-page-box-unstake">
          <div class="visual-box box-shadow height-full flex-direction__column">
            <div class="flex-v__center flex-h__between flex-wrap__wrap">
              <div class="head">Unstake</div>

              <div class="token-adress">
                <img src="/static/images/aPad-coin.svg" alt="aPAD" />

                <span class="name-token">aPAD</span>

                <div class="btns">
                  <div class="btn">
                    <g-tooltip position="left" content="Copy adress" :custom-icon="false" animation>
                      <button
                        class="btn-only-icon-copy"
                        :class="{ 'copied': isAPadCopied }"
                        @click="
                          (e) => {
                            setIsCopied('aPAD');
                            return copy($root?.allowedChains[$root.chainId]?.aPadTokenContract, e);
                          }
                        "
                      ></button>
                    </g-tooltip>
                  </div>

                  <div class="btn">
                    <g-tooltip position="left" content="Add to Metamask" :custom-icon="false" animation>
                      <button class="btn-only-icon-metamask" @click="() => addToMetamask('aPAD')"></button>
                    </g-tooltip>
                  </div>
                </div>
              </div>
            </div>

            <div class="head-subtitle">
              You have
              <span>{{ $formatUnitsToken(maxAmountToUnstake || null) }}</span>
              aPAD
            </div>

            <div
              class="form-field m-t-32 stake-page-form-field"
              :class="{ 'field-error': isAPadAmountInvalid, 'show-predict-amount': willUnstakedPad }"
            >
              <g-auto-numeric v-model="amountToUnstake" />

              <button
                class="form-btn btn-transp"
                :class="{ 'disabled': maxAmountToUnstake == 0 }"
                @click="setMaxAPad()"
              >
                Max
              </button>

              <div class="error">Insufficient balance</div>

              <div v-if="isWillUnstakedPad" class="predict-amount">
                You will have ≈
                <span>{{ $formatPrice(willUnstakedPad, null, true) }}</span>
                PAD
              </div>
            </div>

            <div
              v-if="isUnstakeTransactionInProgress"
              class="info-box-big info-box-multicolor animation-blink m-t-8 m-b--8"
            >
              Unstaking
            </div>

            <button
              v-else
              class="btn-big btn-multicolor width-full m-t-8 m-b--8"
              :class="{ 'disabled': amountToUnstake <= 0 || isAPadAmountInvalid }"
              @click="unstakeAPad()"
            >
              Unstake
            </button>
          </div>
        </div>
      </div>

      <calculator-box :pool-info="poolInfoByChainId" />

      <pool-info :pool-info="poolInfoByChainId" />
    </template>

    <template v-else>
      <g-loader :key="$id('loading')" />
    </template>
  </div>
</template>

<script>
import CalculatorBox from '@StakingPage/Calculator.vue';
import padb64 from '!raw-loader!./StakingPage/padb64.txt';
import apadb64 from '!raw-loader!./StakingPage/apadb64.txt';
import PoolInfo from '@StakingPage/PoolInfo.vue';
import { ethers } from 'ethers';
import GLoader from '@/components/g-loader.vue';
import GAutoNumeric from '@/components/g-autonumeric.vue';
import PADAbi from '@/artifacts/PAD.json';
import aPADAbi from '@/artifacts/aPAD.json';
import BigNumber from 'bignumber.js/bignumber.mjs';
import { useWeb3 } from '../store/web3';

export default {
  name: 'StakingPage',

  components: {
    CalculatorBox,
    PoolInfo,
    GLoader,
    GAutoNumeric,
  },

  data() {
    return {
      homeChain: process.env.VUE_APP_HOME_CHAIN,
      web3Instance: useWeb3(),

      amountToStake: null,
      amountToUnstake: null,

      isPadCopied: false,
      isAPadCopied: false,

      poolInfoByChainId: null,

      PADContract: null,
      aPadContract: null,

      maxAmountToStake: null,
      maxAmountToUnstake: null,

      padAllowanceGiven: null,
      willUnstakedPad: null,

      isApproveTransactionInProgress: false,
      isStakeTransactionInProgress: false,
      isUnstakeTransactionInProgress: false,

      isUpdateInProgress: false,
    };
  },

  computed: {
    isShowStakingPage() {
      if (!this.$root.account) {
        return false;
      }

      if (this.$root.chainId != this.homeChain) {
        return false;
      }

      return true;
    },

    willStakedAPad() {
      if (!this.poolInfoByChainId[this.$root.chainId]?.onePadInAPad) {
        return null;
      }

      if (!this.amountToStake) {
        return null;
      }

      return this.amountToStake * this.poolInfoByChainId[this.$root.chainId]?.onePadInAPad;
    },

    isWillUnstakedPad() {
      return this.willUnstakedPad !== null && !isNaN(this.willUnstakedPad) && this.willUnstakedPad.toString() !== '0';
    },

    isPadAmountInvalid() {
      const maxValue = BigNumber(ethers.utils.formatUnits(this.maxAmountToStake?.toString(), 18));
      return BigNumber(this.amountToStake).isGreaterThan(maxValue);
    },

    isAPadAmountInvalid() {
      const maxValue = BigNumber(ethers.utils.formatUnits(this.maxAmountToUnstake?.toString(), 18));
      return BigNumber(this.amountToUnstake).isGreaterThan(maxValue);
    },

    isPadAllowanceGiven() {
      if (!this.padAllowanceGiven) {
        return false;
      }

      if (this.amountToStake === 'null' || this.amountToStake === null || !+this.amountToStake) {
        return false;
      }

      const stakePad = BigNumber(this.amountToStake);

      return this.padAllowanceGiven.dividedBy(10 ** 18).isGreaterThanOrEqualTo(stakePad);
    },
  },

  watch: {
    '$root.account': {
      immediate: true,
      async handler(account) {
        if (!account) {
          return;
        }

        await this.updateAccountInfo();
      },
    },
    '$root.chainId': {
      immediate: true,
      async handler(chainId) {
        if (!chainId) {
          return;
        }

        await this.updateAccountInfo();
      },
    },
    'amountToUnstake': {
      immediate: true,
      async handler(val) {
        if (!val || val == 0) {
          this.willUnstakedPad = null;
          return;
        }

        this.willUnstakedPad = await this.getWillUnstakedPad();
      },
    },
  },

  mounted() {
    this.connectToHomeChain();
  },

  methods: {
    async connect() {
      await this.web3Instance.resetApp();
      await this.web3Instance.connect();
    },

    async connectToHomeChain() {
      if (this.$root.chainId != this.homeChain) {
        await this.$root.setActiveChain(parseInt(this.homeChain));
      }
    },

    async getWillUnstakedPad() {
      if (!this.amountToUnstake) {
        return null;
      }

      if (!this.maxAmountToUnstake) {
        return null;
      }

      const availableAmount = await this.aPADContract.availableAmount(this.$root.account);
      const DIVISION_PRECISION = BigNumber(10 ** 5);

      const amountInPad = BigNumber(this.amountToUnstake.toString())
        .multipliedBy(BigNumber(availableAmount.toString()))
        .multipliedBy(DIVISION_PRECISION)
        .dividedBy(BigNumber(this.maxAmountToUnstake.toString()))
        .dividedBy(DIVISION_PRECISION);

      return amountInPad;
    },

    async getPoolInfo() {
      const response = await this.$json.get(process.env.VUE_APP_API_URL_PREFIX + '/staking');

      if (response?.statusCode !== 200) {
        return;
      }
      if (!response?.json?.success) {
        return;
      }
      if (!response?.json?.infoByChain) {
        return;
      }

      const data = response.json.infoByChain;
      this.poolInfoByChainId = { ...data };
    },

    async updateAccountInfo() {
      this.amountToStake = null;
      this.amountToUnstake = null;

      if (this.isUpdateInProgress) {
        return;
      }

      this.isUpdateInProgress = true;

      if (!this.$root.chainId) {
        this.isUpdateInProgress = false;
        await setTimeout(async () => await this.updateAccountInfo(), 1000);
        return;
      }

      this.getPoolInfo();

      const signer = this.$root.provider?.getSigner();

      this.PADContract = new ethers.Contract(
        this.$root.allowedChains[this.$root.chainId]?.padTokenContract,
        PADAbi,
        signer
      );

      this.aPADContract = new ethers.Contract(
        this.$root.allowedChains[this.$root.chainId].aPadTokenContract,
        aPADAbi,
        signer
      );

      this.maxAmountToStake = await this.PADContract.balanceOf(this.$root.account);

      this.maxAmountToUnstake = await this.aPADContract.balanceOf(this.$root.account);

      const allowance = await this.PADContract.allowance(
        this.$root.account,
        this.$root.allowedChains[this.$root.chainId].aPadTokenContract
      );

      this.padAllowanceGiven = BigNumber(allowance.toString());

      this.isUpdateInProgress = false;
    },

    setMaxPad() {
      this.amountToStake = ethers.utils.formatUnits(this.maxAmountToStake, 18);
    },

    setMaxAPad() {
      this.amountToUnstake = ethers.utils.formatUnits(this.maxAmountToUnstake, 18);
    },

    async approvePad() {
      this.isApproveTransactionInProgress = true;

      this.$root.transactions.listen({
        info: `Approve ${this.amountToStake} PAD for stake`,
        transactionRequest: this.PADContract.approve(
          this.$root.allowedChains[this.$root.chainId].aPadTokenContract,
          '0x' + BigNumber(this.amountToStake).shiftedBy(18).toString(16)
        ),
        onDone: (isSuccess = false) => {
          this.isApproveTransactionInProgress = false;

          if (isSuccess) {
            this.padAllowanceGiven = BigNumber(this.amountToStake).shiftedBy(18);
          }
        },
      });
    },

    stakePad() {
      if (this.isPadAmountInvalid) {
        return;
      }

      if (!this.isPadAllowanceGiven) {
        return;
      }

      this.isStakeTransactionInProgress = true;

      this.$root.transactions.listen({
        info: `Stake ${this.amountToStake} PAD`,
        transactionRequest: this.aPADContract.stake('0x' + BigNumber(this.amountToStake).shiftedBy(18).toString(16)),
        onDone: async (isSuccess = false) => {
          this.isStakeTransactionInProgress = false;

          if (isSuccess) {
            await this.updateAccountInfo();
          }
        },
      });
    },

    unstakeAPad() {
      if (this.isAPadAmountInvalid) {
        return;
      }

      this.isUnstakeTransactionInProgress = true;

      this.$root.transactions.listen({
        info: `Unstake ${this.amountToUnstake} aPAD`,
        transactionRequest: this.aPADContract.unstake(
          '0x' + BigNumber(this.amountToUnstake).shiftedBy(18).toString(16)
        ),
        onDone: async (isSuccess = false) => {
          this.isUnstakeTransactionInProgress = false;

          if (isSuccess) {
            await this.updateAccountInfo();
          }
        },
      });
    },

    shortAdress(adress) {
      if (!adress) {
        return null;
      }

      return adress.substring(0, 5) + '...' + adress.substring(38);
    },

    copy(text, event) {
      const el = event.target;

      navigator.clipboard
        .writeText(text)
        .then(() => {
          el.classList.add('copied');
          setTimeout(() => el.classList.remove('copied'), 2000);
        })
        .catch(() => {
          /* do nothing */
        });
    },

    addToMetamask(token) {
      if (!token) {
        return;
      }

      let options = {};
      token = token.toString();

      if (token === 'PAD') {
        options = {
          address: this.$root?.allowedChains[this.$root.chainId]?.padTokenContract,
          symbol: token,
          decimals: 18,
          image: padb64,
        };
      } else if (token === 'aPAD') {
        options = {
          address: this.$root?.allowedChains[this.$root.chainId]?.aPadTokenContract,
          symbol: token,
          decimals: 18,
          image: apadb64,
        };
      }

      window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options,
        },
      });
    },

    setIsCopied(name) {
      if (name == 'PAD') {
        this.isPadCopied = true;
      } else {
        this.isAPadCopied = true;
      }

      setTimeout(() => {
        if (name == 'PAD') {
          this.isPadCopied = false;
        } else {
          this.isAPadCopied = false;
        }
      }, 1500);
    },
  },
};
</script>
