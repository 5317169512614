<template>
  <main class="container-page">
    <template v-if="!maintenance.show">
      <the-header :key="updateKey" />

      <div v-if="isLoading" :key="$id('loading')" class="card-row-message message-loading">
        <g-loader class="m-t-64" />
      </div>

      <div v-else-if="isFailed" :key="$id('cannot')" class="card-row-message message-danger">Cannot load data...</div>

      <router-view v-else />

      <transaction-list />

      <the-footer />
    </template>

    <maintenance-page v-else :time="maintenance.time" />
  </main>
</template>

<script>
import allowedChains from '@/allowedChains';
import TheHeader from '@/views/TheHeader';
import TheFooter from '@/views/TheFooter';
import MaintenancePage from '@/views/Maintenance';
import { useTransactions } from '@/store/transactions.js';
import { useWeb3 } from '@/store/web3.js';
import { inject } from 'vue';
import { ethers } from 'ethers';
import GLoader from '@/components/g-loader.vue';
import TransactionList from '@StakingPage/TransactionList';

export default {
  components: {
    TheHeader,
    TheFooter,
    GLoader,
    TransactionList,
    MaintenancePage,
  },

  setup() {
    inject('$vfm');

    return {
      allowedChains,
      transactions: useTransactions(),
      web3Instance: useWeb3(),
    };
  },

  data: () => ({
    errorInfo: null,
    updateKey: 0,
    isLoading: true,
    isFailed: false,

    activePools: [],
    upcomingPools: [],
    filledPools: [],
    poolsInfoHash: {},

    isActiveRequestInProgress: false,
    isProposalsLoading: false,

    maintenance: {
      time: '1653991200',
      show: false,
    },
  }),

  computed: {
    provider() {
      const instance = this.web3Instance;
      if (!instance) {
        return null;
      }

      if (!this.chainId) {
        return null;
      }

      const provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
      return provider;
    },

    chainId() {
      const instance = this.web3Instance;
      if (!instance) {
        return null;
      }

      return parseInt(instance.chainId);
    },

    account() {
      const instance = this.web3Instance;
      if (!instance) {
        return null;
      }

      if (instance.user) {
        //eslint-disable-next-line
        this.transactions.account = instance.user.wallet_address;
        return instance.user.wallet_address;
      } else {
        //eslint-disable-next-line
        this.transactions.account = instance.account;
        return instance.account;
      }
    },
  },

  created() {
    this.$eventBus.on('connect', (args) => {
      this.chainConnected(args);
    });
    this.$eventBus.on('disconnect', () => {
      this.chainDisconnected();
    });
    this.$eventBus.on('account', (args) => {
      this.accountUpdated(args);
    });
    this.$eventBus.on('chain', (args) => {
      this.chainChanged(args);
    });
    this.$eventBus.on('block', (args) => {
      this.blockUpdated(args);
    });
    this.$eventBus.on('error-info', (args) => {
      this.errorInfoUpdate(args);
    });
    this.$eventBus.on('load-pools', () => {
      this.loadPools();
    });

    this.$eventBus.on('load-proposals', async () => {
      await this.loadProposals();
    });

    this.loadPools();
  },

  beforeUnmount() {
    this.$eventBus.off('connect', (args) => {
      this.chainConnected(args);
    });
    this.$eventBus.off('disconnect', () => {
      this.chainDisconnected();
    });
    this.$eventBus.off('account', (args) => {
      this.accountUpdated(args);
    });
    this.$eventBus.off('chain', (args) => {
      this.chainChanged(args);
    });
    this.$eventBus.off('block', (args) => {
      this.blockUpdated(args);
    });
    this.$eventBus.off('error-info', (args) => {
      this.errorInfoUpdate(args);
    });
    this.$eventBus.on('load-pools', () => {
      this.loadPools();
    });

    this.$eventBus.off('load-proposals', async () => {
      await this.loadProposals();
    });
  },

  methods: {
    chainConnected(chainId) {
      this.chainId = chainId;
      this.errorInfoUpdate('');
    },

    chainDisconnected() {
      this.account = null;
      this.blockNumber = null;
      this.chainId = null;
    },

    accountUpdated(account) {
      this.account = account;
      this.transactions.account = account;
    },

    chainChanged(chainId) {
      this.chainId = chainId;
      this.forceUpdate();
    },

    blockUpdated(blockNumber) {
      this.blockNumber = blockNumber;
    },

    errorInfoUpdate(info) {
      this.errorInfo = info;
    },

    forceUpdate() {
      this.updateKey++;
    },

    possiblyUpdateIDOStatus() {
      const currentDate = Date.now();

      for (const poolAddress in this.poolsInfoHash) {
        const currentPool = this.poolsInfoHash[poolAddress];
        const currentStatus = currentPool.status;

        if (currentStatus == 'upcoming') {
          if (currentPool.startedAtTimestamp == 0) {
            continue;
          }

          if (currentPool.startedAtTimestamp * 1000 > currentDate) {
            continue;
          }

          const newStatus = currentPool.endedAtTimestamp * 1000 <= currentDate ? 'filled' : 'active';

          for (const key in this[currentStatus + 'Pools']) {
            const pool = this[currentStatus + 'Pools'][key];

            if (pool.poolAddress !== currentPool.poolAddress) {
              continue;
            }

            pool.status = newStatus;
            this[newStatus + 'Pools'].push(pool);
            this.poolsInfoHash[poolAddress] = pool;

            this[currentStatus + 'Pools'].splice(key, 1);
            break;
          }
        }

        if (currentStatus == 'active') {
          if (currentPool.endedAtTimestamp * 1000 > currentDate) {
            continue;
          }

          const newStatus = 'filled';

          for (const key in this[currentStatus + 'Pools']) {
            const pool = this[currentStatus + 'Pools'][key];

            if (pool.poolAddress !== currentPool.poolAddress) {
              continue;
            }

            pool.status = newStatus;
            this[newStatus + 'Pools'].push(pool);
            this.poolsInfoHash[poolAddress] = pool;

            this[currentStatus + 'Pools'].splice(key, 1);
            break;
          }
        }
      }
    },

    async loadPools() {
      this.isLoading = true;

      const response = await this.$json.get(process.env.VUE_APP_API_URL_PREFIX + '/pools');

      if (!response.json?.success) {
        this.isFailed = true;
        this.isLoading = false;
        return;
      }

      this.activePools = response.json.activePools;
      this.upcomingPools = response.json.upcomingPools;
      this.filledPools = response.json.filledPools;

      const poolsHash = {};

      ['active', 'upcoming', 'filled'].forEach((key) => {
        for (const item of this[key + 'Pools']) {
          poolsHash[item?.poolAddress] = item;
        }
      });

      this.poolsInfoHash = poolsHash;
      this.possiblyUpdateIDOStatus();

      this.isLoading = false;
    },

    async setActiveChain(chainId, onDone = () => {}) {
      await window.ethereum
        .request({
          method: 'wallet_switchEthereumChain',
          params: [
            {
              chainId: '0x' + chainId.toString(16),
            },
          ],
        })
        .catch(async (err) => {
          if (err.code == 4902) {
            try {
              await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                  {
                    chainId: '0x' + chainId.toString(16),
                    chainName: this.$root.allowedChains[chainId]?.name,
                    rpcUrls: [this.$root.allowedChains[chainId]?.rpcUrl],
                  },
                ],
              });
            } catch (addError) {
              console.log('setActiveChain addError', err);
              onDone = false;
            }
          }
          console.log('setActiveChain', err);
          onDone = false;
        });

      if (onDone) {
        this.web3Instance.chainId = chainId;

        onDone();
      }
    },

    async loadProposals() {
      if (this.isProposalsLoading) {
        return;
      }

      this.isProposalsLoading = true;

      const response = await this.$json.get(process.env.VUE_APP_API_URL_PREFIX + '/proposals');

      this.isProposalsLoading = false;
      if (!response.json?.success) {
        return;
      }

      this.$root.proposals = JSON.parse(JSON.stringify(response.json.proposals));
    },
  },
};
</script>
