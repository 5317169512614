<template>
  <div class="dp__theme_dark custom-time-picker-component">
    <select class="select-input" :value="hours" @change="$emit('update:hours', +$event.target.value)">
      <option v-for="h in hoursArray" :key="h.value" :value="h.value">{{ h.text }}</option>
    </select>
    <select class="select-input" :value="minutes" @change="$emit('update:minutes', +$event.target.value)">
      <option v-for="m in minutesArray" :key="m.value" :value="m.value">{{ m.text }}</option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'TimePicker',

  props: {
    hoursIncrement: { type: [Number, String], default: 1 },
    minutesIncrement: { type: [Number, String], default: 1 },
    is24: { type: Boolean, default: true },
    hoursGridIncrement: { type: [String, Number], default: 1 },
    minutesGridIncrement: { type: [String, Number], default: 1 },
    range: { type: Boolean, default: false },
    filters: { type: Object, default: () => ({}) },
    minTime: { type: Object, default: () => ({}) },
    maxTime: { type: Object, default: () => ({}) },
    timePicker: { type: Boolean, default: false },
    hours: { type: [Number, Array], default: 0 },
    minutes: { type: [Number, Array], default: 0 },
    customProps: { type: Object, default: null },
  },

  emits: ['update:hours', 'update:minutes'],

  data: () => ({}),

  computed: {
    hoursArray() {
      const arr = [];
      for (let i = 0; i < 24; i++) {
        arr.push({ text: i < 10 ? `0${i}` : i, value: i });
      }
      return arr;
    },

    minutesArray() {
      const arr = [];
      for (let i = 0; i < 60; i++) {
        arr.push({ text: i < 10 ? `0${i}` : i, value: i });
      }
      return arr;
    },
  },
};
</script>
