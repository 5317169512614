<template>
  <div class="proposal-step">
    <div class="box-header">Contact information</div>

    <div class="hint m-b-24">Personal details will be visible only to the members of SmartPad team</div>

    <div class="row">
      <div class="col-6 col-md-12">
        <g-form-field
          :id="$id('contactEmail')"
          :label="contactEmail.label"
          :type="contactEmail.type"
          :placeholder="contactEmail.placeholder"
          :value="contactEmail.value"
          :error-text="v$.contactEmail.value.$errors[0]?.$message"
          @input="contactEmail.value = $event"
        />
      </div>

      <div class="col-6 col-md-12">
        <g-form-field
          :id="$id('contactTg')"
          :label="contactTg.label"
          :type="contactTg.type"
          :placeholder="contactTg.placeholder"
          :value="contactTg.value"
          :error-text="v$.contactTg.value.$errors[0]?.$message"
          @input="contactTg.value = $event"
        />
      </div>
    </div>

    <div class="text-center m-t-32">
      <button
        class="btn btn-bigger btn-multicolor invert btn-monument font-normal"
        :class="{ 'disabled': isFieldsProjectError || isErrorProposalFields }"
        @click="$emit('show-preview')"
      >
        Preview
      </button>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { email, helpers } from '@vuelidate/validators';

export default {
  name: 'ProposalStepAdditional',

  props: {
    proposalFields: {
      type: Object,
      required: true,
    },

    isErrorProposalFields: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['show-preview', 'fields-error'],

  data() {
    return {
      v$: useVuelidate(),
      contactEmail: this.proposalFields.contactEmail,
      contactTg: this.proposalFields.contactTg,
    };
  },

  computed: {
    isFieldsProjectError() {
      if (this.v$?.$invalid) {
        this.v$.$validate();
      }

      const isError = this.v$.$invalid && (this.v$.contactEmail.$error || this.v$.contactTg.$error);
      this.$emit('fields-error', isError);
      return isError;
    },
  },

  validations() {
    return {
      contactEmail: {
        value: {
          email,
          mustBeFilled: helpers.withMessage(
            () => `Please fill in at least one of the fields`,
            () => this.contactEmail.value.length || this.contactTg.value.length
          ),
        },
      },
      contactTg: {
        value: {
          mustBeFilled: helpers.withMessage(
            () => `Please fill in at least one of the fields`,
            () => this.contactEmail.value.length || this.contactTg.value.length
          ),
        },
      },
    };
  },
};
</script>
