<template>
  <div class="card-item-wrap">
    <button v-if="poolInfo" class="card-item box-shadow" @click="handleIdoClick">
      <div class="card-item-border-animate"></div>

      <div class="card-item_name-price">
        <div class="flex m-b-20 flex__1">
          <img class="hover-rotateY card-item_logo" :src="poolInfo.logoUrl" alt="icon" />

          <div class="m-l-24">
            <div
              class="label-small label-bg-noborder m-b-12"
              :class="{ 'label-upcoming': poolInfo.status == 'upcoming' }"
            >
              {{ poolStatus }}
            </div>

            <div class="name">
              <span class="m-r-12">{{ poolInfo.name }}</span>
              {{ poolInfo.buyTokenSymbol }}
            </div>
          </div>
        </div>

        <table class="table-price">
          <tr>
            <td class="total">Total Raise</td>
            <td class="total">Initial Price</td>
          </tr>

          <tr>
            <td v-if="poolInfo.type == 'lottery'" class="price">{{ $formatAmount(totalRaised, 'n/a', true) }} ETH</td>
            <td v-else class="price">
              {{ $formatAmount(totalRaised) }}
            </td>

            <td v-if="poolInfo.type == 'lottery'" class="price">
              {{ $formatPrice(priceOneToken, 'n/a', true, 4) }} ETH
            </td>
            <td v-else class="price">
              {{ $formatPrice(priceOneToken, 'n/a', false, 4) }}
            </td>
          </tr>

          <tr>
            <td class="total-small p-t-16">Network</td>
            <td class="total-small p-t-16">Type</td>
          </tr>

          <tr>
            <td>
              <div class="icon-wrap">
                <img
                  :src="$root.allowedChains[poolInfo.homeNetwork].icon"
                  :alt="$root.allowedChains[poolInfo.homeNetwork].name"
                />
                <span class="total-small p-v-12">{{ $root.allowedChains[poolInfo.homeNetwork].name }}</span>
              </div>
            </td>

            <td>
              <span class="title-name">{{ poolInfo.type }}</span>
            </td>
          </tr>
        </table>
      </div>

      <div v-if="progressPercent" class="card-item_progress-bar">
        <g-progress-bar
          :min-amount="0"
          :max-amount="maxSellAmount"
          :percent="progressPercent"
          :pool-type="poolInfo.type"
        ></g-progress-bar>
      </div>

      <div class="card-item_start-end">
        <div>
          <span class="title">Start date (local):</span>
          <template v-if="+poolInfo.startedAtTimestamp > 0">
            <span class="date">{{ $getFormattedDate(poolInfo.startedAtTimestamp) }}</span>
            <span class="time">{{ $getFormattedTime(poolInfo.startedAtTimestamp) }}</span>
          </template>
          <span v-else class="text-multicolor">n/a</span>
        </div>

        <div v-if="+poolInfo.startedAtTimestamp > 0">
          <span class="title">End date (local):</span>
          <span class="date">{{ $getFormattedDate(poolInfo.endedAtTimestamp) }}</span>
          <span class="time">{{ $getFormattedTime(poolInfo.endedAtTimestamp) }}</span>
        </div>
      </div>

      <div v-if="+poolInfo.startedAtTimestamp > 0 && launchInLabel" class="card-item_launch arrow">
        {{ launchInLabel }}
      </div>
    </button>
  </div>
</template>

<script>
import GProgressBar from '@/components/g-progressbar.vue';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import BigNumber from 'bignumber.js/bignumber.mjs';
dayjs.extend(utc);

export default {
  name: 'CardItem',

  components: {
    GProgressBar,
  },

  props: ['poolAddress'],

  data: () => ({
    launchInLabel: null,
    refreshCountdown: null,
  }),

  computed: {
    poolInfo() {
      if (!this.poolAddress) {
        return {};
      }

      return this.$root?.poolsInfoHash[this.poolAddress] || {};
    },

    poolStatus() {
      if (this.poolInfo.status == 'filled') {
        return 'closed';
      }

      return this.poolInfo.status;
    },

    progressPercent() {
      if (this.poolInfo.status === 'upcoming') {
        return 0;
      }

      if (this.poolInfo.status === 'active') {
        return 0;
      }

      return ((this.poolInfo?.totalBuyAmount / this.poolInfo?.distributedBuyAmount) * 100).toFixed(2);
    },

    maxSellAmount() {
      const Q112 = BigNumber(2 ** 112);
      const amount = BigNumber(this.poolInfo.distributedBuyAmount)
        .multipliedBy(Q112)
        .dividedBy(this.poolInfo.currentPriceBuyInSellUQ)
        .dividedBy(10 ** this.poolInfo.sellTokenDecimals);

      return amount;
    },

    priceOneToken() {
      const Q112 = BigNumber(2 ** 112);
      const amount = BigNumber(1)
        .multipliedBy(10 ** this.poolInfo.buyTokenDecimals)
        .multipliedBy(Q112)
        .dividedBy(this.poolInfo.currentPriceBuyInSellUQ)
        .dividedBy(10 ** this.poolInfo.sellTokenDecimals);

      return amount;
    },

    totalRaised() {
      if (this.poolInfo?.totalSellAmount) {
        return this.poolInfo.totalSellAmount / 10 ** this.poolInfo.sellTokenDecimals;
      } else {
        return this.maxSellAmount.toNumber();
      }
    },
  },

  mounted() {
    this.countdown();
  },

  beforeUnmount() {
    clearInterval(this.refreshCountdown);
  },

  methods: {
    async handleIdoClick() {
      await this.$root.setActiveChain(parseInt(this.$root?.poolsInfoHash[this.poolAddress]?.homeNetwork), () => {
        this.$router.push({ name: 'IdoPage', params: { poolAddress: this.poolAddress } });
      });
    },

    countdown() {
      const status = this.poolInfo.status.toLowerCase();

      if (['active', 'upcoming'].includes(status)) {
        this.launchInLabel = this.getLaunchInLabel(status);

        this.refreshCountdown = setInterval(() => {
          this.launchInLabel = this.getLaunchInLabel(status);
        }, 1000);
      }
    },

    getLaunchInLabel(status) {
      if (status === 'active') {
        const convertedEndDate = dayjs.unix(this.poolInfo.endedAtTimestamp);

        return `Ends in: ${new Date(convertedEndDate.diff(dayjs(), 'ms')).toISOString().substr(11, 8)}`;
      }

      if (status === 'upcoming') {
        const convertedStartDate = dayjs.unix(this.poolInfo.startedAtTimestamp);

        if (!this.poolInfo.startedAtTimestamp) {
          return null;
        }

        const oneDayInMs = 24 * 60 * 60 * 1000;
        const diff = convertedStartDate.diff(dayjs(), 'ms');

        if (diff < 0) {
          this.$eventBus.emit('load-pools');
          return null;
        }

        if (diff > oneDayInMs) {
          const countOfDays = parseInt(diff / oneDayInMs);
          return `Launch in: ${countOfDays} day${countOfDays > 1 ? 's' : ''}`;
        }

        return `Launch in: ${new Date(diff).toISOString().substr(11, 8)}`;
      }

      return null;
    },
  },
};
</script>
