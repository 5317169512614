<template>
  <g-banner @on-close="$emit('on-close')">
    <div class="row">
      <div class="col-8">
        <h4>Veteran multiplier</h4>
        <div class="banner-text">
          You have additional ratio
          <span class="multiplier">x{{ multiplier / 10000 }}</span>
        </div>
      </div>

      <div class="col-4">
        <div class="flex-v__center flex-h__center height-full">
          <button class="btn-multicolor btn-big" @click="applyClick">Apply</button>
        </div>
      </div>
    </div>
  </g-banner>
</template>

<script>
export default {
  name: 'DaoBanner',

  props: {
    multiplier: {
      type: Number,
      default: 10000,
    },
  },

  emits: ['on-close', 'apply-multiplier'],

  methods: {
    applyClick() {
      this.$emit('apply-multiplier');
    },
  },
};
</script>
