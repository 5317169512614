<template>
  <div class="container calendar-page">
    <div class="main-title">
      <div class="row">
        <div class="col-6">
          <h1 class="m-b-0">Calendar</h1>
          <div class="main-subtitle">Timeline of all IDOs unlocks</div>
        </div>
        <div class="col-6 flex-h__end">
          <img class="main-title-img" src="/static/images/calendar.png" alt="calendar" />
        </div>
      </div>
    </div>

    <ul class="calendar-list">
      <li class="timer">
        <div class="timer-in" @click="scrollToFirstActualElement">
          <svg width="20" height="20" viewBox="0 0 32 32">
            <g transform="scale(0.03125 0.03125)">
              <path
                d="M658.744 749.256l-210.744-210.746v-282.51h128v229.49l173.256 173.254zM512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 896c-212.078 0-384-171.922-384-384s171.922-384 384-384c212.078 0 384 171.922 384 384s-171.922 384-384 384z"
              ></path>
            </g>
          </svg>

          <div class="timer-in-date">
            <div class="date-main">
              <span>
                <g-date :timestamp="+currentTime" part="date" :with-seconds="true" />
              </span>
              <g-date :timestamp="+currentTime" part="time" :with-seconds="true" />
              <div class="date-UTC inline-block m-l-4">(local)</div>
            </div>

            <div class="date-UTC">
              <g-date :timestamp="+currentTime" part="datetime" :utc="true" :with-seconds="true" />
              (UTC)
            </div>
          </div>
        </div>
      </li>

      <li
        v-for="(item, index) in unlockData"
        :key="index"
        class="calendar-item"
        :class="{ 'disabled': isPastTime(item.timestamp) }"
      >
        <div class="row">
          <div class="col-3 flex-v__center">
            <img class="icon" :src="item.icon" :alt="item.name" />
            <div class="project-name">
              {{ item.name }}
              <span class="m-l-8"></span>
              <span>{{ item.tokenSymbol }}</span>
            </div>
          </div>
          <div class="col-4 col-md-3 flex-v__center flex-h__center">
            <div class="vesting">{{ item.percent }}%</div>
          </div>
          <div class="col-3 flex-direction__column flex-h__center">
            <div class="date-main">
              <span>
                <g-date :timestamp="item.timestamp" part="date" />
              </span>
              <g-date :timestamp="item.timestamp" part="time" />
              <div class="date-UTC inline-block m-l-4">(local)</div>
            </div>

            <div class="date-UTC">
              <g-date :timestamp="item.timestamp" part="datetime" :utc="true" />
              (UTC)
            </div>
          </div>
          <div class="col-2 col-md-3 flex-v__center flex-h__end">
            <a class="btn-medium btn-transp height-full" :href="`/pool/${item.poolAddress}`">Withdraw</a>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'CalendarPage',

  data: () => ({
    unlockData: null,
    currentTime: null,
  }),

  created() {
    setInterval(() => {
      this.currentTime = new Date().getTime() / 1000;
    }, 1000);

    this.createDataList();
  },

  mounted() {
    setTimeout(() => {
      this.scrollToFirstActualElement();
    }, 1050);
  },

  methods: {
    async createDataList() {
      this.isLoading = true;

      const response = await this.$json.get(process.env.VUE_APP_API_URL_PREFIX + '/pools/unlock-timeline');

      if (!response.json?.success) {
        this.isLoading = false;
        return;
      }

      this.unlockData = response.json.data;
    },

    isPastTime(time) {
      return time < this.currentTime;
    },

    scrollToFirstActualElement() {
      let triger = true;
      const list = document.getElementsByClassName('calendar-item');
      const arrList = Array.prototype.slice.call(list);

      arrList.forEach((item) => {
        if (!item.classList.contains('disabled') && triger) {
          const y = item.getBoundingClientRect().top + window.pageYOffset - 120;

          window.scrollTo({
            top: y,
            left: 0,
            behavior: 'smooth',
          });

          triger = false;
        }
      });
    },
  },
};
</script>
