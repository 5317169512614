<template>
  <ul class="ido-step-vesting">
    <li
      v-for="(item, index) in vesting"
      :key="index"
      :class="{ 'active': item.active, 'active-last': lastActive(index) }"
    >
      <div class="dot"></div>
      <span class="vesting">{{ item.percent }}%</span>
      <template v-if="item.timestamp">
        <span class="date">
          <span class="m-r-4">
            <g-date :timestamp="item.timestamp" part="datetime" />
          </span>
          <div class="date-local">(local)</div>
        </span>
        <span class="utc">
          <g-date :timestamp="item.timestamp" part="datetime" :utc="true" />
          (UTC)
        </span>
      </template>
      <span v-else class="date text-multicolor animation-blink">Coming Soon</span>
      <div v-if="item.isTGE" class="label-tge">TGE</div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'ListClaiming',

  props: {
    vesting: {
      type: Array,
      required: true,
    },
  },

  methods: {
    lastActive(i) {
      return this.vesting[i]?.active && !this.vesting[i + 1]?.active;
    },
  },
};
</script>
