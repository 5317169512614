<template>
  <div class="ido-step-live">
    <div class="row m-h--40 m-b--28">
      <div class="col-6 col-md-12 p-h-40 m-b-28">
        <template v-if="isActive && !isTimeFcfs">
          <div v-if="launchInLabel" class="info-box-transp">
            <img class="flex-sh__0 m-r-16" src="/static/images/ido/clock.svg" alt="clock" />
            <span v-html="launchInLabel"></span>
          </div>

          <div class="ido-step-title">Available</div>

          <div class="flex-v__baseline flex-h__between flex-wrap__wrap">
            <div class="head">
              <span>
                {{ $formatUnitsToken(availableToBuy.toString(), poolInfo.buyTokenDecimals) }}
                {{ poolInfo.buyTokenSymbol }}
              </span>
              <div class="inline-block">
                ({{ $formatUnitsToken(maxSellToken.toString(), poolInfo.sellTokenDecimals) }}
                {{ poolInfo.sellTokenSymbol }})
              </div>
            </div>

            <div class="user-usdt-balance">
              Balance: {{ $formatUnitsToken(poolInfo?.sellTokenBalance, poolInfo.sellTokenDecimals) }}
              {{ poolInfo.sellTokenSymbol }}
            </div>
          </div>

          <div class="form-field m-t-32 ido-step-form-field" :class="{ 'field-error': isAmountInvalid }">
            <g-autonumeric v-model="amount" placeholder="Enter amount" />

            <div class="form-name-token">{{ poolInfo.sellTokenSymbol }}</div>

            <button
              class="form-btn btn-transp"
              :class="{ 'disabled': $formatUnitsToken(availableToBuy.toString(), poolInfo.buyTokenDecimals) == 0 }"
              @click="setMax()"
            >
              Max
            </button>

            <div class="error">Insufficient balance</div>

            <div v-if="willGetToken" class="predict-amount">
              You will get&nbsp;
              <div class="inline-block text-multicolor">
                {{ $formatPrice(willGetToken, null, true, 6) }} {{ poolInfo.buyTokenSymbol }}
              </div>
            </div>
          </div>

          <div class="row m-t-36">
            <div class="col-6">
              <div
                v-if="isApproveTransactionInProgress"
                class="btn-big btn-approve width-full no-click animation-blink"
              >
                Approving...
              </div>
              <button
                v-else
                class="btn-big btn-approve width-full"
                :class="{
                  'disabled':
                    amount <= 0 ||
                    isSellTokenAllowanceGiven ||
                    isAmountInvalid ||
                    isApproveTransactionInProgress ||
                    isBuyTransactionInProgress,
                }"
                @click="approve()"
              >
                Approve
              </button>
            </div>

            <div class="col-6">
              <div
                v-if="isBuyTransactionInProgress"
                class="btn-big btn-multicolor width-full btn-font-normal no-click animation-blink"
              >
                Processing...
              </div>
              <button
                v-else
                class="btn-big btn-multicolor width-full btn-font-normal"
                :class="{
                  'disabled':
                    amount <= 0 ||
                    !isSellTokenAllowanceGiven ||
                    isAmountInvalid ||
                    isApproveTransactionInProgress ||
                    isBuyTransactionInProgress,
                }"
                @click="buy()"
              >
                Buy
              </button>
            </div>
          </div>
        </template>

        <template v-else-if="isUpcoming">
          <div class="flex-direction__column flex-h__center flex-v__center height-full">
            <div class="m-b-16">
              <img src="/static/images/clockInCircle.svg" alt="Upcoming" />
            </div>

            <div class="ido-step-title m-b-0">Upcoming</div>
          </div>
        </template>

        <template v-else>
          <div class="flex-direction__column flex-h__center flex-v__center height-full">
            <div class="m-b-16">
              <img src="/static/images/doneInCircle.svg" alt="OK" />
            </div>

            <div class="ido-step-title m-b-0">Public sale round ends</div>
          </div>
        </template>
      </div>

      <div class="col-6 col-md-12 p-h-40 ido-step-border-left flex-direction__column flex-h__center m-b-28">
        <ido-progress-bar :options="progressBarOptions" />

        <div class="row m-b-16">
          <div class="col-6">
            <div class="field-info-title">Allocation:</div>

            <div v-if="whitelistAllocation" class="basic-info">
              {{ $formatPrice(whitelistAllocation, 'n/a', true, 0) }} {{ poolInfo.buyTokenSymbol }}
            </div>
            <template v-else>n/a</template>
          </div>

          <div class="col-6">
            <div class="field-info-title">Purchased:</div>

            <div v-if="whitelistAllocation" class="basic-info">
              {{ $formatUnitsToken(poolInfo.boughtAmount?.toString(), poolInfo.buyTokenDecimals) }}
              {{ poolInfo.buyTokenSymbol }}
            </div>

            <div v-else>n/a</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from '@IdoPage/steps/mixinsLiveFcfs.js';
import BigNumber from 'bignumber.js/bignumber.mjs';

export default {
  name: 'IdoStepLive',

  mixins: [mixins],

  computed: {
    availableToBuy() {
      if (!this.whitelistAllocation) {
        return BigNumber(0);
      }

      let amount = BigNumber(this.whitelistAllocation).shiftedBy(this.poolInfo.buyTokenDecimals);

      if (this.poolInfo.boughtAmount) {
        amount = amount.minus(BigNumber(this.poolInfo.boughtAmount.toString()));
      }

      return amount;
    },
  },

  created() {
    this.refreshCountdown = setInterval(() => this.getLaunchInLabel('active'), 1000);
    this.getLaunchInLabel('active');
  },

  beforeUnmount() {
    clearInterval(this.refreshCountdown);
  },
};
</script>
