<template>
  <div class="proposal-step">
    <div class="box-header">IDO details</div>

    <div class="row">
      <div class="col-6 col-md-12">
        <div class="form-field" :class="{ 'field-error': v$.totalAllocation.$error }">
          <label v-if="totalAllocation.label">{{ totalAllocation.label }}</label>
          <div class="hint">Maximum purchasable number of tokens</div>
          <g-auto-numeric v-model="getTotalAllocationValue" :placeholder="totalAllocation.placeholder" />
          <div v-if="v$.totalAllocation.$error" class="error">
            {{ getErrorMessage(v$.totalAllocation.value.$errors[0]) }}
          </div>
        </div>
      </div>

      <div class="col-6 col-md-12">
        <div class="form-field" :class="{ 'field-error': v$.priceOneToken.$error, 'show-predict-amount': willGetUSDT }">
          <label v-if="priceOneToken.label">{{ priceOneToken.label }}</label>
          <div class="hint">Token price in USDT (per 1 token)</div>
          <div class="prefix-icon">
            <img src="/static/images/proposals/icon-$.svg" :alt="priceOneToken.label" />
          </div>
          <g-auto-numeric v-model="getPriceTokenValue" class="p-l-24" :placeholder="priceOneToken.placeholder" />
          <div v-if="v$.priceOneToken.$error" class="error">
            {{ getErrorMessage(v$.priceOneToken.value.$errors[0]) }}
          </div>
          <div v-if="false" class="predict-amount">
            (Estimated raise:
            <span>{{ $formatPrice(willGetUSDT, null, true, 2) }}</span>
            USDT)
          </div>
        </div>
      </div>

      <div class="col-6 col-md-12">
        <g-date-picker
          :label="votingDateUTC.label"
          :hint="'Please select the deadline for DAO to vote on your proposal. A minimum of 7 days is required to complete the review.'"
          :value="votingDateUTC.value"
          :error-text="getErrorMessage(v$.votingDateUTC.value.$errors[0])"
          @changed="votingDateUTC.value = $event"
        />
      </div>

      <div class="col-6 col-md-12">
        <g-date-picker
          :label="tgeDateUTC.label"
          :hint="'Please select the TGE date (at least 1 day after the proposal review is completed).'"
          :value="tgeDateUTC.value"
          :error-text="getErrorMessage(v$.tgeDateUTC.value.$errors[0])"
          @changed="tgeDateUTC.value = $event"
        />
      </div>
    </div>

    <div class="btns flex-h__center m-t-32">
      <button
        class="btn btn-bigger btn-multicolor invert"
        :class="{ 'disabled': isFieldsProjectError }"
        @click="goNextStep"
      >
        Next
      </button>
      <button
        class="btn btn-bigger btn-approve invert btn-monument font-normal"
        :class="{ 'disabled': isFieldsProjectError || isErrorProposalFields }"
        @click="$emit('show-preview')"
      >
        Preview
      </button>
    </div>
  </div>
</template>

<script>
import GAutoNumeric from '@/components/g-autonumeric.vue';
import useVuelidate from '@vuelidate/core';
import { required, helpers, maxValue, minValue } from '@vuelidate/validators';

const mustBeMoreThanTodayPlus7Days = (value) => {
  const result = new Date();
  result.setDate(result.getDate() + 7);
  return value >= result.getTime();
};

export default {
  name: 'ProposalStepIDO',

  components: {
    GAutoNumeric,
  },

  props: {
    proposalFields: {
      type: Object,
      required: true,
    },

    isErrorProposalFields: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['next-step', 'fields-error', 'show-preview'],

  data() {
    return {
      v$: useVuelidate(),
      totalAllocation: this.proposalFields.totalAllocation,
      priceOneToken: this.proposalFields.priceOneToken,
      tgeDateUTC: this.proposalFields.tgeDateUTC,
      votingDateUTC: this.proposalFields.votingDateUTC,
    };
  },

  computed: {
    isFieldsProjectError() {
      if (this.v$?.$invalid) {
        this.v$.$validate();
      }

      const isError =
        this.v$.$invalid &&
        (this.v$.totalAllocation.$error ||
          this.v$.priceOneToken.$error ||
          this.v$.tgeDateUTC.$error ||
          this.v$.votingDateUTC.$error);
      this.$emit('fields-error', isError);
      return isError;
    },

    getTotalAllocationValue: {
      get() {
        return this.totalAllocation.value;
      },
      set(newValue) {
        this.totalAllocation.value = newValue.toString();
      },
    },

    getPriceTokenValue: {
      get() {
        return this.priceOneToken.value;
      },
      set(newValue) {
        this.priceOneToken.value = Number(newValue.toString());
      },
    },

    willGetUSDT() {
      if (!this.priceOneToken.value || !this.totalAllocation.value) {
        return null;
      }

      return +this.totalAllocation.value * +this.priceOneToken.value;
    },
  },

  validations() {
    return {
      totalAllocation: {
        value: {
          // required,
          minValueTotal: minValue(1),
          maxValueTotal: maxValue(10 ** 9),
        },
      },
      priceOneToken: {
        value: {
          // required,
          minValuePrice: minValue(0.001),
          maxValuePrice: maxValue(10),
        },
      },
      tgeDateUTC: {
        value: {
          required,
          mustBeMoreThanEndDate: helpers.withMessage(
            () => `At least 1 day after the "Proposal voting deadline"`,
            (value) => {
              const result = new Date(this.votingDateUTC.value);
              return value > result.getTime();
            }
          ),
        },
      },
      votingDateUTC: {
        value: {
          required,
          mustBeMoreThanTodayPlus7Days: helpers.withMessage(
            () => `At least 7 days from the current date/time`,
            mustBeMoreThanTodayPlus7Days
          ),
        },
      },
    };
  },

  methods: {
    goNextStep() {
      this.v$.$validate();

      if (!this.isFieldsProjectError) {
        this.$emit('next-step');
      }
    },

    getErrorMessage(error) {
      if (!error) {
        return null;
      }

      if (error.$validator == 'required') {
        return 'This field is required to proceed to the next step';
      }

      return error.$message;
    },
  },
};
</script>
