<template>
  <div class="ido-step-claim">
    <div class="row m-h--40 m-b--28">
      <div v-if="poolInfo.type == 'whitelist'" class="col-6 col-md-12 p-h-40 m-b-28">
        <template v-if="!isUpcoming">
          <div class="info-box-transp">
            <img class="flex-sh__0 m-r-16" src="/static/images/ido/clock.svg" alt="clock" />

            <template v-if="nextClaimTimestamp">
              Next claim:
              <span class="m-l-8 text-multicolor text-left" style="min-width: 82px">
                <g-date :timestamp="nextClaimTimestamp" part="datetime" />
              </span>
            </template>

            <template v-else>Claim period was finished</template>
          </div>

          <div class="ido-step-title">Available for claiming</div>

          <div class="flex-v__center flex-h__between flex-wrap__wrap m-b-8">
            <div class="head m-b-8">
              <span>
                {{ $formatUnitsToken(poolInfo?.claimAmount, poolInfo.buyTokenDecimals, 3) }}
                {{ poolInfo.buyTokenSymbol }}
              </span>
            </div>

            <div class="user-usdt-balance m-b-8">
              Wallet balance: {{ $formatUnitsToken(poolInfo?.buyTokenBalance, poolInfo.buyTokenDecimals) }}
              {{ poolInfo.buyTokenSymbol }}
            </div>
          </div>

          <div v-if="isClaimTransactionInProgress" class="info-box-big info-box-multicolor animation-blink m-b-24">
            Claiming
          </div>

          <button
            v-else
            class="btn-big btn-multicolor width-full btn-font-normal m-b-24"
            :class="{
              'disabled': !isClaimAvailable || isClaimTransactionInProgress,
            }"
            @click="claim()"
          >
            Claim
          </button>

          <div v-if="poolInfo.dexUrl" class="flex-v__center flex-h__between flex-wrap__wrap">
            <div class="user-usdt-balance m-b-12">
              <button class="btn-medium btn-multicolor invert btn-montserrat" @click="goToDex">Go to DEX</button>
            </div>
          </div>
        </template>

        <template v-else>
          <div class="flex-direction__column flex-h__center flex-v__center height-full">
            <div class="m-b-16">
              <img src="/static/images/clockInCircle.svg" alt="Upcoming" />
            </div>

            <div class="ido-step-title m-b-0">Upcoming</div>
          </div>
        </template>
      </div>

      <div v-else class="col-12 p-h-40 m-b-16">
        <div class="field-info-title">Lottery pools doesn't have claiming</div>
      </div>

      <div
        v-if="poolInfo.type == 'whitelist'"
        class="col-6 col-md-12 p-h-40 ido-step-border-left flex-direction__column flex-h__center m-b-28"
      >
        <div class="ido-step-title">Vesting</div>

        <list-claiming :vesting="vestingList" />
      </div>
    </div>
  </div>
</template>

<script>
import ListClaiming from '@IdoPage/steps/ListClaiming';
import PoolAbi from '@/artifacts/Pool';
import { ethers } from 'ethers';
import BigNumber from 'bignumber.js/bignumber.mjs';

export default {
  name: 'IdoStepClaim',

  components: {
    ListClaiming,
  },

  props: {
    poolInfo: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    vestingList: [],
    isClaimTransactionInProgress: false,
  }),

  computed: {
    nextClaimTimestamp() {
      let timestamp = null;

      for (const item of this.vestingList) {
        if (item.active) {
          continue;
        }

        timestamp = item.timestamp;
        break;
      }

      return timestamp;
    },

    isClaimAvailable() {
      return this.poolInfo?.claimAmount && BigNumber(this.poolInfo.claimAmount.toString()).isGreaterThan(BigNumber(0));
    },

    isUpcoming() {
      return this.poolInfo.status === 'upcoming';
    },
  },

  mounted() {
    this.vestingList.push({
      isTGE: true,
      active: this.isActive(this.poolInfo?.tgeTimestamp),
      timestamp: this.poolInfo?.tgeTimestamp,
      percent: this.poolInfo?.tgePercent,
    });

    const vestingInfo = this.poolInfo?.vestingInfo;

    if (Array.isArray(vestingInfo) && vestingInfo.length > 0) {
      for (const item of vestingInfo) {
        this.vestingList.push({
          active: this.isActive(item.timestamp),
          timestamp: item.timestamp,
          percent: item.percent,
        });
      }
    }
  },

  methods: {
    isActive(timestamp) {
      const currentTimeMs = Date.now() / 1000;

      return currentTimeMs > timestamp;
    },

    claim() {
      this.isClaimTransactionInProgress = true;

      const signer = this.$root?.provider.getSigner();
      const contract = new ethers.Contract(this.poolInfo?.poolAddress, PoolAbi, signer);

      this.$root.transactions.listen({
        info: `Claim ${this.$formatUnitsToken(this.poolInfo?.claimAmount, this.poolInfo?.buyTokenDecimals)} ${
          this.poolInfo?.buyTokenSymbol
        }`,
        transactionRequest: contract.withdraw(),
        onDone: (isSuccess = false) => {
          this.isClaimTransactionInProgress = false;

          if (isSuccess) {
            this.$eventBus.emit('update-pool');
          }
        },
      });
    },

    goToDex() {
      window.open(this.poolInfo.dexUrl, '_blank');
    },
  },
};
</script>
