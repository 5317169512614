<template>
  <div class="ido-step-whitelist">
    <div class="row m-h--40 m-b--28">
      <div class="col-6 col-md-12 p-h-40 m-b-28">
        <div v-if="poolInfo.type == 'whitelist'" class="info-box-transp">
          <img class="flex-sh__0 m-r-16" src="/static/images/ido/clock.svg" alt="clock" />
          <template v-if="isUpcoming && launchInLabel">
            IDO starts in
            <span class="m-l-8 text-multicolor text-left" style="min-width: 82px">{{ launchInLabel }}</span>
          </template>
          <template v-else-if="poolInfo?.participants">Whitelist was formed</template>
          <template v-else>Whitelist not formed yet</template>
        </div>

        <div v-if="poolInfo.type == 'whitelist'" class="row">
          <div class="col-6">
            <div class="field-info-title">Status:</div>
            <div
              v-if="poolInfo?.participants"
              class="label-small label-bg-noborder fw-500"
              :class="{ 'label-danger': !whitelistAllocation }"
            >
              <template v-if="whitelistAllocation">Whitelisted</template>
              <template v-else>Not whitelisted</template>
            </div>
            <div v-else class="basic-info">-</div>
          </div>
          <div class="col-6">
            <div class="field-info-title m-b-6">Allocation:</div>

            <div class="basic-info">
              <template v-if="whitelistAllocation">
                {{ $formatPrice(whitelistAllocation, 'n/a', true, 0) }} {{ poolInfo.buyTokenSymbol }}
              </template>
              <template v-else>-</template>
            </div>
          </div>
        </div>
        <div v-else class="row width-full">
          <div class="col-12">
            <div class="field-info-title">Lottery pools doesn't have registration</div>
          </div>
        </div>

        <button v-if="false" class="btn-big btn-multicolor width-full btn-font-normal m-t-28" @click="seeWhitelist">
          See whitelist
        </button>
      </div>

      <div class="col-6 col-md-12 p-h-40 ido-step-border-left flex-direction__column flex-h__center m-b-28">
        <div class="row m-b-16">
          <div class="col-6">
            <div class="field-info-title">IDO start date (local):</div>

            <template v-if="poolInfo.startedAtTimestamp">
              <div class="date-main">
                <span>
                  <g-date :timestamp="poolInfo.startedAtTimestamp" part="date" />
                </span>
                <g-date :timestamp="poolInfo.startedAtTimestamp" part="time" />
              </div>

              <div class="date-UTC">
                <g-date :timestamp="poolInfo.startedAtTimestamp" part="datetime" :utc="true" />
                (UTC)
              </div>
            </template>
            <span v-else>n/a</span>
          </div>

          <div class="col-6">
            <div class="field-info-title">IDO end date (local):</div>

            <template v-if="poolInfo.endedAtTimestamp">
              <div class="date-main">
                <span>
                  <g-date :timestamp="poolInfo.endedAtTimestamp" part="date" />
                </span>
                <g-date :timestamp="poolInfo.endedAtTimestamp" part="time" />
              </div>

              <div class="date-UTC">
                <g-date :timestamp="poolInfo.endedAtTimestamp" part="datetime" :utc="true" />
                (UTC)
              </div>
            </template>
            <span v-else>n/a</span>
          </div>
        </div>

        <div v-if="poolInfo.type == 'whitelist'" class="row">
          <div class="col-6">
            <div class="field-info-title">FCFS start (local):</div>

            <template v-if="poolInfo.fcfsStartedAtTimestamp">
              <div class="date-main">
                <span>
                  <g-date :timestamp="poolInfo.fcfsStartedAtTimestamp || null" part="date" />
                </span>
                <g-date :timestamp="poolInfo.fcfsStartedAtTimestamp" part="time" />
              </div>

              <div class="date-UTC">
                <g-date :timestamp="poolInfo.fcfsStartedAtTimestamp" part="datetime" :utc="true" />
                (UTC)
              </div>
            </template>
            <span v-else>n/a</span>
          </div>

          <div v-if="poolInfo?.participants?.length" class="col-6">
            <div class="field-info-title">Whitelisted:</div>

            <div class="basic-info">{{ poolInfo?.participants?.length }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export default {
  name: 'IdoStepWhitelist',

  props: {
    poolInfo: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    launchInLabel: null,
  }),

  computed: {
    isWhitelistOver() {
      const currentTimeMs = Date.now() / 1000;

      if (currentTimeMs < this.poolInfo?.registrationEndsAtTimestamp) {
        return false;
      }

      return true;
    },

    whitelistAllocation() {
      if (!this.$root.account) {
        return null;
      }

      if (!Array.isArray(this.poolInfo?.participants)) {
        return null;
      }

      let allocation = null;

      this.poolInfo?.participants.forEach((participant) => {
        if (this.$root.account.toLowerCase() === participant.address.toLowerCase()) {
          allocation = parseInt(participant.allowedAllocation);
        }
      });

      return allocation;
    },

    isUpcoming() {
      return this.poolInfo.status === 'upcoming';
    },
  },

  mounted() {
    this.countdown();
  },

  methods: {
    countdown() {
      if (this.isUpcoming) {
        this.launchInLabel = this.getLaunchInLabel();

        setInterval(() => {
          this.launchInLabel = this.getLaunchInLabel();
        }, 1000);
      }
    },

    getLaunchInLabel() {
      const convertedStartDate = dayjs.unix(this.poolInfo.startedAtTimestamp);

      const oneDayInMs = 24 * 60 * 60 * 1000;
      const diff = convertedStartDate.diff(dayjs(), 'ms');

      if (diff < 0) {
        return null;
      }

      if (diff > oneDayInMs) {
        const countOfDays = parseInt(diff / oneDayInMs);
        return `${countOfDays} day${countOfDays > 1 ? 's' : ''}`;
      }

      return `${new Date(diff).toISOString().substr(11, 8)}`;
    },

    seeWhitelist() {
      console.log('See whitelist');
    },
  },
};
</script>
