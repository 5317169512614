<template>
  <div class="ido-info box-shadow">
    <div class="box-header">
      <img :src="poolInfo.logoUrl" alt="logo" />
      Details
    </div>

    <div class="ido-info_primary m-b-12">
      <div class="col-6">
        <div class="ido-info_primary-text">Total raise</div>

        <div class="ido-info_big-text m-b-4">
          {{ $formatAmount(totalAllocation, 'n/a', true) }} {{ poolInfo.buyTokenSymbol }}
        </div>

        <div v-if="poolInfo.type == 'lottery'" class="total-small">
          {{ $formatPrice(totalMoneyAllocation, 'n/a', true, 0) }} ETH
        </div>
        <div v-else class="total-small">{{ $formatPrice(totalMoneyAllocation, 'n/a', false, 0) }}</div>
      </div>

      <div class="col-6">
        <div class="ido-info_primary-text">
          <span class="m-r-12">Initial Price</span>

          <div v-if="poolInfo.buyTokenAddress" class="btns flex-wrap__nowrap">
            <div class="btn">
              <g-tooltip position="left" content="Copy adress" :custom-icon="false" animation>
                <button
                  class="btn-only-icon-copy"
                  :class="{ 'copied': isTokenCopied }"
                  @click="
                    (e) => {
                      setIsCopiedToken();
                      return copy(poolInfo.buyTokenAddress, e);
                    }
                  "
                ></button>
              </g-tooltip>
            </div>
            <div class="btn">
              <g-tooltip position="left" content="Add to Metamask" :custom-icon="false" animation>
                <button class="btn-only-icon-metamask" @click="() => addTokenToMetamask()"></button>
              </g-tooltip>
            </div>
          </div>
        </div>
        <p v-if="poolInfo.type == 'lottery'" class="ido-info_big-text">
          {{ $formatPrice(priceOneToken, 'n/a', true, 4) }} ETH
        </p>
        <p v-else class="ido-info_big-text">{{ $formatPrice(priceOneToken, 'n/a', false, 4) }}</p>
      </div>
    </div>

    <div class="ido-info_primary m-b-24">
      <div class="col-6">
        <p class="ido-info_secondary-text">Network</p>
        <div class="icon-wrap">
          <img
            :src="$root.allowedChains[poolInfo.homeNetwork].icon"
            :alt="$root.allowedChains[poolInfo.homeNetwork].name"
          />
          <span class="ido-info_network-type">{{ $root.allowedChains[poolInfo.homeNetwork].name }}</span>
        </div>
      </div>

      <div class="col-6">
        <p class="ido-info_secondary-text">Type</p>
        <p class="ido-info_network-type">
          {{ poolInfo.type }}
        </p>
      </div>
    </div>

    <div class="ido-info_dates">
      <div v-if="!poolInfo.startedAtTimestamp" class="field-info-title p-h-8">
        Start date (local):
        <span class="text-multicolor fw-500 m-l-8">n/a</span>
      </div>
      <template v-else>
        <div class="col-6">
          <div class="field-info-title">Start date (local):</div>

          <div class="date-main">
            <span>
              <g-date :timestamp="poolInfo.startedAtTimestamp" part="date" />
            </span>
            <g-date :timestamp="poolInfo.startedAtTimestamp" part="time" />
          </div>

          <div class="date-UTC">
            <g-date :timestamp="poolInfo.startedAtTimestamp" part="datetime" :utc="true" />
            (UTC)
          </div>
        </div>

        <div class="col-6">
          <div class="field-info-title">End date (local):</div>

          <div class="date-main">
            <span>
              <g-date :timestamp="poolInfo.endedAtTimestamp" part="date" />
            </span>

            <g-date :timestamp="poolInfo.endedAtTimestamp" part="time" />
          </div>

          <div class="date-UTC">
            <g-date :timestamp="poolInfo.endedAtTimestamp" part="datetime" :utc="true" />
            (UTC)
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import BigNumber from 'bignumber.js/bignumber.mjs';

export default {
  name: 'IdoInfo',

  props: {
    poolInfo: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    isTokenCopied: false,
  }),

  computed: {
    priceOneToken() {
      const Q112 = BigNumber(2 ** 112);
      const amount = BigNumber(1)
        .multipliedBy(10 ** this.poolInfo.buyTokenDecimals)
        .multipliedBy(Q112)
        .dividedBy(this.poolInfo.currentPriceBuyInSellUQ)
        .dividedBy(10 ** this.poolInfo.sellTokenDecimals);

      return amount;
    },

    totalAllocation() {
      if (this.poolInfo?.distributedBuyAmount) {
        return this.poolInfo.distributedBuyAmount / 10 ** this.poolInfo.buyTokenDecimals;
      }

      return 0;
    },

    totalMoneyAllocation() {
      return this.totalAllocation * this.priceOneToken;
    },

    isUpcoming() {
      return this.poolInfo.status === 'upcoming';
    },
  },

  methods: {
    copy(text, event) {
      const el = event.target;

      navigator.clipboard
        .writeText(text)
        .then(() => {
          el.classList.add('copied');
          setTimeout(() => el.classList.remove('copied'), 2000);
        })
        .catch(() => {
          /* do nothing */
        });
    },

    addTokenToMetamask() {
      const options = {
        address: this.poolInfo.buyTokenAddress,
        symbol: this.poolInfo.buyTokenSymbol,
        decimals: this.poolInfo.buyTokenDecimals,
        image: this.poolInfo.logoUrl,
      };

      window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options,
        },
      });
    },

    setIsCopiedToken() {
      this.isTokenCopied = true;

      setTimeout(() => {
        this.isTokenCopied = false;
      }, 1500);
    },
  },
};
</script>
