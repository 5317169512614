<template>
  <div class="visual-box box-shadow">
    <div class="box-header">
      <img class="m-r-16" src="/static/images/ido-steps.svg" alt="steps" />
      IDO flow
    </div>

    <ul class="steps-box" :class="{ 'no-click': isPreventClickTabs }">
      <li v-for="(item, index) in stepList" :key="index" @click="setActiveStep(index)">
        <g-tooltip
          v-if="item.tooltip"
          :position="getPositionArrow(index) || 'bottom'"
          :content="item.tooltip"
          :custom-icon="false"
          animation
        >
          <button class="arrow-box" :class="{ 'active': item.active }">
            <svg class="arrow-box-start" width="16" height="32" viewBox="0 0 16 32" xmlns="http://www.w3.org/2000/svg">
              <path d="m0.27969,-0.38813l15.36113,16.7002" />
              <path d="m15.63477,15.64308l-15.31248,16.7349" />
            </svg>
            {{ item.nameHr }}
            <svg class="arrow-box-end" width="16" height="32" viewBox="0 0 16 32" xmlns="http://www.w3.org/2000/svg">
              <path d="m16.09501,0.4778l-15.02678,0.00786l14.57259,15.82642" />
              <path d="m15.65545,15.67989l-14.6446,15.85091l15.09948,-0.00911" />
            </svg>
          </button>
        </g-tooltip>
        <template v-else>
          <div class="arrow-box" :class="{ 'active': item.active }">
            <svg class="arrow-box-start" width="16" height="32" viewBox="0 0 16 32" xmlns="http://www.w3.org/2000/svg">
              <path d="m0.27969,-0.38813l15.36113,16.7002" />
              <path d="m15.63477,15.64308l-15.31248,16.7349" />
            </svg>
            {{ item.nameHr }}
            <svg class="arrow-box-end" width="16" height="32" viewBox="0 0 16 32" xmlns="http://www.w3.org/2000/svg">
              <path d="m16.09501,0.4778l-15.02678,0.00786l14.57259,15.82642" />
              <path d="m15.65545,15.67989l-14.6446,15.85091l15.09948,-0.00911" />
            </svg>
          </div>
        </template>
      </li>
    </ul>

    <transition-group name="toTop">
      <ido-step-upcoming v-if="activeName === 'upcoming'" :pool-info="poolInfo" />
      <ido-step-whitelist v-if="activeName === 'whitelist'" :pool-info="poolInfo" />
      <ido-step-live v-if="activeName === 'live'" :pool-info="poolInfo" @is-prevent-tabs="isPreventTabs" />
      <ido-step-fcfs v-if="activeName === 'fcfs'" :pool-info="poolInfo" @is-prevent-tabs="isPreventTabs" />
      <ido-step-claim v-if="activeName === 'claim'" :pool-info="poolInfo" />
    </transition-group>
  </div>
</template>

<script>
import IdoStepUpcoming from '@IdoPage/steps/IdoStepUpcoming';
import IdoStepWhitelist from '@IdoPage/steps/IdoStepWhitelist';
import IdoStepLive from '@IdoPage/steps/IdoStepLive';
import IdoStepFcfs from '@IdoPage/steps/IdoStepFcfs';
import IdoStepClaim from '@IdoPage/steps/IdoStepClaim';

export default {
  name: 'IdoSteps',

  components: {
    IdoStepUpcoming,
    IdoStepWhitelist,
    IdoStepLive,
    IdoStepFcfs,
    IdoStepClaim,
  },

  props: ['poolInfo'],

  data: () => ({
    stepList: [
      {
        name: 'upcoming',
        nameHr: 'Registration',
        active: false,
      },
      {
        name: 'whitelist',
        nameHr: 'Whitelist',
        active: false,
      },
      {
        name: 'live',
        nameHr: 'In progress',
        active: false,
      },
      {
        name: 'fcfs',
        nameHr: 'FCFS',
        active: false,
      },
      {
        name: 'claim',
        nameHr: 'Claim',
        active: false,
      },
    ],
    activeName: null,
    isPreventClickTabs: false,
  }),

  mounted() {
    this.setActiveStep();
  },

  methods: {
    setActiveStep(index = null) {
      const status = this.poolInfo.status;
      if (status === 'filled') {
        this.activeName = 'claim';
      } else if (status === 'upcoming') {
        this.activeName = 'upcoming';
      } else {
        const currentTimeMs = Date.now() / 1000;
        if (
          this.poolInfo?.fcfsStartedAtTimestamp &&
          this.poolInfo?.fcfsStartedAtTimestamp <= currentTimeMs &&
          this.poolInfo?.endedAtTimestamp >= currentTimeMs
        ) {
          this.activeName = 'fcfs';
        } else {
          this.activeName = 'live';
        }
      }

      let indexActiveStep = index;

      if (index === null) {
        indexActiveStep = this.stepList.findIndex((item) => item.name === this.activeName);
      }

      this.stepList.forEach((step, index) => {
        step.active = false;

        if (index <= indexActiveStep) {
          step.active = true;
          this.activeName = step.name;
        }
      });
    },

    getPositionArrow(index) {
      let position = '';

      if (index <= 1) {
        position = 'bottom-start';
      } else if (index >= this.stepList.length - 2) {
        position = 'bottom-end';
      } else {
        position = 'bottom';
      }

      return position;
    },

    isPreventTabs(val) {
      this.isPreventClickTabs = val;
    },
  },
};
</script>
