import GAutonumeric from '@/components/g-autonumeric.vue';
import IdoProgressBar from '@IdoPage/steps/IdoProgressBar';
import BigNumber from 'bignumber.js/bignumber.mjs';
import WhitelistAbi from '@/artifacts/Whitelist.json';
import { ethers } from 'ethers';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export default {
  components: {
    GAutonumeric,
    IdoProgressBar,
  },

  props: {
    poolInfo: {
      type: Object,
      default: () => {},
    },
  },

  emits: ['isPreventTabs'],

  data: () => ({
    amount: 0,
    progressBarOptions: {},

    isApproveTransactionInProgress: false,
    isBuyTransactionInProgress: false,

    sellTokenAllowanceGiven: null,
    launchInLabel: null,

    isTimeFcfs: false,
  }),

  computed: {
    maxSellToken() {
      const Q112 = BigNumber(2 ** 112);
      const amount = this.availableToBuy.multipliedBy(Q112).dividedBy(this.poolInfo.currentPriceBuyInSellUQ);

      return BigNumber(Math.round(amount.toString()));
    },

    isAmountInvalid() {
      return BigNumber(this.amount).shiftedBy(this.poolInfo.sellTokenDecimals).isGreaterThan(this.maxSellToken);
    },

    priceOneToken() {
      const Q112 = BigNumber(2 ** 112);
      const amount = BigNumber(1)
        .multipliedBy(10 ** this.poolInfo.buyTokenDecimals)
        .multipliedBy(Q112)
        .dividedBy(this.poolInfo.currentPriceBuyInSellUQ)
        .dividedBy(10 ** this.poolInfo.sellTokenDecimals);

      return amount;
    },

    whitelistAllocation() {
      if (!this.$root.account?.toLowerCase()) {
        return null;
      }

      if (!Array.isArray(this.poolInfo?.participants)) {
        return null;
      }

      let allocation = null;

      this.poolInfo?.participants.forEach((participant) => {
        if (this.$root.account.toLowerCase() === participant.address.toLowerCase()) {
          allocation = parseInt(participant.allowedAllocation);
        }
      });

      return allocation;
    },

    isUpcoming() {
      return this.poolInfo.status === 'upcoming';
    },

    isActive() {
      return this.poolInfo.status === 'active';
    },

    isSellTokenAllowanceGiven() {
      if (!this.sellTokenAllowanceGiven) {
        return false;
      }

      if (this.amount === 'null' || this.amount === null || !+this.amount) {
        return false;
      }

      const amount = BigNumber(this.amount);

      return this.sellTokenAllowanceGiven
        .dividedBy(10 ** this.poolInfo.sellTokenDecimals)
        .isGreaterThanOrEqualTo(amount);
    },

    willGetToken() {
      if (!this.priceOneToken || !this.amount) {
        return null;
      }

      return this.amount / this.priceOneToken;
    },
  },

  watch: {
    poolInfo: {
      immediate: true,
      handler() {
        this.getProgressBarOptions();
      },
    },

    isTimeFcfs: {
      immediate: true,
      handler(newIsTime, oldIsTime) {
        if (newIsTime && newIsTime !== oldIsTime) return;

        this.$eventBus.emit('update-pool');
      },
    },
  },

  mounted() {
    this.getBuyInfo();
    this.getProgressBarOptions();
  },

  created() {
    this.$eventBus.on('update-progressbar', () => {
      this.getProgressBarOptions();
    });
  },

  beforeUnmount() {
    this.$eventBus.off('update-progressbar', () => {
      this.getProgressBarOptions();
    });
  },

  methods: {
    async getBuyInfo() {
      if (!this.poolInfo.sellTokenContract) {
        setTimeout(() => this.getBuyInfo(), 100);
        return;
      }
      const allowance = await this.poolInfo.sellTokenContract.allowance(
        this.$root.account,
        this.poolInfo.whitelistAddress
      );

      this.sellTokenAllowanceGiven = BigNumber(allowance.toString());
    },

    getProgressBarOptions() {
      const Q112 = BigNumber(2 ** 112);

      const max = BigNumber(this.poolInfo.distributedBuyAmount)
        .multipliedBy(Q112)
        .dividedBy(this.poolInfo.currentPriceBuyInSellUQ)
        .dividedBy(10 ** this.poolInfo.sellTokenDecimals);

      const raised = BigNumber(this.poolInfo.totalSellAmount).dividedBy(10 ** this.poolInfo.sellTokenDecimals);

      this.progressBarOptions = {
        sellTokenSymbol: this.poolInfo.sellTokenSymbol,
        raised,
        max,
      };
    },

    async approve() {
      this.isApproveTransactionInProgress = true;
      this.$emit('isPreventTabs', this.isApproveTransactionInProgress);

      this.$root.transactions.listen({
        info: `Approve ${this.amount} ${this.poolInfo.sellTokenSymbol}`,
        transactionRequest: this.poolInfo.sellTokenContract.approve(
          this.poolInfo.whitelistAddress,
          '0x' + BigNumber(this.amount).shiftedBy(this.poolInfo.sellTokenDecimals).toString(16)
        ),
        onDone: (isSuccess = false) => {
          this.isApproveTransactionInProgress = false;
          this.$emit('isPreventTabs', this.isApproveTransactionInProgress);

          if (isSuccess) {
            this.sellTokenAllowanceGiven = BigNumber(this.amount).shiftedBy(this.poolInfo.sellTokenDecimals);
          }
        },
      });
    },

    buy() {
      this.isBuyTransactionInProgress = true;

      this.$emit('isPreventTabs', this.isBuyTransactionInProgress);

      const signer = this.$root.provider?.getSigner();
      const whitelistContract = new ethers.Contract(this.poolInfo.whitelistAddress, WhitelistAbi, signer);

      if (!this.poolInfo.whitelistProof) {
        console.log('No merkle tree proof');
        this.isBuyTransactionInProgress = false;
        this.$emit('isPreventTabs', this.isBuyTransactionInProgress);
        return;
      }

      this.$root.transactions.listen({
        info: `Buy ${this.poolInfo.name}`,
        transactionRequest: whitelistContract.exchange(
          this.poolInfo.whitelistProof,
          '0x' + BigNumber(this.whitelistAllocation).shiftedBy(this.poolInfo.buyTokenDecimals).toString(16),
          '0x' + BigNumber(this.amount).shiftedBy(this.poolInfo.sellTokenDecimals).toString(16)
        ),
        onDone: async (isSuccess = false) => {
          if (isSuccess) {
            this.$eventBus.emit('update-pool');
            this.amount = 0;
          }

          this.isBuyTransactionInProgress = false;
          this.$emit('isPreventTabs', this.isBuyTransactionInProgress);
        },
      });
    },

    getLaunchInLabel(state) {
      let options = {};
      if (state === 'active') {
        options = {
          time: 'fcfsStartedAtTimestamp',
          label: 'starts',
        };
      } else {
        options = {
          time: 'endedAtTimestamp',
          label: 'ends',
        };
      }
      const convertedEndDate = dayjs.unix(this.poolInfo[options.time]);

      const currentTimeMs = Math.round(dayjs().valueOf() / 1000);

      this.isTimeFcfs =
        this.poolInfo?.fcfsStartedAtTimestamp &&
        this.poolInfo?.fcfsStartedAtTimestamp >= currentTimeMs &&
        this.poolInfo?.endedAtTimestamp >= currentTimeMs;

      if (this.isTimeFcfs) {
        this.$eventBus.emit('update-pool');
      }

      this.launchInLabel = `FCFS ${
        options.label
      } in <span class="m-l-4 text-multicolor inline-block text-left" style="min-width: 82px;">${new Date(
        convertedEndDate.diff(dayjs(), 'ms')
      )
        .toISOString()
        .substr(11, 8)}</span>`;
    },

    setMax() {
      this.amount = this.$formatUnitsToken(this.maxSellToken.toString(), this.poolInfo.sellTokenDecimals);
    },
  },
};
