<template>
  <div class="proposal-step">
    <div class="box-header">General information</div>

    <div class="hint m-b-24">Please describe your project</div>

    <div class="row">
      <div class="col-6 col-md-12">
        <g-form-field
          :id="$id('projectName')"
          :label="projectName.label"
          :type="projectName.type"
          :placeholder="projectName.placeholder"
          :value="projectName.value"
          :messages="{ required: 'Please fill in the Name field' }"
          :error-text="getErrorMessage(v$.projectName.value.$errors[0])"
          @input="projectName.value = $event"
        />
      </div>

      <div class="col-6 col-md-12">
        <g-form-field
          :id="$id('telegramLink')"
          :label="telegramLink.label"
          :type="telegramLink.type"
          icon="/static/images/proposals/link.svg"
          :placeholder="telegramLink.placeholder"
          :value="telegramLink.value"
          :error-text="getErrorMessage(v$.telegramLink.value.$errors[0])"
          @input="telegramLink.value = $event"
        />
      </div>

      <div class="col-6 col-md-12">
        <g-form-field
          :id="$id('mediumLink')"
          :label="mediumLink.label"
          :type="mediumLink.type"
          icon="/static/images/proposals/link.svg"
          :placeholder="mediumLink.placeholder"
          :value="mediumLink.value"
          :error-text="getErrorMessage(v$.mediumLink.value.$errors[0])"
          @input="mediumLink.value = $event"
        />
      </div>

      <div class="col-6 col-md-12">
        <g-form-field
          :id="$id('twitterLink')"
          :label="twitterLink.label"
          :type="twitterLink.type"
          icon="/static/images/proposals/link.svg"
          :placeholder="twitterLink.placeholder"
          :value="twitterLink.value"
          :error-text="getErrorMessage(v$.twitterLink.value.$errors[0])"
          @input="twitterLink.value = $event"
        />
      </div>

      <div class="col-6 col-md-12">
        <g-form-field
          :id="$id('websiteLink')"
          :label="websiteLink.label"
          :type="websiteLink.type"
          icon="/static/images/proposals/link.svg"
          :placeholder="websiteLink.placeholder"
          :value="websiteLink.value"
          :error-text="getErrorMessage(v$.websiteLink.value.$errors[0])"
          @input="websiteLink.value = $event"
        />
      </div>

      <div class="col-6 col-md-12">
        <div class="form-field">
          <label v-if="aboutProject.label" for="aboutProject">
            {{ aboutProject.label }}
          </label>
          <textarea
            name="aboutProject"
            rows="1"
            :value="aboutProject.value"
            :placeholder="aboutProject.placeholder"
            @input="aboutProject.value = $event.target.value"
          ></textarea>
        </div>
      </div>
    </div>

    <div class="btns flex-h__center m-t-32">
      <button
        class="btn btn-bigger btn-multicolor invert"
        :class="{ 'disabled': isFieldsProjectError }"
        @click="goNextStep"
      >
        Next
      </button>
      <button
        class="btn btn-bigger btn-approve invert btn-monument font-normal"
        :class="{ 'disabled': isFieldsProjectError || isErrorProposalFields }"
        @click="$emit('show-preview')"
      >
        Preview
      </button>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, url, maxLength, minLength, requiredUnless } from '@vuelidate/validators';

export default {
  name: 'ProposalStepProject',

  props: {
    proposalFields: {
      type: Object,
      required: true,
    },

    isErrorProposalFields: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['next-step', 'fields-error', 'show-preview'],

  data() {
    return {
      v$: useVuelidate(),
      projectName: this.proposalFields.projectName,
      telegramLink: this.proposalFields.telegramLink,
      mediumLink: this.proposalFields.mediumLink,
      twitterLink: this.proposalFields.twitterLink,
      aboutProject: this.proposalFields.aboutProject,
      websiteLink: this.proposalFields.websiteLink,
    };
  },

  computed: {
    isFieldsProjectError() {
      if (this.v$?.$invalid) {
        this.v$.$validate();
      }

      const isError =
        this.v$.$invalid &&
        (this.v$.projectName.$error ||
          this.v$.telegramLink.$error ||
          this.v$.mediumLink.$error ||
          this.v$.websiteLink.$error ||
          this.v$.twitterLink.$error);
      this.$emit('fields-error', isError);
      return isError;
    },
  },

  validations() {
    return {
      projectName: {
        value: {
          required,
          minLengthValue: minLength(3),
          maxLengthValue: maxLength(20),
        },
      },
      telegramLink: {
        value: {
          requiredIfValue: requiredUnless(url),
          url,
          maxLengthValue: maxLength(255),
        },
      },
      mediumLink: {
        value: {
          requiredIfValue: requiredUnless(url),
          url,
          maxLengthValue: maxLength(255),
        },
      },
      twitterLink: {
        value: {
          requiredIfValue: requiredUnless(url),
          url,
          maxLengthValue: maxLength(255),
        },
      },
      websiteLink: {
        value: {
          required,
          url,
          maxLengthValue: maxLength(255),
        },
      },
    };
  },

  methods: {
    goNextStep() {
      this.v$.$validate();

      if (!this.isFieldsProjectError) {
        this.$emit('next-step');
      }
    },

    getErrorMessage(error) {
      if (!error) {
        return null;
      }

      if (error.$validator == 'required') {
        return 'This field is required to proceed to the next step';
      }

      return error.$message;
    },
  },
};
</script>
