<template>
  <div class="proposal-preview">
    <h4 class="m-b-32">Proposal preview</h4>

    <template v-if="proposalInfo">
      <div class="row">
        <div class="col-7 col-md-12 p-h-16 m-b-32">
          <proposal-prime-info :pool-info="proposalInfo" :is-preview="true" />
        </div>
        <div class="col-5 col-md-12 p-h-16 m-b-32">
          <proposal-ido-info :pool-info="proposalInfo" />
        </div>
      </div>

      <proposal-vesting-info :pool-info="proposalInfo" :is-preview="true" />

      <div class="btns flex-h__center m-t-36 m-b-40">
        <div class="btn btn-bigger btn-multicolor invert btn-montserrat proposal-preview-edit" @click="$emit('edit')">
          Edit
        </div>
        <div
          class="btn btn-bigger btn-multicolor btn-montserrat proposal-preview-create"
          @click="$emit('create-proposal')"
        >
          Create proposal
          <div class="fee">
            Fee:
            <span class="text-multicolor">{{ $formatUnitsToken(feeInfo?.padFee || null) }} PAD</span>
            or
            <span class="eth-color">{{ $formatUnitsToken(feeInfo?.ethFee || null) }} ETH</span>
          </div>
        </div>
      </div>
    </template>

    <template v-else>
      <g-loader :key="$id('loading')" />
    </template>
  </div>
</template>

<script>
import ProposalPrimeInfo from './component/ProposalPrimeInfo';
import ProposalVestingInfo from './component/ProposalVestingInfo';
import ProposalIdoInfo from './component/ProposalIdoInfo';
import GLoader from '@/components/g-loader.vue';

export default {
  name: 'ProposalPreview',

  components: {
    ProposalPrimeInfo,
    ProposalVestingInfo,
    ProposalIdoInfo,
    GLoader,
  },

  props: {
    proposalInfo: {
      type: Object,
      required: true,
    },
    feeInfo: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {};
  },

  emits: ['edit', 'create-proposal'],
};
</script>
