<template>
  <div class="proposal-step">
    <div class="box-header">Vesting period</div>

    <div class="row">
      <!-- <div class="col-6 col-md-12">
        <div class="form-field" :class="{ 'field-error': v$.priceToken.$error }">
          <label v-if="priceToken.label">{{ priceToken.label }}</label>
          <g-autonumeric v-model="getPriceTokenValue" />
          <div class="error">error</div>
        </div>
      </div> -->

      <!-- <div class="col-6 col-md-12">
        <g-date-picker :label="tgeDateUTC.label" :value="tgeDateUTC.value" />
      </div> -->

      <!-- <div class="col-6 col-md-12">
        <g-form-field
          :id="$id('listing')"
          :label="listing.label"
          :type="listing.type"
          :placeholder="listing.placeholder"
          :value="listing.value"
          @input="listing.value = $event"
        />
      </div> -->

      <div class="col-6 col-md-12">
        <div class="form-field" :class="{ 'field-error': v$.tgePercent.$error }">
          <label v-if="tgePercent.label">{{ tgePercent.label }}</label>
          <div class="hint">
            Please indicate the number of tokens available at TGE and their vesting schedule, if applicable.
          </div>
          <input
            v-model="getTgePercentValue"
            min="0"
            max="100"
            type="number"
            @keydown="stopSymbol"
            @keyup.enter="$event.target.blur()"
          />
          <div v-if="v$.tgePercent.$error" class="error">{{ getErrorMessage(v$.tgePercent.value.$errors[0]) }}</div>
        </div>
      </div>
    </div>

    <transition-group name="toTop">
      <div v-if="getPercentageBalance" class="inline-block">
        <div v-if="false" class="hint m-b-0 m-t-8">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fugiat alias, exercitationem numquam vero rem nobis
          quas cupiditate saepe quis voluptas at laudantium.
        </div>

        <div class="vesting-row">
          <div class="vesting-col">Vesting duration</div>

          <div class="vesting-col">
            <div class="form-field vesting-period">
              <input v-model="getCountValue" min="1" type="number" @keyup.enter="$event.target.blur()" />
            </div>

            <div class="btns">
              <button
                v-for="name in vesting.period"
                :key="name"
                class="btn btn-small btn-vesting"
                :class="{ 'active': vesting.periodValue == name }"
                @click="() => (vesting.periodValue = name)"
              >
                {{ name }}(s)
              </button>
            </div>
          </div>

          <div class="vesting-col hint">
            <!-- <div class="form-field vesting-percentage">
              <input
                v-model="getPercentageValue"
                min="1"
                max="100"
                type="number"
                @keyup.enter="returnPercent(), $event.target.blur()"
              />
            </div> -->

            ({{ getPercentageValue }}% {{ vesting.periodValue == 'month' ? 'monthly' : 'weekly' }} unlock)
          </div>
        </div>

        <div class="form-field" :class="{ 'field-error': v$.sumPercentage.$error }">
          <div class="error">{{ v$.sumPercentage.$errors[0]?.$message }}</div>
        </div>
      </div>
    </transition-group>

    <div class="btns flex-h__center m-t-32">
      <button
        class="btn btn-bigger btn-multicolor invert"
        :class="{ 'disabled': isFieldsProjectError }"
        @click="goNextStep"
      >
        Next
      </button>
      <button
        class="btn btn-bigger btn-approve invert btn-monument font-normal"
        :class="{ 'disabled': isFieldsProjectError || isErrorProposalFields }"
        @click="$emit('show-preview')"
      >
        Preview
      </button>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, numeric, between, helpers } from '@vuelidate/validators';

const mustBe100 = (value) => value === 100;

export default {
  name: 'ProposalStepVesting',

  props: {
    proposalFields: {
      type: Object,
      required: true,
    },

    isErrorProposalFields: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['next-step', 'fields-error', 'show-preview'],

  data() {
    return {
      v$: useVuelidate(),
      tgePercent: this.proposalFields.tgePercent,
      // priceToken: this.proposalFields.priceToken,
      tgeDateUTC: this.proposalFields.tgeDateUTC,
      // listing: this.proposalFields.listing,
      vesting: this.proposalFields.vesting,
    };
  },

  computed: {
    isFieldsProjectError() {
      const isError = this.v$.tgePercent.$error || this.v$.sumPercentage.$error;
      this.$emit('fields-error', isError);
      return isError;
    },

    getPercentageBalance() {
      return this.tgePercent.value < 100;
    },

    sumPercentage() {
      return this.getTgePercentValue + this.getPercentageValue * this.vesting.countValue;
    },

    getTgePercentValue: {
      get() {
        return this.tgePercent.value;
      },
      set(newValue) {
        this.tgePercent.value = newValue;
        this.getPercentageValue = (100 - this.tgePercent.value) / this.vesting.countValue;
      },
    },

    getCountValue: {
      get() {
        return this.vesting.countValue;
      },
      set(newValue) {
        this.vesting.countValue = newValue;
        this.getPercentageValue = (100 - this.tgePercent.value) / this.vesting.countValue;
      },
    },

    getPercentageValue: {
      get() {
        return this.vesting.percentageValue;
      },
      set(newValue) {
        this.vesting.percentageValue = newValue;
      },
    },
  },

  mounted() {
    this.v$.$validate();
    this.vesting.percentageValue = (100 - this.tgePercent.value) / this.vesting.countValue;
  },

  validations() {
    return {
      tgePercent: {
        value: {
          required,
          numeric,
          betweenValue: between(0, 100),
        },
      },
      sumPercentage: {
        mustBe100: helpers.withMessage(() => `The sum must be 100%`, mustBe100),
      },
    };
  },

  methods: {
    goNextStep() {
      this.v$.$validate();

      if (!this.isFieldsProjectError) {
        this.$emit('next-step');
      }
    },

    stopSymbol($event) {
      if (['+', '-', 'e', '.'].includes($event.key)) {
        $event.preventDefault();
      }
    },

    returnPercent() {
      const percent = 100 - this.sumPercentage;
      if (percent !== 0) {
        this.tgePercent.value += percent;
      }
    },

    getErrorMessage(error) {
      if (!error) {
        return null;
      }

      if (error.$validator == 'required') {
        return 'This field is required to proceed to the next step';
      }

      return error.$message;
    },
  },
};
</script>
