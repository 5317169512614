<template>
  <g-modal
    v-model="isShowModal"
    name="poolInfo"
    :classes="['vfm--modal', 'pool-info-modal']"
    :click-to-close="true"
    :is-show-close-button="true"
    :width="580"
    @close-modal="closeModal"
  >
    <template #header>
      <h3 class="m-b-36">Pool info</h3>
    </template>

    <template #content>
      <div class="pool-info-row">
        <div class="pool-info-item-title mob-order-1">PAD in pool</div>

        <div class="pool-info-item-title mob-order-3">aPAD minted</div>

        <div class="pool-info-item-title pool-info-p-l mob-order-9">
          <img class="m-r-8" src="/static/images/pad-coin.svg" alt="PAD" />
          1 PAD ≈
        </div>

        <div class="pool-info-item m-b-52 mob-order-2">
          {{ $formatUnits(poolInfo[$root.chainId]?.padBalanceOnStaking) }}
        </div>

        <div class="pool-info-item m-b-52 mob-order-4">
          {{ $formatUnits(poolInfo[$root.chainId]?.aPadTotalSupply) }}
        </div>

        <div class="pool-info-item pool-info-p-l m-b-52 mob-order-10">
          {{ $formatPrice(poolInfo[$root.chainId]?.padPrice, 'n/a', false, 4) }}
        </div>

        <div class="pool-info-item-title mob-order-5">Total PAD on {{ $root.allowedChains[$root.chainId].name }}:</div>

        <div class="pool-info-item-title mob-order-7">APR ≈</div>

        <div class="pool-info-item-title pool-info-p-l mob-order-11">
          <img class="m-r-8" src="/static/images/aPad-coin.svg" alt="aPAD" />
          1 aPAD ≈
        </div>

        <div class="pool-info-item mob-order-6">{{ $formatUnits(poolInfo[$root.chainId]?.padTotalSupply) }}</div>

        <div class="pool-info-item mob-order-8">{{ $formatPercent(poolInfo[$root.chainId]?.aprPercent) }}</div>

        <div class="pool-info-item pool-info-p-l mob-order-12">
          {{ $formatPrice(poolInfo[$root.chainId]?.oneAPadInPad, 'n/a', true, 4) }}
          <span v-if="poolInfo[$root.chainId]?.oneAPadInPad">PAD</span>
        </div>
      </div>
    </template>
  </g-modal>
</template>

<script>
export default {
  name: 'PoolInfo',

  props: ['poolInfo'],

  data: () => ({
    isShowModal: true,
  }),

  methods: {
    showModal() {
      this.$vfm.show('poolInfo');
    },

    closeModal() {
      this.$vfm.hide('poolInfo');
    },
  },
};
</script>
