<template>
  <div class="container">
    <div class="back-page">
      <router-link :to="{ name: 'PoolsPage' }">Back</router-link>
    </div>

    <template v-if="!isShowIdoPage">
      <template v-if="!web3Instance.active">
        <div class="text-multicolor animation-blink">Please, connect wallet first.</div>

        <div class="btn-big btn-transp m-t-24 m-r-a" @click="connect">Connect wallet</div>
      </template>

      <template v-else>
        <div class="text-multicolor animation-blink">
          Please, connect to {{ $root.allowedChains[poolInfo?.homeNetwork].name }} network first.
        </div>

        <div class="btn-big btn-transp m-t-24 m-r-a" @click="connectToHomeChain">
          Connect to {{ $root.allowedChains[poolInfo?.homeNetwork].name }} network
        </div>
      </template>
    </template>

    <template v-else-if="poolAddress">
      <div class="row m-h--16">
        <div class="col-7 col-md-12 p-h-16 m-b-32">
          <ido-prime :pool-info="poolInfo" />
        </div>
        <div class="col-5 col-md-12 p-h-16 m-b-32">
          <ido-info :pool-info="poolInfo" />
        </div>
      </div>

      <ido-steps :pool-info="poolInfo" />
    </template>

    <template v-else>
      <g-loader :key="$id('loading')" />
    </template>
  </div>
</template>

<script>
import IdoPrime from '@IdoPage/IdoPrime';
import IdoInfo from '@IdoPage/IdoInfo';
import IdoSteps from '@IdoPage/IdoSteps';
import GLoader from '@/components/g-loader.vue';
import PoolAbi from '@/artifacts/Pool';
import ERC20Abi from '@/artifacts/ERC20';
import { ethers } from 'ethers';
import { useWeb3 } from '../store/web3';
import axios from 'axios';
import MerkleTree from '@/MerkleTree.js';
import { solidityPack } from 'ethers/lib/utils';
import BigNumber from 'bignumber.js/bignumber.mjs';

export default {
  components: {
    IdoPrime,
    IdoInfo,
    IdoSteps,
    GLoader,
  },

  props: ['poolAddress'],

  data() {
    return {
      web3Instance: useWeb3(),

      poolInfo: null,
      whitelistInfo: {},
      contract: null,

      refreshLoadPools: null,
    };
  },

  computed: {
    isShowIdoPage() {
      if (!this.$root.account) {
        return false;
      }

      if (this.$root.chainId != this.poolInfo?.homeNetwork) {
        return false;
      }

      return true;
    },
  },

  watch: {
    'poolInfo': {
      immediate: true,
      async handler(newVal, oldVal) {
        if (!newVal) {
          return;
        }

        if (Object.keys(this.whitelistInfo).length > 0 && oldVal?.whitelistUrl == newVal.whitelistUrl) {
          return;
        }

        await this.loadWhitelistInfo(newVal.whitelistUrl);
      },
    },
    '$root.account': {
      immediate: true,
      handler() {
        this.loadPools();
      },
    },
  },

  created() {
    this.$eventBus.on('update-pool', () => {
      this.updatePoolInfoUserData();
    });
  },

  beforeUnmount() {
    this.$eventBus.off('update-pool', () => {
      this.updatePoolInfoUserData();
    });

    clearInterval(this.refreshLoadPools);
  },

  async mounted() {
    if (this.poolInfo.status === 'active') {
      await this.getPoolInfo();

      this.refreshLoadPools = setInterval(async () => {
        await this.getPoolInfo();
      }, 5000);
    } else {
      clearInterval(this.refreshLoadPools);
    }

    if (!this.isShowIdoPage) {
      this.connectToHomeChain();
    }
  },

  methods: {
    async connectToHomeChain() {
      if (this.$root.chainId != this.$root?.poolsInfoHash[this.poolAddress]?.homeNetwork) {
        await this.$root.setActiveChain(parseInt(this.$root?.poolsInfoHash[this.poolAddress]?.homeNetwork));
      }
    },

    loadPools() {
      this.poolInfo = null;
      this.poolInfo = JSON.parse(JSON.stringify(this.$root?.poolsInfoHash?.[this.poolAddress]));
    },

    async getPoolInfo() {
      if (this.isShowIdoPage && this.contract) {
        this.poolInfo.totalBuyAmount = (await this.contract.totalBuyAmount()).toString();
        this.poolInfo.totalSellAmount = (await this.contract.totalSellAmount()).toString();

        const maxFCFS = BigNumber(this.poolInfo.distributedBuyAmount)
          .minus(BigNumber(this.poolInfo.totalBuyAmount))
          .dividedBy(10 ** this.poolInfo.buyTokenDecimals)
          .dividedBy(100);

        this.poolInfo.maxFCFS = parseInt(maxFCFS.toString()) || 0;

        this.$eventBus.emit('update-progressbar');
        this.$eventBus.emit('update-max-fcfs');
      }
    },

    async updatePoolInfoUserData() {
      if (this.isShowIdoPage && this.poolAddress && this.poolAddress.startsWith('0x')) {
        if (!this.contract) {
          const signer = this.$root?.provider?.getSigner();

          this.contract = new ethers.Contract(this.poolAddress, PoolAbi, signer);
        }

        const boughtAmount = await this.getBoughtAmount();
        const idoCreator = await this.getIDOCreatorAdress();
        const claimAmount = await this.getAvaliableToClaimAmount();

        this.poolInfo.idoCreator = idoCreator;
        this.poolInfo.claimAmount = claimAmount;
        this.poolInfo.boughtAmount = boughtAmount;

        const signer = this.$root?.provider?.getSigner();
        const buyTokenContract = new ethers.Contract(this.poolInfo.buyTokenAddress, ERC20Abi, signer);
        const buyTokenBalance = await buyTokenContract.balanceOf(this.$root.account);
        this.poolInfo.buyTokenBalance = buyTokenBalance;

        const sellTokenContract = new ethers.Contract(this.poolInfo.sellTokenAddress, ERC20Abi, signer);
        this.poolInfo.sellTokenContract = sellTokenContract;

        const sellTokenBalance = await sellTokenContract.balanceOf(this.$root.account);
        this.poolInfo.sellTokenBalance = sellTokenBalance;

        return;
      }

      this.poolInfo.idoCreator = null;
      this.poolInfo.claimAmount = null;
      this.poolInfo.boughtAmount = null;
      this.poolInfo.buyTokenBalance = null;
      this.poolInfo.sellTokenBalance = null;
    },

    async loadWhitelistInfo(whitelistUrl) {
      if (!whitelistUrl) {
        return;
      }

      if (!this.$root.account) {
        return;
      }

      const signer = this.$root?.provider?.getSigner();
      const participants = await this.getWhitelist(whitelistUrl);

      this.contract = new ethers.Contract(this.poolAddress, PoolAbi, signer);
      this.whitelistInfo = {};

      this.poolInfo.participants = participants;

      this.whitelistInfo.participants = participants;

      if (Array.isArray(participants)) {
        const treeHashes = [];
        let signerHash = null;

        participants.forEach((participant) => {
          if (this.$root.account.toLowerCase() === participant.address.toLowerCase()) {
            this.whitelistInfo.allowedAllocation = participant.allowedAllocation;

            signerHash = this.merklePoolParticipantMaxAllocationHash(
              this.$root.account.toLowerCase(),
              BigNumber(participant.allowedAllocation).shiftedBy(this.poolInfo.buyTokenDecimals)
            );
          }

          treeHashes.push(
            this.merklePoolParticipantMaxAllocationHash(
              participant.address.toLowerCase(),
              BigNumber(participant.allowedAllocation).shiftedBy(this.poolInfo.buyTokenDecimals)
            )
          );
        });

        if (signerHash) {
          const merkleTree = new MerkleTree(treeHashes);
          this.poolInfo.whitelistProof = merkleTree.getHexProof(signerHash);
        }
      }

      await this.updatePoolInfoUserData();
    },

    merklePoolParticipantMaxAllocationHash(participant, maxAllocation) {
      const packed = solidityPack(['address', 'uint256'], [participant, '0x' + maxAllocation.toString(16)]);
      return Buffer.from(ethers.utils.arrayify(ethers.utils.keccak256(packed)));
    },

    async getWhitelist(URL) {
      if (!URL) {
        return;
      }

      try {
        const response = await axios.get(URL).catch((err) => {
          console.log('load whitelist ERR', err);
          return;
        });

        if (response.status !== 200) {
          console.log('Something wrong with status loading whitelist', response.status);
          return;
        }

        const whitelist = response?.data;

        let participants = whitelist.split('\n').map((row) =>
          row
            .trim()
            .replace(/(?:\r\n|\r|\n)/g, '')
            .split(',')
        );

        participants = participants.map((el) => ({ address: el[0], allowedAllocation: el[1] }));

        return participants;
      } catch (e) {
        console.log('Error with loading whitelist info', e);
        return;
      }
    },

    async getBoughtAmount() {
      let boughtAmount = 0;

      try {
        boughtAmount = await this.contract.buyAmountOf(this.$root.account);
      } catch (e) {
        console.log('getBoughtAmount: ', e);
      }

      return boughtAmount;
    },

    async getAvaliableToClaimAmount() {
      let claimAmount = 0;

      try {
        claimAmount = await this.contract.withdrawalAmountOf(this.$root.account);
      } catch (e) {
        console.log('getAvaliableToClaimAmount: ', e);
      }

      return claimAmount;
    },

    async getIDOCreatorAdress() {
      let creatorAddress = null;

      try {
        creatorAddress = await this.contract.creator();
      } catch (e) {
        console.log('getIDOCreatorAdress: ', e);
      }

      return creatorAddress?.toLowerCase();
    },
  },
};
</script>
