<template>
  <div class="container ball-shadow">
    <template v-if="isShowClaimPage">
      <h2>Claim PAD token</h2>

      <div class="row m-h--16 m-b-24">
        <div class="col-6 col-md-12 p-h-16 m-b-24">
          <div class="visual-box box-shadow">
            <h4 class="flex-wrap__wrap flex-h__between m-b-0">
              <span class="m-r-8">Available to claim:</span>
              <span class="text-multicolor">{{ $formatUnitsToken(availableToClaim, 18, 3, 'n/a') }} PAD</span>
            </h4>

            <div
              class="btn-big btn-multicolor width-full claiming-btn m-t-32"
              :class="{ 'disabled': !isClaimButtonActive || isClaimTransactionInProgress }"
              @click="claim"
            >
              {{ claimButtonText }}
            </div>
          </div>
        </div>

        <div class="col-6 col-md-12 p-h-16 m-b-24">
          <h4>How it works?</h4>

          <div class="info-text">
            SmartPad's migration to Aurora has been completed. The following balance of PAD is available for claiming on
            Aurora. Below you may find a table your old PAD distribution across different networks.
          </div>
        </div>
      </div>

      <h3>
        Total
        <span class="text-multicolor fw-500 m-r--6">PAD</span>
        s
      </h3>

      <div v-if="!isUpdateInProgress" class="table-claim">
        <div class="table-claim-row">
          <div class="col col-head col-start">Chain</div>
          <div class="col col-head">PAD on wallet</div>
          <div class="col col-head">PAD on staking</div>
          <div class="col col-head">PAD from presale</div>
          <div class="col col-head col-end">Total</div>
        </div>
        <div v-for="chainId in [1, 137, 1313161554]" :key="chainId" class="table-claim-row">
          <div class="col col-start">
            <span class="table-claim-img">
              <img :src="$root.allowedChains[chainId].icon" :alt="$root.allowedChains[chainId].name" />
            </span>
            {{ $root.allowedChains[chainId].name }}
          </div>

          <div class="col" data-name="PAD on wallet">
            {{ $formatUnitsToken(claimInfo[chainId]?.padBalanceOnWallet || 0) }}
          </div>
          <div class="col" data-name="PAD on staking">
            {{ $formatUnitsToken(claimInfo[chainId]?.padFromStakingWithRewards || 0) }}
          </div>
          <div class="col" data-name="PAD from presale">
            {{ $formatUnitsToken(claimInfo[chainId]?.padFromPresale) }}
          </div>
          <div class="col col-end" data-name="Total">
            {{ $formatPrice(getTotalByChain(chainId), 0, true, 6) }}
          </div>
        </div>
        <div class="table-claim-row">
          <div class="col col-foot col-start">Total</div>
          <div class="col col-foot">{{ $formatPrice(getTotalByType('padBalanceOnWallet'), 0, true, 6) }}</div>
          <div class="col col-foot">{{ $formatPrice(getTotalByType('padFromStakingWithRewards'), 0, true, 6) }}</div>
          <div class="col col-foot">{{ $formatPrice(getTotalByType('padFromPresale'), 0, true, 6) }}</div>
          <div class="col col-foot col-end" data-name="Total all">
            {{ $formatPrice(getTotalByType('all'), 0, true, 6) }}
          </div>
        </div>
      </div>
      <template v-else>
        <g-loader :key="$id('loading')" />
      </template>
    </template>

    <template v-else>
      <template v-if="!web3Instance.active">
        <div class="text-multicolor animation-blink">Please, connect wallet first.</div>

        <div class="btn-big btn-transp m-t-24" @click="connect">Connect wallet</div>
      </template>

      <template v-else>
        <div class="text-multicolor animation-blink">
          Please, connect to {{ $root.allowedChains[homeChain].name }} network first.
        </div>

        <div class="btn-big btn-transp m-t-24" @click="connectToHomeChain">
          Connect to {{ $root.allowedChains[homeChain].name }} network
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { ethers } from 'ethers';
import CFSAbi from '@/artifacts/claimFromSnapshot.json';
import BigNumber from 'bignumber.js/bignumber.mjs';
import { useWeb3 } from '../store/web3';
import GLoader from '@/components/g-loader.vue';

export default {
  name: 'ClaimPage',

  components: {
    GLoader,
  },

  data() {
    return {
      homeChain: process.env.VUE_APP_HOME_CHAIN,

      availableToClaim: null,
      CFSContract: null,
      alreadyClaimed: null,
      claimInfo: {},
      isClaimTransactionInProgress: false,
      isUpdateInProgress: true,

      web3Instance: useWeb3(),
    };
  },

  computed: {
    claimButtonText() {
      if (this.isClaimTransactionInProgress) {
        return 'Claiming...';
      }

      if (this.alreadyClaimed > 0) {
        return `Already claimed ${this.$formatUnitsToken(this.alreadyClaimed, 18, 3, 'n/a')} PAD`;
      }

      return this.isClaimButtonActive
        ? `Claim ${this.$formatUnitsToken(this.availableToClaim, 18, 3, 'n/a')} PAD`
        : 'No PAD available to claim';
    },

    isClaimButtonActive() {
      return this.alreadyClaimed == 0 && this.availableToClaim != 0;
    },

    isShowClaimPage() {
      if (!this.$root.account) {
        return false;
      }

      if (this.$root.chainId != this.homeChain) {
        return false;
      }

      return true;
    },
  },

  watch: {
    '$root.account': {
      immediate: true,
      async handler(account) {
        if (!account) {
          return;
        }

        await this.updateAccountInfo();
      },
    },
    '$root.chainId': {
      immediate: true,
      async handler(chainId) {
        if (!chainId) {
          return;
        }

        await this.updateAccountInfo();
      },
    },
  },

  mounted() {
    this.connectToHomeChain();
  },

  methods: {
    async connect() {
      await this.web3Instance.resetApp();
      await this.web3Instance.connect();
    },

    async connectToHomeChain() {
      if (this.$root.chainId !== this.homeChain) {
        await this.$root.setActiveChain(parseInt(this.homeChain));
      }
    },

    async getClaimInfo() {
      const response = await this.$json.get(process.env.VUE_APP_API_URL_PREFIX + '/claim/' + this.$root.account);

      if (response?.statusCode !== 200) {
        return;
      }
      if (!response?.json?.success) {
        return;
      }

      this.claimInfo = response.json.claimInfo;
    },

    async updateAccountInfo() {
      if (this.$root.chainId != this.homeChain) {
        return;
      }

      this.isUpdateInProgress = true;

      this.getClaimInfo();

      const signer = this.$root.provider?.getSigner();

      this.CFSContract = new ethers.Contract(
        this.$root.allowedChains[parseInt(this.$root.chainId)]?.claimContractAddress,
        CFSAbi,
        signer
      );

      const availableToClaim = await this.CFSContract.availableAmount(this.$root.account);
      this.availableToClaim = availableToClaim;

      const alreadyClaimed = await this.CFSContract.alreadyClaimedAmount(this.$root.account);
      this.alreadyClaimed = alreadyClaimed;

      this.isUpdateInProgress = false;
    },

    claim() {
      if (this.availableToClaim <= 0) {
        return;
      }

      this.isClaimTransactionInProgress = true;

      this.$root.transactions.listen({
        info: `Claim ${this.$formatUnitsToken(this.availableToClaim, 18, 3, 'n/a')} PAD`,
        transactionRequest: this.CFSContract.claim(),
        onDone: (isSuccess = false) => {
          this.isClaimTransactionInProgress = false;

          if (isSuccess) {
            this.updateAccountInfo();
          }
        },
      });
    },

    getTotalByType(key) {
      if (key == 'all') {
        return this.getTotalByChain(1).plus(this.getTotalByChain(137)).plus(this.getTotalByChain(1313161554));
      }

      let sum = BigNumber(0);

      Object.values(this.claimInfo).forEach((item) => {
        sum = sum.plus(BigNumber(item[key] || 0));
      });

      return sum.dividedBy(10 ** 18);
    },

    getTotalByChain(chainId) {
      return BigNumber(this.claimInfo[chainId]?.padBalanceOnWallet || 0)
        .plus(BigNumber(this.claimInfo[chainId]?.padFromStakingWithRewards || 0))
        .plus(BigNumber(this.claimInfo[chainId]?.padFromPresale || 0))
        .dividedBy(10 ** 18);
    },
  },
};
</script>
