<template>
  <div class="visual-box box-shadow">
    <ul class="steps-box">
      <li v-for="(item, index) in stepList" :key="index" @click="setActiveStep(index)">
        <g-tooltip
          v-if="item.tooltip"
          :position="getPositionArrow(index) || 'bottom'"
          :content="item.tooltip"
          :custom-icon="false"
          animation
        >
          <button class="arrow-box" :class="{ 'active': item.activ, 'error no-click': item.error }">
            <svg class="arrow-box-start" width="16" height="32" viewBox="0 0 16 32" xmlns="http://www.w3.org/2000/svg">
              <path d="m0.27969,-0.38813l15.36113,16.7002" />
              <path d="m15.63477,15.64308l-15.31248,16.7349" />
            </svg>
            {{ item.nameHr }}
            <sup :class="{ 'inline-block': item.error }">*</sup>
            <svg class="arrow-box-end" width="16" height="32" viewBox="0 0 16 32" xmlns="http://www.w3.org/2000/svg">
              <path d="m16.09501,0.4778l-15.02678,0.00786l14.57259,15.82642" />
              <path d="m15.65545,15.67989l-14.6446,15.85091l15.09948,-0.00911" />
            </svg>
          </button>
        </g-tooltip>
        <template v-else>
          <button class="arrow-box" :class="{ 'active': item.active, 'error no-click': item.error }">
            <svg class="arrow-box-start" width="16" height="32" viewBox="0 0 16 32" xmlns="http://www.w3.org/2000/svg">
              <path d="m0.27969,-0.38813l15.36113,16.7002" />
              <path d="m15.63477,15.64308l-15.31248,16.7349" />
            </svg>
            {{ item.nameHr }}
            <sup :class="{ 'inline-block': item.error }">*</sup>
            <svg class="arrow-box-end" width="16" height="32" viewBox="0 0 16 32" xmlns="http://www.w3.org/2000/svg">
              <path d="m16.09501,0.4778l-15.02678,0.00786l14.57259,15.82642" />
              <path d="m15.65545,15.67989l-14.6446,15.85091l15.09948,-0.00911" />
            </svg>
          </button>
        </template>
      </li>
    </ul>

    <transition-group name="toTop">
      <proposal-step-project
        v-show="activeName === 'project'"
        key="project"
        :proposal-fields="proposalFields"
        :is-error-proposal-fields="isErrorProposalFields"
        @fields-error="setStepsError($event, 'project')"
        @show-preview="$emit('show-preview')"
        @next-step="setActiveStep(1)"
      />

      <proposal-step-token
        v-show="activeName === 'token'"
        key="token"
        :proposal-fields="proposalFields"
        :is-error-proposal-fields="isErrorProposalFields"
        @fields-error="setStepsError($event, 'token')"
        @show-preview="$emit('show-preview')"
        @next-step="setActiveStep(2)"
      />

      <proposal-step-ido
        v-show="activeName === 'ido'"
        key="ido"
        :proposal-fields="proposalFields"
        :is-error-proposal-fields="isErrorProposalFields"
        @fields-error="setStepsError($event, 'ido')"
        @show-preview="$emit('show-preview')"
        @next-step="setActiveStep(3)"
      />

      <proposal-step-vesting
        v-show="activeName === 'vesting'"
        key="vesting"
        :proposal-fields="proposalFields"
        :is-error-proposal-fields="isErrorProposalFields"
        @fields-error="setStepsError($event, 'vesting')"
        @show-preview="$emit('show-preview')"
        @next-step="setActiveStep(4)"
      />

      <proposal-step-additional
        v-show="activeName === 'additional'"
        key="additional"
        :proposal-fields="proposalFields"
        :is-error-proposal-fields="isErrorProposalFields"
        @fields-error="setStepsError($event, 'additional')"
        @show-preview="$emit('show-preview')"
      />
    </transition-group>
  </div>
</template>

<script>
import ProposalStepProject from './component/steps/ProposalStepProject';
import ProposalStepToken from './component/steps/ProposalStepToken';
import ProposalStepIdo from './component/steps/ProposalStepIDO';
import ProposalStepVesting from './component/steps/ProposalStepVesting';
import ProposalStepAdditional from './component/steps/ProposalStepAdditional';

export default {
  name: 'ProposalSteps',

  components: {
    ProposalStepProject,
    ProposalStepToken,
    ProposalStepIdo,
    ProposalStepVesting,
    ProposalStepAdditional,
  },

  props: {
    proposalFields: {
      type: Object,
      required: true,
    },
  },

  emits: ['show-preview'],

  data: () => ({
    stepList: [
      {
        name: 'project',
        nameHr: 'Overview',
        active: false,
        error: false,
      },
      {
        name: 'token',
        nameHr: 'Token',
        active: false,
        error: false,
      },
      {
        name: 'ido',
        nameHr: 'IDO',
        active: false,
        error: false,
      },
      {
        name: 'vesting',
        nameHr: 'Vesting',
        active: false,
        error: false,
      },
      {
        name: 'additional',
        nameHr: 'Contacts',
        active: false,
        error: false,
      },
    ],
    activeName: 'project',
  }),

  computed: {
    isErrorProposalFields() {
      return Boolean(this.stepList.find((e) => e.error));
    },
  },

  mounted() {
    this.setActiveStep();
  },

  methods: {
    setActiveStep(index = null) {
      let indexActiveStep = index;

      const errorElem = this.stepList.find((e) => e.error);
      const isErrorStepList = !!errorElem;
      if (isErrorStepList) {
        this.activeName = errorElem.name;
      }

      if (index === null) {
        indexActiveStep = this.stepList.findIndex((item) => item.name === this.activeName);
      }

      this.stepList.forEach((step, index) => {
        step.active = false;

        if (index <= indexActiveStep) {
          step.active = true;
          this.activeName = step.name;
        }
      });
    },

    getPositionArrow(index) {
      let position = '';

      if (index <= 1) {
        position = 'bottom-start';
      } else if (index >= this.stepList.length - 2) {
        position = 'bottom-end';
      } else {
        position = 'bottom';
      }

      return position;
    },

    setStepsError(isError, nameStep) {
      this.stepList.find((item) => item.name == nameStep).error = isError;
    },
  },
};
</script>
