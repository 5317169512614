<template>
  <div class="time-scale" :class="{ 'error': isValidDate }">
    <input-period
      :selected-period="selectedPeriod"
      :width-scale-line="widthScaleLine"
      :min-period-value="minPeriodValue"
      :max-period-value="maxPeriodValue"
      :style="`left: ${widthScaleLine}%`"
      @change-data-input="changeDataInput"
      @change-period-type="changePeriodType"
    />

    <graph-period :time-intervals="timeIntervals" @change-data-graph="changeDataGraph" />
    <div class="time-scale-line" :style="`width: ${widthScaleLine}%`"></div>

    <div class="time-scale-error">New Unlock date can't be earlier than current Unlock date</div>
  </div>
</template>

<script>
import InputPeriod from '@DaoPage/InputPeriod';
import GraphPeriod from '@DaoPage/GraphPeriod';

const MIN_COUNT_DAYS = 30;
const MIN_COUNT_MONTHS = 1;
const MAX_COUNT_DAYS = 5 * 365;
const MAX_COUNT_MONTHS = 60;

const countOfDaysInOneMonth = 30.42;

export default {
  name: 'LockPeriod',

  components: {
    InputPeriod,
    GraphPeriod,
  },

  props: {
    isValidDate: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['changeData'],

  data() {
    return {
      selectedPeriod: {
        periodType: 'months',
        valueInMonths: 1,
        valueInDays: 30,
      },
      countOfDaysInOneMonth,
      widthScaleLine: 0,

      timeIntervals: [
        {
          countOfMonths: 1,
          labelHR: '1m',
          multiplier: 1.1,
          percent: 0,
        },
        {
          countOfMonths: 2,
          labelHR: '2m',
          multiplier: 1.2,
          percent: 11,
        },
        {
          countOfMonths: 3,
          labelHR: '3m',
          multiplier: 1.3,
          percent: 22,
        },
        {
          countOfMonths: 6,
          labelHR: '6m',
          multiplier: 1.6,
          percent: 33,
        },
        {
          countOfMonths: 12,
          labelHR: '1y',
          multiplier: 2,
          percent: 44,
        },
        {
          countOfMonths: 18,
          labelHR: '1.5y',
          multiplier: 3,
          percent: 51,
        },
        {
          countOfMonths: 24,
          labelHR: '2y',
          multiplier: 4,
          percent: 58,
        },
        {
          countOfMonths: 30,
          labelHR: '2.5y',
          multiplier: 5,
          percent: 65,
        },
        {
          countOfMonths: 36,
          labelHR: '3y',
          multiplier: 6,
          percent: 72,
        },
        {
          countOfMonths: 42,
          labelHR: '3.5y',
          multiplier: 7,
          percent: 79,
        },
        {
          countOfMonths: 48,
          labelHR: '4y',
          multiplier: 8,
          percent: 86,
        },
        {
          countOfMonths: 54,
          labelHR: '4.5y',
          multiplier: 9,
          percent: 94,
        },
        {
          countOfMonths: 60,
          labelHR: '5y',
          multiplier: 10,
          percent: 100,
        },
      ],
    };
  },

  computed: {
    minPeriodValue() {
      return this.selectedPeriod.periodType === 'months' ? MIN_COUNT_MONTHS : MIN_COUNT_DAYS;
    },

    maxPeriodValue() {
      return this.selectedPeriod.periodType === 'months' ? MAX_COUNT_MONTHS : MAX_COUNT_DAYS;
    },
  },

  mounted() {
    this.changeActiveItem();
  },

  methods: {
    getDaysFromMonths(n) {
      return parseInt(n * countOfDaysInOneMonth);
    },
    getMonthsFromDays(n) {
      return parseInt(n / MIN_COUNT_DAYS);
    },

    changeDataGraph(countOfMonth) {
      this.selectedPeriod.valueInMonths = countOfMonth;
      this.selectedPeriod.valueInDays = this.getDaysFromMonths(countOfMonth);

      this.setFullWidthScaleLine();
      this.$emit('changeData', this.selectedPeriod.valueInDays);
    },

    changeDataInput(val) {
      if (this.selectedPeriod.periodType === 'months') {
        let valueInMonths = val;

        if (val < this.minPeriodValue) {
          valueInMonths = this.minPeriodValue;
        } else if (val > this.maxPeriodValue) {
          valueInMonths = this.maxPeriodValue;
        }

        this.selectedPeriod.valueInDays = this.getDaysFromMonths(valueInMonths);
        this.selectedPeriod.valueInMonths = valueInMonths;
      }

      if (this.selectedPeriod.periodType === 'days') {
        let valueInDays = val;

        if (val < this.minPeriodValue) {
          valueInDays = this.minPeriodValue;
        } else if (val > this.maxPeriodValue) {
          valueInDays = this.maxPeriodValue;
        }

        this.selectedPeriod.valueInDays = valueInDays;
        this.selectedPeriod.valueInMonths = this.getMonthsFromDays(valueInDays);
      }

      this.selectedPeriod = JSON.parse(JSON.stringify(this.selectedPeriod));

      this.setFullWidthScaleLine();

      this.$emit('changeData', this.selectedPeriod.valueInDays);
    },

    changePeriodType(periodType) {
      if (periodType === this.selectedPeriod.periodType) {
        return;
      }

      if (periodType === 'months') {
        this.selectedPeriod.valueInDays = this.getDaysFromMonths(this.selectedPeriod.valueInMonths);
      }

      this.selectedPeriod.periodType = periodType;
    },

    setFullWidthScaleLine() {
      const prevItem = this.changeActiveItem();

      const nextItem =
        this.timeIntervals[this.timeIntervals.findIndex((item) => item.percent === prevItem.percent) + 1] ||
        this.timeIntervals[this.timeIntervals.length - 1];

      const countOfMonthsPassedAfterPrev = this.selectedPeriod.valueInMonths - prevItem.countOfMonths;

      if (!countOfMonthsPassedAfterPrev) {
        this.widthScaleLine = prevItem.percent;
        return;
      }

      const percent = nextItem.percent - prevItem.percent;
      const monthsDifference = nextItem.countOfMonths - prevItem.countOfMonths;

      this.widthScaleLine = prevItem.percent + (percent * countOfMonthsPassedAfterPrev) / monthsDifference;
    },

    changeActiveItem() {
      const arr = JSON.parse(JSON.stringify(this.timeIntervals));
      const filteredItems = arr
        .filter((e) => e.countOfMonths <= this.selectedPeriod.valueInMonths)
        .sort((a, b) => a.countOfMonths - b.countOfMonths);

      const prevItem = filteredItems[filteredItems.length - 1];

      this.timeIntervals.forEach((item) => {
        item.active = item.countOfMonths == this.selectedPeriod.valueInMonths;
      });

      return prevItem;
    },
  },
};
</script>
