const proposalFieldsJson = {
  /* START of STEP 1*/
  projectName: {
    label: 'Project name',
    value: '',
    type: 'text',
    placeholder: 'Enter name',
  },
  mediumLink: {
    label: 'Medium URL',
    value: '',
    type: 'text',
    placeholder: 'Enter link',
  },
  telegramLink: {
    label: 'Telegram URL',
    value: '',
    type: 'text',
    placeholder: 'Enter link',
  },
  twitterLink: {
    label: 'Twitter URL',
    value: '',
    type: 'text',
    placeholder: 'Enter link',
  },
  aboutProject: {
    label: 'Project description',
    value: '',
    type: 'text',
    placeholder: 'Enter project description',
  },
  websiteLink: {
    label: 'Website URL',
    value: '',
    type: 'text',
    placeholder: 'Enter link',
  },
  /* END of STEP 1*/

  /* START of STEP 2*/
  tokenSymbol: {
    label: 'Ticker symbol',
    value: '',
    type: 'text',
    placeholder: 'Enter ticker',
  },
  tokenLogo: {
    label: 'Logo URL',
    value: '',
    placeholder: 'Enter URL',
  },
  /* END of STEP 2*/

  /* START of STEP 3*/
  totalAllocation: {
    label: 'Total allocation',
    value: '100',
    type: 'text',
    placeholder: 'Enter allocation',
  },
  priceOneToken: {
    label: 'Token price',
    value: 0.5,
    type: 'number',
    placeholder: 'Enter price',
  },
  /* END of STEP 3*/

  createDateUTC: {
    label: 'Create time and date',
    value: null,
    type: 'dateTimePicker',
    objName: 'createdAtTimestamp',
  },
  // startDateUTC: {
  //   label: 'Start time and date',
  //   value: null,
  //   type: 'dateTimePicker',
  //   objName: 'startedAtTimestamp',
  // },
  // endDateUTC: {
  //   label: 'End time and date',
  //   value: null,
  //   type: 'dateTimePicker',
  //   objName: 'endedAtTimestamp',
  // },

  tgePercent: {
    label: 'Allocation available at TGE',
    value: 100,
    type: 'number',
    placeholder: 'Enter %',
    objName: 'tgePercent',
  },
  votingDateUTC: {
    label: 'Proposal voting deadline',
    value: null,
    type: 'dateTimePicker',
    icon: 'calendar',
    objName: 'new',
  },
  cookyLink: {
    label: 'Cooky link',
    value: '',
    type: 'text',
    placeholder: 'Enter link',
    objName: 'new',
  },
  grants: {
    label: 'Grants info',
    value: '',
    type: 'text',
    placeholder: 'Enter info about grants of your project',
    objName: 'new',
  },

  tgeDateUTC: {
    label: 'TGE date/time',
    value: null,
    type: 'dateTimePicker',
    icon: 'calendar',
    objName: 'new',
  },
  listing: {
    label: 'Listing on:',
    value: '',
    type: 'text',
    placeholder: 'Enter DEX',
    objName: 'new',
  },
  vesting: {
    label: 'Vesting duration',
    type: 'vesting',
    period: ['month', 'week'],
    countValue: 1,
    periodValue: 'month',
    percentageValue: 10,
    placeholder: 'Enter DEX',
    objName: 'new',
  },
  contactEmail: {
    label: 'Email',
    value: '',
    type: 'text',
    placeholder: 'Enter email',
    objName: 'new',
  },
  contactTg: {
    label: 'Telegram URL',
    value: '',
    type: 'text',
    placeholder: 'Enter your Telegram handle',
    objName: 'new',
  },
};

export { proposalFieldsJson };
