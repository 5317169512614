<template>
  <section class="first-screen ball-shadow">
    <div class="container">
      <div class="row">
        <div class="col-8 col-sm-12">
          <div class="main-title">
            <h1 class="m-b-0">SmartPad IDO</h1>
            <div v-if="$root.errorInfo" class="m-t-16" v-html="$root.errorInfo"></div>
          </div>
        </div>
        <div v-if="!$root.account" class="col-4 col-sm-12">
          <img src="/static/images/purse.svg" class="first-screen-img" alt="purse" />
        </div>
      </div>
    </div>
  </section>

  <card-list />
</template>

<script>
import CardList from '@PoolsPage/CardList';

export default {
  components: {
    CardList,
  },

  data: () => ({}),
};
</script>
