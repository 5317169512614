import { defineStore } from 'pinia';
import { ethers } from 'ethers';
import Web3Modal from 'web3modal';
import UAuthSPA from '@uauth/js';
import * as UAuthWeb3Modal from '@uauth/web3modal';
import { markRaw } from 'vue';

const uauthOptions = {
  clientID: process.env.VUE_APP_UAUTH_KEY,
  redirectUri: process.env.VUE_APP_REDIRECT_URL,
  shouldLoginWithRedirect: false,
  fallbackIssuer: 'https://auth.unstoppabledomains.com/',
  scope: 'openid wallet',
};

export const useWeb3 = defineStore('web3', {
  state: () => ({
    web3Modal: null,

    library: null,
    active: false,
    account: null,
    user: null,
    chainId: 0,
  }),

  actions: {
    async connect(transparent = false) {
      if (!this.web3Modal) {
        this.web3Modal = new Web3Modal({
          cacheProvider: true,
          theme: 'dark',
          providerOptions: {
            'custom-uauth': {
              display: UAuthWeb3Modal.display,
              connector: UAuthWeb3Modal.connector,
              package: UAuthSPA,
              options: uauthOptions,
            },
          },
        });

        UAuthWeb3Modal.registerWeb3Modal(this.web3modal);
      }

      // If this is a tranparent (no pop-up desired) call and we don't have a
      // cached provider return because it will trigger a pop-up
      if (transparent && !this.web3Modal.cachedProvider) {
        return;
      }

      const uauth = UAuthWeb3Modal.getUAuth(UAuthSPA, uauthOptions);

      let provider = null;
      try {
        const providerInstance = await this.web3Modal.connect();
        provider = markRaw(providerInstance);
      } catch (ex) {
        console.error('Connect error', ex);
      }

      if (this.web3Modal.cachedProvider == 'custom-uauth') {
        try {
          const user = await uauth.user();
          this.user = user;
        } catch (ex) {
          console.log(ex);
        }
      } else if (!this.user) {
        this.user = null;
      }

      const library = markRaw(new ethers.providers.Web3Provider(provider));

      this.library = library;

      const accounts = await library.listAccounts();
      if (accounts.length > 0) {
        this.account = accounts[0];
      }
      const network = await library.getNetwork();
      this.chainId = network.chainId;
      this.active = true;

      provider.on('connect', async (info) => {
        const chainId = parseInt(info.chainId);
        this.chainId = chainId;
      });

      provider.on('accountsChanged', async (accounts) => {
        if (accounts.length > 0) {
          this.account = accounts[0];
        } else {
          this.resetApp();
        }
      });
      provider.on('chainChanged', async (chainId) => {
        chainId = parseInt(chainId);
        this.chainId = chainId;
      });
    },

    async resetApp() {
      const uauth = UAuthWeb3Modal.getUAuth(UAuthSPA, uauthOptions);

      if (this.web3Modal.cachedProvider == 'custom-uauth') {
        try {
          await uauth.logout();
        } catch (ex) {
          console.log(ex);
        }
      }

      try {
        await this.web3Modal.clearCachedProvider();
      } catch (error) {
        console.error('resetApp', error);
      }

      this.account = null;
      this.user = null;
      this.active = false;
      this.library = null;
    },
  },
});
