<template>
  <div :ref="$id('dropdown')" class="tool-tip-icon-wrap">
    <template v-if="customIcon">
      <div class="icon-question"></div>
    </template>

    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'GTooltip',

  props: {
    position: {
      type: String,
      default: 'bottom',
    },

    arrow: {
      type: String,
      default: '',
    },

    content: {
      type: String,
      required: true,
    },

    contentAlign: {
      type: String,
      default: 'left',
    },

    classTooltip: {
      type: String,
      default: '',
    },

    customIcon: {
      type: Boolean,
      default: true,
    },

    animation: {
      type: Boolean,
      default: false,
    },

    tooltipMaxWidth: {
      type: Number,
      default: null,
    },

    canOverTooltip: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    jsToolTipWrap: null,
    space: 7,
    arrowSize: {
      normal: 8,
      small: 6,
    },

    iconWidth: 0,
    iconHeight: 0,
    top: 0,
    left: 0,
    newPosition: '',
  }),

  computed: {
    getArrowSize() {
      if (this.arrow === '') {
        return this.arrowSize['normal'];
      }
      return this.arrowSize[this.arrow] || this.arrowSize['normal'];
    },
  },

  mounted() {
    this.createItem();

    this.$nextTick(() => {
      this.$refs[this.$id('dropdown')].addEventListener('mouseover', this.showTooltip);
      this.$refs[this.$id('dropdown')].addEventListener('mouseleave', this.onMouseLeave);
    });
  },

  beforeUnmount() {
    this.hideTooltip();
    this.$refs[this.$id('dropdown')].removeEventListener('mouseover', this.showTooltip);
    this.$refs[this.$id('dropdown')].removeEventListener('mouseleave', this.onMouseLeave);
  },

  methods: {
    async showTooltip(e) {
      if (!this.content) {
        return;
      }
      const targetEl = e.currentTarget.getBoundingClientRect();
      this.iconWidth = targetEl.width;
      this.iconHeight = targetEl.height;
      this.top = targetEl.top + window.scrollY;
      this.left = targetEl.left + window.scrollX;

      this.newPosition = this.position;
      if (this.jsToolTipWrap !== null) {
        this.newPosition = this.autoPosition(this.position, targetEl);
      }

      await this.setPosition();
      this.setToolTip();

      window.addEventListener('resize', this.hideTooltip);
    },

    onMouseLeave(e) {
      if (this.canOverTooltip) {
        this.overTooltipHide(e.relatedTarget);
        return;
      }
      this.hideTooltip();
    },

    autoPosition(position, targetEl) {
      this.jsToolTipWrap.innerHTML = this.content;
      const { width, height } = this.jsToolTipWrap.getBoundingClientRect();
      const clientWidth = document.documentElement.clientWidth;
      const clientHeight = document.documentElement.clientHeight;
      const heightHeader = 100;
      const heightFooter = 34;

      const revertInitialPos = (initial, invert) => {
        const reg = new RegExp(initial, 'g');
        return position.replace(reg, invert);
      };

      if (position.includes('top') && targetEl.top < height + heightHeader) {
        return revertInitialPos('top', 'bottom');
      }
      if (position.includes('bottom') && clientHeight - targetEl.bottom < height + heightFooter) {
        return revertInitialPos('bottom', 'top');
      }
      if (position.includes('right') && clientWidth - targetEl.right < width) {
        return revertInitialPos('right', 'left');
      }
      if (position.includes('left') && targetEl.left < width) {
        return revertInitialPos('left', 'right');
      }

      return position;
    },

    setPosition() {
      if (this.newPosition.includes('top')) {
        this.left += this.iconWidth / 2;
        this.top -= this.getArrowSize + this.space + 3;
      }

      if (this.newPosition.includes('right')) {
        this.left += this.iconWidth + this.getArrowSize + this.space;
        this.top += this.iconHeight / 2;
      }

      if (this.newPosition.includes('bottom')) {
        this.left += this.iconWidth / 2;
        this.top += this.iconHeight + this.getArrowSize + this.space;
      }

      if (this.newPosition.includes('left')) {
        this.left -= this.getArrowSize + this.space;
        this.top += this.iconHeight / 2;
      }
    },

    overTooltipHide(relatedTarget) {
      if (relatedTarget?.id === 'jsToolTipWrap') {
        this.jsToolTipWrap.addEventListener('mouseleave', this.hideTooltip);
      } else {
        this.hideTooltip();
      }
    },

    hideTooltip() {
      if (this.jsToolTipWrap !== null) {
        this.jsToolTipWrap.style.opacity = 0;
        this.jsToolTipWrap.classList.remove(`at-${this.newPosition}`);
        this.jsToolTipWrap.classList.remove(`text-${this.contentAlign}`);
        if (this.arrow) {
          this.jsToolTipWrap.classList.remove(this.arrow);
        }
        if (this.animation) {
          this.jsToolTipWrap.classList.remove('animated');
        }
        if (this.classTooltip) {
          this.jsToolTipWrap.classList.remove(this.classTooltip);
        }
        this.jsToolTipWrap.style.top = '-1000px';
        this.jsToolTipWrap.style.left = '-1000px';
        this.jsToolTipWrap.innerHTML = ``;

        window.removeEventListener('resize', this.hideTooltip);
      }
    },

    setToolTip() {
      if (this.jsToolTipWrap !== null) {
        this.jsToolTipWrap.style.opacity = 1;
        this.jsToolTipWrap.classList.add(`at-${this.newPosition}`);
        this.jsToolTipWrap.classList.add(`text-${this.contentAlign}`);
        if (this.arrow) {
          this.jsToolTipWrap.classList.add(this.arrow);
        }
        if (this.classTooltip) {
          this.jsToolTipWrap.classList.add(this.classTooltip);
        }
        if (this.animation) {
          this.jsToolTipWrap.classList.add('animated');
        }
        if (this.tooltipMaxWidth) {
          this.jsToolTipWrap.style.maxWidth = `${this.tooltipMaxWidth}px`;
        } else {
          this.jsToolTipWrap.style.maxWidth = null;
        }
        this.jsToolTipWrap.style.top = `${Math.floor(this.top)}px`;
        this.jsToolTipWrap.style.left = `${Math.floor(this.left)}px`;
      }
    },

    createItem() {
      this.jsToolTipWrap = document.getElementById('jsToolTipWrap');

      if (this.jsToolTipWrap === null) {
        const newNode = document.createElement('div');
        newNode.setAttribute('id', 'jsToolTipWrap');
        newNode.className = `tool-tip-vue`;
        newNode.style.display = 'block';

        if (this.tooltipMaxWidth) {
          newNode.style.maxWidth = `${this.tooltipMaxWidth}px`;
        }

        newNode.style.opacity = 0;
        document.body.append(newNode);

        this.jsToolTipWrap = document.getElementById('jsToolTipWrap');
      }
    },
  },
};
</script>
