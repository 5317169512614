<template>
  <div class="visual-box box-shadow flex-direction__column height-full m-b-20">
    <div class="dao-page-box_wrap">
      <div class="dao-page-box_title">Lock aPAD</div>
      <div class="dao-page-box_subtitle">
        You have
        {{ $formatUnitsToken(maxLockAmount || null) }}
        aPAD balance
      </div>
    </div>

    <div class="form-field" :class="{ 'field-error': isLockAmountInvalid }">
      <g-auto-numeric v-model="lockAmount" />

      <div class="form-btn btn-transp" :class="{ 'disabled': maxLockAmount == 0 }" @click="setMaxLockAmount">Max</div>

      <div class="error">Insufficient balance</div>
    </div>

    <lock-period :is-valid-date="allowedTimestamp" @change-data="setPeriodInDays" />

    <hr class="dao-page-box-hr" />

    <div class="row">
      <div class="col-8">
        <div class="row">
          <div class="col-4">
            <div class="dao-page-box-title m-t-8 p-v-4">Multiplier:</div>
          </div>
          <div class="col-8">
            <div class="dao-page-box-result m-t-8">
              x{{ getLockMultiplier() }}
              <g-tooltip v-if="false" position="left" content="Lock multiplier" :custom-icon="false" animation>
                <div class="btn-only-icon-info m-l-8"></div>
              </g-tooltip>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <div class="dao-page-box-title m-t-8">You will receive:</div>
          </div>

          <div class="col-8">
            <div class="dao-page-box-result m-t-8">
              {{ $formatPrice(willReceive, 'n/a', true, 2) }} vPAD
              <g-tooltip v-if="false" position="top" content="Number of vPAD tokens" :custom-icon="false" animation>
                <div class="btn-only-icon-calc m-l-8"></div>
              </g-tooltip>
            </div>
          </div>
        </div>
      </div>

      <div class="col-4 flex-v__end">
        <div v-if="isApproveTransactionInProgress" class="btn-big btn-approve width-full animation-blink">
          Approving...
        </div>

        <button
          v-else-if="!isAllowanceGiven"
          class="btn-big btn-approve width-full"
          :class="{
            'disabled': lockAmount <= 0 || allowedTimestamp || isLockAmountInvalid || isApproveTransactionInProgress,
          }"
          @click="approve()"
        >
          Approve
        </button>

        <div v-else-if="isLockTransactionInProgress" class="btn-big btn-multicolor width-full animation-blink">
          Locking...
        </div>

        <div
          v-else
          class="btn-big btn-multicolor width-full"
          :class="{ 'disabled': lockAmount <= 0 || allowedTimestamp || isLockAmountInvalid }"
          @click="lock()"
        >
          Lock
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BigNumber from 'bignumber.js/bignumber.mjs';
import LockPeriod from '@DaoPage/LockPeriod';
import GAutoNumeric from '@/components/g-autonumeric.vue';
import aPADAbi from '@/artifacts/aPAD.json';
import { ethers } from 'ethers';
import GovernanceAbi from '@/artifacts/Governance.json';

export default {
  name: 'LockAPad',

  components: {
    GAutoNumeric,
    LockPeriod,
  },

  props: {
    allowedTimestamp: {
      type: Boolean,
      default: true,
    },
  },

  emits: ['on-close', 'update-goverance', 'days'],

  data: () => ({
    lockAmount: 0,
    maxLockAmount: 0,
    days: 0,
    lockMultiplier: 0,

    allowanceGiven: null,
    isApproveTransactionInProgress: false,
    isLockTransactionInProgress: false,
  }),

  computed: {
    isLockAmountInvalid() {
      const maxValue = BigNumber(ethers.utils.formatUnits(this.maxLockAmount?.toString(), 18));
      return BigNumber(this.lockAmount).isGreaterThan(maxValue);
    },

    isAllowanceGiven() {
      if (!this.allowanceGiven) {
        return false;
      }

      if (this.lockAmount === 'null' || this.lockAmount === null || !+this.lockAmount) {
        return false;
      }

      const lockAmount = BigNumber(this.lockAmount);

      return this.allowanceGiven.dividedBy(10 ** 18).isGreaterThanOrEqualTo(lockAmount);
    },

    willReceive() {
      return this.lockAmount * this.getLockMultiplier();
    },
  },

  watch: {
    '$root.account': {
      immediate: true,
      async handler(account) {
        if (!account) {
          return;
        }

        await this.updateAccountInfo();
      },
    },
    '$root.chainId': {
      immediate: true,
      async handler(chainId) {
        if (!chainId) {
          return;
        }

        await this.updateAccountInfo();
      },
    },
  },

  created() {
    this.setPeriodInDays(30);

    this.updateAccountInfo();
  },

  methods: {
    setPeriodInDays(val) {
      this.days = val;
      this.$emit('days', this.days);

      this.lockMultiplier = Math.floor(Math.sqrt(this.days * 24 * 60 * 60)) / Math.floor(Math.sqrt(30 * 24 * 60 * 60));
    },

    getLockMultiplier() {
      return this.lockMultiplier.toFixed(2);
    },

    async updateAccountInfo() {
      this.lockAmount = 0;
      this.maxLockAmount = 0;

      if (!this.$root.chainId) {
        await setTimeout(async () => await this.updateAccountInfo(), 1000);
        return;
      }

      const signer = this.$root.provider?.getSigner();

      const aPADContract = new ethers.Contract(
        this.$root.allowedChains[this.$root.chainId].aPadTokenContract,
        aPADAbi,
        signer
      );

      const allowance = await aPADContract.allowance(
        this.$root.account,
        this.$root.allowedChains[this.$root.chainId].governanceContractAddress
      );

      this.allowanceGiven = BigNumber(allowance.toString());

      this.maxLockAmount = await aPADContract.balanceOf(this.$root.account);
    },

    setMaxLockAmount() {
      this.lockAmount = ethers.utils.formatUnits(this.maxLockAmount?.toString(), 18);
    },

    async lock() {
      if (this.isLockAmountInvalid) {
        return;
      }

      if (this.allowedTimestamp) {
        return;
      }

      if (!this.isAllowanceGiven) {
        return;
      }

      this.isLockTransactionInProgress = true;

      const signer = this.$root.provider?.getSigner();

      const governanceContract = new ethers.Contract(
        this.$root.allowedChains[this.$root.chainId]?.governanceContractAddress,
        GovernanceAbi,
        signer
      );

      this.$root.transactions.listen({
        info: `Lock ${this.lockAmount} aPAD`,
        transactionRequest: governanceContract.lock(
          '0x' + BigNumber(this.lockAmount).shiftedBy(18).toString(16),
          this.days * 24 * 60 * 60
        ),
        onDone: async (isSuccess = false) => {
          this.isLockTransactionInProgress = false;

          if (isSuccess) {
            this.lockAmount = 0;
            await this.$emit('update-goverance');
            this.updateAccountInfo();
          }
        },
      });
    },

    async approve() {
      this.isApproveTransactionInProgress = true;

      const signer = this.$root.provider?.getSigner();

      const aPADContract = new ethers.Contract(
        this.$root.allowedChains[this.$root.chainId].aPadTokenContract,
        aPADAbi,
        signer
      );

      this.$root.transactions.listen({
        info: `Approve ${this.lockAmount} aPAD for lock`,
        transactionRequest: aPADContract.approve(
          this.$root.allowedChains[this.$root.chainId].governanceContractAddress,
          '0x' + BigNumber(this.lockAmount).shiftedBy(18).toString(16)
        ),
        onDone: (isSuccess = false) => {
          this.isApproveTransactionInProgress = false;

          if (isSuccess) {
            this.allowanceGiven = BigNumber(this.lockAmount).shiftedBy(18);
          }
        },
      });
    },
  },
};
</script>
