<template>
  <div class="progressbar" :class="{ 'progressbar-withoutAmounts': withAmounts }">
    <div class="progressbar-label" :style="`left: ${percent}%`">{{ percent + '%' }}</div>
    <div class="progressbar-line" :style="`width: ${percent}%`"></div>

    <div v-if="poolType == 'lottery'" class="progressbar-minAmount">
      {{ $formatAmount(minAmount, 'n/a', true) }} ETH
    </div>
    <div v-else class="progressbar-minAmount">{{ $formatAmount(minAmount) }}</div>

    <div v-if="poolType == 'lottery'" class="progressbar-maxAmount">
      {{ $formatAmount(maxAmount, 'n/a', true) }} ETH
    </div>
    <div v-else class="progressbar-maxAmount">{{ $formatAmount(maxAmount) }}</div>
  </div>
</template>

<script>
export default {
  name: 'GProgressBar',

  props: {
    minAmount: {
      default: null,
    },
    maxAmount: {
      default: null,
    },
    percent: {
      default: 0,
    },
    poolType: {
      default: null,
    },
  },

  data() {
    return {
      withAmounts: this.maxAmount && this.minAmount,
    };
  },
};
</script>
