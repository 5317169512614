<template>
  <div class="container">
    <div class="flex-direction__column flex-h__center flex-v__center">
      <h2 class="text-center m-b-44">Sorry, this page isn't exist</h2>

      <div class="info-box-error">
        <div class="info-box-error-bg">
          <svg viewBox="0 0 442 207" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_b_1044_32048)">
              <rect x="1" y="1" width="440" height="205" fill="url(#paint0_radial_1044_32048)" fill-opacity="0.2" />
              <rect
                x="1"
                y="1"
                width="440"
                height="205"
                stroke="url(#paint1_linear_1044_32048)"
                stroke-opacity="0.14"
              />
            </g>
            <g opacity="0.5" filter="url(#filter1_b_1044_32048)">
              <path d="M42 46H409" stroke="url(#paint2_linear_1044_32048)" stroke-opacity="0.14" />
            </g>
            <rect opacity="0.05" x="42" y="77" width="87" height="25" fill="#C4C4C4" />
            <rect opacity="0.05" x="319" y="81" width="87" height="25" fill="#C4C4C4" />
            <rect opacity="0.2" x="307.5" y="18.5" width="111" height="13" stroke="#199100" />
            <rect opacity="0.05" x="43" y="160" width="47" height="19" fill="#C4C4C4" />
            <rect opacity="0.05" x="42" y="117" width="87" height="9" fill="#C4C4C4" />
            <rect opacity="0.05" x="319" y="121" width="87" height="9" fill="#C4C4C4" />
            <rect opacity="0.05" x="42" y="140" width="87" height="9" fill="#C4C4C4" />
            <rect opacity="0.05" x="319" y="144" width="87" height="9" fill="#C4C4C4" />
            <circle opacity="0.2" cx="56" cy="25" r="11.5" stroke="#199100" />
            <defs>
              <filter
                id="filter0_b_1044_32048"
                x="-16.5"
                y="-16.5"
                width="475"
                height="240"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImage" stdDeviation="8.5" />
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1044_32048" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1044_32048" result="shape" />
              </filter>
              <filter
                id="filter1_b_1044_32048"
                x="25"
                y="28.5"
                width="401"
                height="35"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImage" stdDeviation="8.5" />
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1044_32048" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1044_32048" result="shape" />
              </filter>
              <radialGradient
                id="paint0_radial_1044_32048"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(491.564 162.256) rotate(173.597) scale(653.629 144.049)"
              >
                <stop stop-color="white" stop-opacity="0.4" />
                <stop offset="1" stop-color="white" stop-opacity="0.03" />
              </radialGradient>
              <linearGradient
                id="paint1_linear_1044_32048"
                x1="221"
                y1="1"
                x2="221"
                y2="206"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#F1F1F1" />
                <stop offset="1" stop-color="white" stop-opacity="0.33" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_1044_32048"
                x1="225.5"
                y1="46"
                x2="225.5"
                y2="47"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#F1F1F1" />
                <stop offset="1" stop-color="white" stop-opacity="0.33" />
              </linearGradient>
            </defs>
          </svg>
        </div>

        <span>404</span>

        <div class="info-box-error-search">
          <svg width="158" height="152" viewBox="0 0 158 152" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_b_1044_32063)">
              <path
                d="M139.667 70.6429C139.667 109.106 108.625 140.286 70.3333 140.286C32.0416 140.286 1 109.106 1 70.6429C1 32.1802 32.0416 1 70.3333 1C108.625 1 139.667 32.1802 139.667 70.6429Z"
                fill="url(#paint0_linear_1044_32063)"
                fill-opacity="0.23"
              />
              <path
                d="M119 120.196L157 151M139.667 70.6429C139.667 109.106 108.625 140.286 70.3333 140.286C32.0416 140.286 1 109.106 1 70.6429C1 32.1802 32.0416 1 70.3333 1C108.625 1 139.667 32.1802 139.667 70.6429Z"
                stroke="url(#paint1_linear_1044_32063)"
              />
            </g>
            <defs>
              <filter
                id="filter0_b_1044_32063"
                x="-16.5"
                y="-16.5"
                width="190.815"
                height="184.887"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImage" stdDeviation="8.5" />
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1044_32063" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1044_32063" result="shape" />
              </filter>
              <linearGradient
                id="paint0_linear_1044_32063"
                x1="79"
                y1="0.999988"
                x2="155.228"
                y2="34.581"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#33B415" />
                <stop offset="1" stop-color="#237A0E" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_1044_32063"
                x1="79"
                y1="0.999988"
                x2="155.228"
                y2="34.581"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#33B415" />
                <stop offset="1" stop-color="#237A0E" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>

      <a href="/" class="btn-bigger btn-multicolor invert">Go home</a>
    </div>
  </div>
</template>
