<template>
  <div class="vesting-info box-shadow">
    <div class="box-header">Vesting info</div>

    <div class="row m-t--16">
      <div class="col-6 m-t-16" :class="{ 'vesting-info-col-3': isPreview }">
        <div class="field-info-title">TGE time and date (local)</div>

        <template v-if="poolInfo?.tgeAtTimestamp">
          <div class="date-main">
            <span>
              <g-date :timestamp="poolInfo?.tgeAtTimestamp" part="date" />
            </span>
            <g-date :timestamp="poolInfo?.tgeAtTimestamp" part="time" />
          </div>

          <div class="date-UTC">
            <g-date :timestamp="poolInfo?.tgeAtTimestamp" part="datetime" :utc="true" />
            (UTC)
          </div>
        </template>
        <span v-else>n/a</span>
      </div>

      <div class="col-6 m-t-16" :class="{ 'vesting-info-col-3': isPreview }">
        <div class="field-info-title">TGE unlock %</div>
        <div class="basic-info">{{ poolInfo?.tgePercent }}%</div>
      </div>

      <div v-if="poolInfo?.tgePercent != 100" class="col-6 m-t-16" :class="{ 'vesting-info-col-3': isPreview }">
        <div class="field-info-title">Vesting period</div>
        <div class="basic-info">{{ poolInfo?.vesting.countValue }} {{ poolInfo?.vesting.periodValue }}</div>
      </div>

      <div v-if="poolInfo?.tgePercent != 100" class="col-6 m-t-16" :class="{ 'vesting-info-col-3': isPreview }">
        <div class="field-info-title">Unlock %</div>
        <div class="basic-info">{{ poolInfo?.vesting.percentageValue }}% every {{ poolInfo?.vesting.periodValue }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProposalVestingInfo',

  props: {
    poolInfo: {
      type: Object,
      required: true,
    },

    isPreview: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
