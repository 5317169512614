<template>
  <header
    class="header-menu"
    :class="{ 'move-top': moveTop, 'bg-blur': bgBlur, 'bg-opaque': isOpaque, 'active': isShowMenu }"
  >
    <focus-trap ref="focusTrapHeader" v-model:active="isActiveFocusTrapHeader">
      <div class="container">
        <div class="header-wrap">
          <button
            v-if="!isDesktop"
            class="main-menu-burger"
            :class="{ 'active': isShowMenu }"
            @click="
              (isShowMenu = !isShowMenu),
                () => (isShowMenu ? $refs.focusTrapHeader.deactivate() : $refs.focusTrapHeader.activate())
            "
          >
            <div class="main-menu-burger-in">
              <div class="main-menu-burger_1"></div>
              <div class="main-menu-burger_2"></div>
              <div class="main-menu-burger_3"></div>
            </div>
          </button>

          <div class="logo">
            <a href="/">
              <img class="logo-long" src="/static/images/logo-long.svg" alt="logo" height="35" />
              <img class="logo-mobile" src="/static/images/logo.svg" alt="logo" height="35" />
            </a>
          </div>

          <ul class="main-menu" :class="{ 'main-menu-mobile': !isDesktop }">
            <li @click="isShowMenu = false">
              <router-link to="/pools" :class="{ 'router-link-active': $route.params.poolAddress }">IDO</router-link>
            </li>
            <li @click="isShowMenu = false">
              <router-link :to="{ name: 'StakingPage' }">Staking</router-link>
            </li>
            <li @click="isShowMenu = false">
              <router-link :to="{ name: 'ClaimPage' }">Claim</router-link>
            </li>
            <li @click="isShowMenu = false">
              <router-link :to="{ name: 'DaoPage' }">DAO</router-link>
            </li>
            <li @click="isShowMenu = false">
              <router-link :to="{ name: 'CalendarPage' }">Calendar</router-link>
            </li>
            <li @click="isShowMenu = false">
              <router-link :to="{ name: 'ProposalsPage' }">Proposals</router-link>
            </li>
            <li v-if="false" @click="$vfm.show('withdrawToken'), (isShowMenu = false)"><a href="#">Modal</a></li>
          </ul>

          <metamask-button :is-desktop="isDesktop" />
        </div>
      </div>
    </focus-trap>
  </header>
</template>

<script>
import MetamaskButton from '@/components/MetamaskButton';

export default {
  name: 'TheHeader',

  components: {
    MetamaskButton,
  },

  setup() {},

  data: () => ({
    lastScrollTop: 0,
    moveTop: false,
    bgBlur: false,
    isDesktop: false,
    isShowMenu: false,
    isOpaque: false,
    isActiveFocusTrapHeader: false,
  }),

  watch: {
    isShowMenu: {
      immediate: true,

      handler(val) {
        this.isActiveFocusTrapHeader = val;

        if (this.isDesktop || this.isShowMenu) return;

        this.bgBlur = false;
        this.isOpaque = true;

        setTimeout(() => {
          this.bgBlur = true;
          this.isOpaque = false;
        }, 400);
      },
    },
  },

  mounted() {
    window.addEventListener('scroll', this.headerScroll);
    window.addEventListener('resize', this.resizeWidth);
    this.resizeWidth();
  },

  beforeUnmount() {
    window.removeEventListener('scroll', this.headerScroll);
    window.removeEventListener('resize', this.resizeWidth);
  },

  methods: {
    headerScroll() {
      const scrollTop = window.pageYOffset;

      this.moveTop = scrollTop > this.lastScrollTop;

      if (this.$route.name == 'CalendarPage') {
        return;
      }

      this.bgBlur = scrollTop != 0;

      this.lastScrollTop = +scrollTop > 48 ? +scrollTop : 48;
    },

    resizeWidth() {
      const clientWidth = document.body.clientWidth;
      this.isDesktop = clientWidth >= 768;
    },
  },
};
</script>
