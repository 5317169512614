<template>
  <div class="chart">
    <div :class="['info-arrow', setClass]" :style="`left: ${percent <= 100 ? percent : 100}%;`">
      <span class="parsent">{{ percent }}%</span>
      <span class="usdt">{{ $formatPrice(options.raised, 0, true, 1) }} {{ options.sellTokenSymbol }}</span>
    </div>
    <div class="chart-line">
      <div class="active-line" :style="`width: ${percent <= 100 ? percent : 100}%;`"></div>
      <ul class="point-line">
        <li v-for="item in countLine" :key="item"></li>
      </ul>
    </div>
    <div class="chart-text-row">
      <div class="chart-start">0 {{ options.sellTokenSymbol }}</div>
      <div class="chart-end">{{ $formatPrice(options.max, 'n/a', true, 0) }} {{ options.sellTokenSymbol }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IdoProgressBar',

  props: {
    options: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    countLine: 9,
  }),

  computed: {
    percent() {
      return Math.round((this.options.raised * 100 * 100) / this.options.max) / 100 || 0;
    },

    setClass() {
      if (this.percent < 12) {
        return 'left';
      }

      return this.percent > 88 ? 'right' : '';
    },
  },
};
</script>
