<template>
  {{ getFormattedDate }}
</template>

<script>
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export default {
  name: 'GDate',

  props: {
    timestamp: {
      type: [Number, String],
      required: true,
    },
    part: {
      type: String,
      default: 'date', // [date, time, datetime]
    },
    withSeconds: {
      type: [Boolean],
      default: false,
    },
    utc: {
      type: [Boolean],
      default: false,
    },
  },

  computed: {
    getFormattedDate() {
      let date = dayjs.unix(+this.timestamp);

      if (this.utc) {
        date = date.utc();
      }

      switch (this.part) {
        case 'date':
          date = date.format('DD.MM.YYYY');
          break;
        case 'time':
          if (this.withSeconds) {
            date = date.format('HH:mm:ss');
          } else {
            date = date.format('HH:mm');
          }

          break;
        default:
          if (this.withSeconds) {
            date = date.format('DD.MM.YYYY\xa0\xa0HH:mm:ss');
          } else {
            date = date.format('DD.MM.YYYY\xa0\xa0HH:mm');
          }

          break;
      }

      return date;
    },
  },
};
</script>
