<template>
  <div
    v-for="(item, index) in timeIntervals"
    :key="item"
    :class="['item', { 'active': item.active, 'first': !index, 'last': index === timeIntervals.length - 1 }]"
    :style="`left: ${item.percent}%`"
    @click="set(item)"
  >
    <div class="time-scale-text">{{ item.labelHR }}</div>
  </div>
</template>

<script>
export default {
  name: 'GraphPeriod',

  props: {
    timeIntervals: {
      type: Array,
      required: true,
    },
  },

  emits: ['changeDataGraph'],

  methods: {
    set(item) {
      if (item.active) {
        return;
      }

      this.$emit('changeDataGraph', item.countOfMonths);
    },
  },
};
</script>
