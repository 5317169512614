<template>
  <div class="visual-box box-shadow flex-direction__column height-full m-b-20">
    <div class="row m-b-16">
      <div class="col-8">
        <div class="dao-page-box_title">vPAD balance</div>
        <div class="dao-page-box_balance">{{ $formatUnitsToken(lockInfo?.votes || null, 18, 2) }} vPAD</div>
      </div>
      <div class="col-4">
        <div class="dao-page-box_locked">
          <div class="icon-locked">
            <img src="/static/images/lock.svg" alt="lock" />
          </div>

          <div class="title">Locked:</div>

          <div class="data">{{ $formatUnitsToken(lockInfo?.amount || null) }} aPAD</div>
        </div>
      </div>
    </div>

    <div class="lock-unlock-date-row">
      <div v-if="lockInfo?.unlockTimestamp > 0" class="lock-date">
        <span class="title">Unlock date:</span>
        <div class="flex-wrap__wrap flex-v__baseline">
          <div class="date-main">
            <span>
              <g-date :timestamp="lockInfo.unlockTimestamp" part="date" />
            </span>
            <g-date :timestamp="lockInfo.unlockTimestamp" part="time" />
          </div>

          <div class="date-UTC">
            <g-date :timestamp="lockInfo.unlockTimestamp" part="datetime" :utc="true" />
            (UTC)
          </div>
        </div>
      </div>

      <div v-if="getNewUnlockDate" class="lock-date">
        <span class="title">Unlock date:</span>
        <div class="flex-wrap__wrap flex-v__baseline">
          <div class="date-main">
            <span>
              <g-date :timestamp="getNewUnlockDate" part="date" />
            </span>
            <g-date :timestamp="getNewUnlockDate" part="time" />
          </div>

          <div class="date-UTC">
            <g-date :timestamp="getNewUnlockDate" part="datetime" :utc="true" />
            (UTC)
          </div>
        </div>
      </div>
    </div>

    <button class="btn-big btn-multicolor invert width-full m-t-16" :disabled="true" @click="$vfm.show('unlockVPad')">
      Unlock is unavailable
    </button>

    <v-pad-unlock-modal :lock-info="lockInfo" />
  </div>
</template>

<script>
import VPadUnlockModal from '@DaoPage/VPadUnlockModal';

export default {
  name: 'VPadBalance',

  components: {
    VPadUnlockModal,
  },

  props: {
    lockInfo: {
      type: Object,
      default: () => {},
    },

    allowedTimestamp: {
      type: Boolean,
      default: true,
    },

    days: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    getNewUnlockDate() {
      const oneDayInMs = 24 * 60 * 60;

      const now = new Date();
      const nowTimestamp = now.getTime() / 1000;

      const unlockTimestamp = +this.lockInfo?.unlockTimestamp?.toString();
      const daysDiff = this.days - Math.ceil((unlockTimestamp - nowTimestamp) / oneDayInMs);

      if (daysDiff <= 0) {
        return false;
      }

      if (!unlockTimestamp) {
        now.setDate(now.getDate() + this.days);
        return now.getTime() / 1000;
      }

      return unlockTimestamp + daysDiff * oneDayInMs;
    },
  },
};
</script>
