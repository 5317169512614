<template>
  <div class="container proposal-page">
    <div class="flex-wrap__wrap">
      <h2 class="stake-page-title">Proposals</h2>
    </div>

    <template v-if="!isShowPage">
      <template v-if="!web3Instance.active">
        <div class="text-multicolor animation-blink">Please, connect wallet first.</div>

        <div class="btn-big btn-transp m-t-24 m-r-a" @click="connect">Connect wallet</div>
      </template>

      <template v-else>
        <div class="text-multicolor animation-blink">
          Please, connect to {{ $root.allowedChains[homeChain].name }} network first.
        </div>

        <div class="btn-big btn-transp m-t-24 m-r-a" @click="connectToHomeChain">
          Connect to {{ $root.allowedChains[homeChain].name }} network
        </div>
      </template>
    </template>

    <template v-else>
      <div class="first-screen ball-shadow">
        <div class="row m-b-24">
          <div class="col-12 col-sm-12">
            <div class="flex-h__end">
              <router-link
                class="btn-proposal"
                :to="{ name: 'CreateProposalsIDO', params: { proposalAddress: 'new' } }"
              >
                <span>+</span>
                Create proposal
              </router-link>
            </div>
          </div>
        </div>

        <div v-if="isLoading" class="text-multicolor animation-blink">Proposals are loading</div>
        <div v-else-if="proposals.length == 0" class="text-multicolor animation-blink">Proposals list is empty</div>
        <proposals-list v-else :list="proposals" @sort-column="sort" />
      </div>
    </template>
  </div>
</template>

<script>
import ProposalsList from './ProposalsPage/ProposalsList';
import { ethers } from 'ethers';
import GovernanceAbi from '@/artifacts/Governance.json';
import BigNumber from 'bignumber.js/bignumber.mjs';
import { useWeb3 } from '../store/web3';

export default {
  components: {
    ProposalsList,
  },

  data: () => ({
    homeChain: process.env.VUE_APP_HOME_CHAIN,
    web3Instance: useWeb3(),

    proposals: {},
    isLoading: false,
  }),

  computed: {
    isShowPage() {
      if (!this.$root.account) {
        return false;
      }

      if (this.$root.chainId != this.homeChain) {
        return false;
      }

      return true;
    },
  },

  watch: {
    '$root.account': {
      immediate: true,
      async handler(account) {
        if (!account) {
          return;
        }

        await this.loadProposals();
      },
    },
    '$root.chainId': {
      immediate: true,
      async handler(chainId) {
        if (!chainId) {
          return;
        }

        await this.loadProposals();
      },
    },
  },

  mounted() {
    this.connectToHomeChain();
  },

  methods: {
    async connect() {
      await this.web3Instance.resetApp();
      await this.web3Instance.connect();
    },

    async connectToHomeChain() {
      if (this.$root.chainId != this.homeChain) {
        await this.$root.setActiveChain(parseInt(this.homeChain));
      }
    },

    async loadProposals() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      if (!this.$root.chainId) {
        this.isLoading = false;
        await setTimeout(async () => await this.loadProposals(), 1000);
        return;
      }

      const response = await this.$json.get(process.env.VUE_APP_API_URL_PREFIX + '/proposals');

      this.isLoading = false;
      if (!response.json?.success) {
        return;
      }

      const proposals = Object.values(response.json.proposals);

      const signer = this.$root.provider?.getSigner();

      const governanceContract = new ethers.Contract(
        this.$root.allowedChains[this.$root.chainId]?.governanceContractAddress,
        GovernanceAbi,
        signer
      );

      for (const proposal of proposals) {
        const info = await governanceContract.proposals(proposal.createdAtTimestamp);

        const positiveVotes = parseInt(
          BigNumber(info.positiveVotes.toString())
            .dividedBy(10 ** 18)
            .toString(),
          10
        );

        const negativeVotes = parseInt(
          BigNumber(info.negativeVotes.toString())
            .dividedBy(10 ** 18)
            .toString(),
          10
        );

        proposal.info = {
          positiveVotes,
          negativeVotes,
        };
      }

      this.proposals = proposals;
      this.$root.proposals = JSON.parse(JSON.stringify(response.json.proposals));
    },

    sort([nameField, isAsc]) {
      if (!Array.isArray(this.proposals)) {
        return;
      }

      if (nameField == 'name') {
        if (isAsc) {
          this.proposals.sort((a, b) => b[nameField].localeCompare(a[nameField]));
        } else {
          this.proposals.sort((a, b) => a[nameField].localeCompare(b[nameField]));
        }

        return;
      }

      if (nameField == 'votes') {
        if (isAsc) {
          this.proposals.sort(
            (a, b) => b.info.positiveVotes - b.info.negativeVotes - (a.info.positiveVotes - a.info.negativeVotes)
          );
        } else {
          this.proposals.sort(
            (a, b) => a.info.positiveVotes - a.info.negativeVotes - (b.info.positiveVotes - b.info.negativeVotes)
          );
        }

        return;
      }

      if (isAsc) {
        this.proposals.sort((a, b) => b[nameField] - a[nameField]);
      } else {
        this.proposals.sort((a, b) => a[nameField] - b[nameField]);
      }
    },
  },
};
</script>
