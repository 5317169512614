<template>
  <g-modal v-model="isShowModal" name="unlockVPad" :click-to-close="true" :width="580" @close-modal="closeModal">
    <template #header>
      <div class="dao-page-box_wrap">
        <div class="dao-page-box_title">Unlock vPAD</div>
        <div class="dao-page-box_subtitle">
          You have
          {{ $formatUnitsToken(maxUnlockAmount, 18, 2) }}
          vPAD
        </div>
      </div>
    </template>

    <template #content>
      <div class="form-field m-t-8 ido-step-form-field" :class="{ 'field-error': isUnlockAmountInvalid }">
        <g-autonumeric v-model="unlockAmount" />

        <button class="form-btn btn-transp" :class="{ 'disabled': maxUnlockAmount == 0 }" @click="setMaxUnlockAmount">
          Max
        </button>

        <div class="error">Insufficient balance</div>

        <div v-if="willGetToken" class="predict-amount">
          You will receive&nbsp;
          <div class="inline-block text-multicolor">
            {{ $formatPrice(willGetToken, null, true, 6) }}
            vPAD
          </div>
        </div>
      </div>

      <div
        v-if="isApproveTransactionInProgress"
        class="btn-big btn-approve width-full btn-font-normal no-click animation-blink m-t-16"
      >
        Approving...
      </div>

      <button
        v-else-if="!isAllowanceGiven"
        class="btn-big btn-approve width-full btn-font-normal m-t-16"
        :class="{
          'disabled': unlockAmount <= 0 || isUnlockAmountInvalid || isApproveTransactionInProgress,
        }"
        @click="approve()"
      >
        Approve
      </button>

      <div
        v-else-if="isUnlockTransactionInProgress"
        class="btn-big btn-multicolor width-full btn-font-normal no-click animation-blink m-t-16"
      >
        Locking...
      </div>

      <div
        v-else
        class="btn-big btn-multicolor width-full btn-font-normal m-t-16"
        :class="{ 'disabled': unlockAmount <= 0 || isUnlockAmountInvalid }"
        @click="lock()"
      >
        Lock
      </div>
    </template>
  </g-modal>
</template>

<script>
import GAutonumeric from '@/components/g-autonumeric.vue';
import BigNumber from 'bignumber.js/bignumber.mjs';

export default {
  name: 'VPadUnlockModal',

  components: {
    GAutonumeric,
  },

  props: {
    lockInfo: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    unlockAmount: 0,

    isShowModal: true,
    isBuyTransactionInProgress: false,

    allowanceGiven: null,
    isApproveTransactionInProgress: false,
    isUnlockTransactionInProgress: false,
  }),

  computed: {
    isUnlockAmountInvalid() {
      const maxValue = BigNumber(this.$formatUnitsToken(this.maxUnlockAmount, 18, 2));
      return BigNumber(this.unlockAmount).isGreaterThan(maxValue);
    },

    isAllowanceGiven() {
      if (!this.allowanceGiven) {
        return false;
      }

      if (this.unlockAmount === 'null' || this.unlockAmount === null || !+this.unlockAmount) {
        return false;
      }

      const unlockAmount = BigNumber(this.unlockAmount);

      return this.allowanceGiven.dividedBy(10 ** 18).isGreaterThanOrEqualTo(unlockAmount);
    },

    willGetToken() {
      if (!+this.unlockAmount) {
        return null;
      }

      return this.unlockAmount;
    },

    maxUnlockAmount() {
      return this.lockInfo?.votes || null;
    },
  },

  methods: {
    showModal() {
      this.$vfm.show('unlockVPad');
    },

    closeModal() {
      this.$vfm.hide('unlockVPad');
    },

    setMaxUnlockAmount() {
      this.unlockAmount = this.$formatUnitsToken(this.maxUnlockAmount, 18, 2);
    },

    async lock() {
      if (this.isUnlockAmountInvalid) {
        return;
      }

      if (!this.isAllowanceGiven) {
        return;
      }

      this.isUnlockTransactionInProgress = true;
      //FIXME:
    },

    async approve() {
      this.isApproveTransactionInProgress = true;
      //FIXME:
    },
  },
};
</script>
