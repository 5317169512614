<template>
  <div class="custom-action-row">
    <p class="current-selection">{{ date }}</p>
    <button class="select-button" @click="$emit('selectDate')">Select Date</button>
  </div>
</template>

<script>
export default {
  name: 'ActionRow',

  props: {
    selectText: { type: String, default: 'Select' },
    cancelText: { type: String, default: 'Cancel' },
    internalModelValue: { type: [Date, Array], default: null },
    range: { type: Boolean, default: false },
    previewFormat: {
      type: [String, Function],
      default: () => '',
    },
    monthPicker: { type: Boolean, default: false },
    timePicker: { type: Boolean, default: false },
  },

  emits: ['selectDate', 'closePicker'],

  data: () => ({}),

  computed: {
    date() {
      if (this.internalModelValue) {
        const date = this.internalModelValue.getDate();
        const month = this.internalModelValue.getMonth() + 1;
        const year = this.internalModelValue.getFullYear();
        const hours = this.internalModelValue.getHours();
        const minutes = this.internalModelValue.getMinutes();

        return `${date}.${month}.${year}, ${hours}:${minutes}`;
      }
      return '';
    },
  },
};
</script>
