<template>
  <img src="/static/images/loader.svg" alt="loader" />
</template>

<script>
export default {
  name: 'GLoader',
};
</script>

<style scoped>
img {
  margin: auto;
  height: 16px;
  display: block;
}
</style>
