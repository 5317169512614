<template>
  <div class="calculator">
    <div class="calculator_header">
      <h3>Calculate</h3>
    </div>

    <div class="flex-wrap__wrap visual-box box-shadow m-b-32">
      <div class="col-10 col-md-12">
        <div class="row">
          <div class="calculator-item">
            <div class="form-field">
              <div class="calculator_label">
                <img src="/static/images/usdt.svg" alt="USDT" class="calculator_label-coin" />
                <span>USDT</span>
              </div>

              <g-auto-numeric
                v-model="amountInUSDT"
                class="calculator_input"
                :options="{ decimalPlaces: 6 }"
                @input="onInputUSDT()"
              />
            </div>
          </div>

          <div class="calculator_equal"></div>

          <div class="calculator-item">
            <div class="form-field">
              <div class="calculator_label">
                <img src="/static/images/pad-coin.svg" alt="PAD" class="calculator_label-coin" />
                <span>PAD</span>
              </div>

              <g-auto-numeric
                v-model="amountInPad"
                class="calculator_input"
                :options="{ decimalPlaces: 6 }"
                @input="onInputPAD()"
              />
            </div>
          </div>

          <div class="calculator_equal"></div>

          <div class="calculator-item">
            <div class="form-field">
              <div class="calculator_label">
                <img src="/static/images/aPad-coin.svg" alt="aPAD" class="calculator_label-coin" />
                <span>aPAD</span>
              </div>

              <g-auto-numeric
                v-model="amountInAPad"
                class="calculator_input"
                :options="{ decimalPlaces: 6 }"
                @input="onInputAPAD()"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="col-2 col-md-12">
        <a :href="linkBuy" target="_blank" class="btn-big btn-border calculator_btn-buy">
          <span>Buy PAD</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import GAutoNumeric from '@/components/g-autonumeric.vue';

export default {
  name: 'StakingCalculator',

  components: {
    GAutoNumeric,
  },

  props: ['poolInfo'],

  data: () => ({
    amountInUSDT: 0,
    amountInPad: 0,
    amountInAPad: 0,
  }),

  computed: {
    oneAPadInPad() {
      return this.poolInfo[this.$root.chainId]?.oneAPadInPad;
    },

    padPrice() {
      return this.poolInfo[this.$root.chainId]?.padPrice;
    },

    linkBuy() {
      if (!this.poolInfo[this.$root.chainId]?.dexUrl) {
        return;
      }

      return this.poolInfo[this.$root.chainId]?.dexUrl;
    },
  },

  watch: {
    '$root.account': {
      immediate: true,

      async handler(newAccount, oldAccount) {
        if (newAccount == oldAccount) {
          return;
        }

        await this.clearAmount();
      },
    },

    '$root.chainId': {
      immediate: true,

      async handler(newChainId, oldChainId) {
        if (newChainId == oldChainId) {
          return;
        }

        await this.clearAmount();
      },
    },
  },

  methods: {
    checkPriceToken() {
      if (!this.padPrice || !this.oneAPadInPad) {
        this.clearAmount();

        return false;
      }

      return true;
    },

    clearAmount() {
      this.amountInUSDT = 0;
      this.amountInPad = 0;
      this.amountInAPad = 0;
    },

    onInputUSDT() {
      if (!this.checkPriceToken()) {
        return;
      }

      this.refreshPAD('USDT');
      this.refreshAPAD('USDT');
    },

    onInputPAD() {
      if (!this.checkPriceToken()) {
        return;
      }

      this.refreshUSDT('PAD');
      this.refreshAPAD('PAD');
    },

    onInputAPAD() {
      if (!this.checkPriceToken()) {
        return;
      }

      this.refreshUSDT('aPAD');
      this.refreshPAD('aPAD');
    },

    refreshUSDT(name) {
      if (name == 'PAD') {
        this.amountInUSDT = +this.amountInPad * this.padPrice;
      }
      if (name == 'aPAD') {
        this.amountInUSDT = +this.amountInAPad * this.padPrice * this.oneAPadInPad;
      }
    },

    refreshPAD(name) {
      if (name == 'USDT') {
        this.amountInPad = +this.amountInUSDT / this.padPrice;
      }
      if (name == 'aPAD') {
        this.amountInPad = +this.amountInAPad * this.oneAPadInPad;
      }
    },

    refreshAPAD(name) {
      if (name == 'USDT') {
        this.amountInAPad = +this.amountInUSDT / (this.oneAPadInPad * this.padPrice);
      }
      if (name == 'PAD') {
        this.amountInAPad = +this.amountInPad / this.oneAPadInPad;
      }
    },
  },
};
</script>
