<template>
  <g-modal
    v-model="isShowModal"
    name="transactionList"
    :click-to-close="true"
    :is-show-close-button="true"
    :width="700"
    @close-modal="closeModal"
  >
    <template #header>
      <h3 class="m-b-36 small">Transactions:</h3>
    </template>

    <template #content>
      <ul class="transaction-list">
        <li v-for="(transaction, key) in transactionList" :key="key" class="transaction-list-row">
          <div class="transaction-list-date">{{ $getFormattedDateTime(transaction.createdAt) }}</div>

          <div class="transaction-list-hash" :class="{ 'copied': transaction.hash == currentCopiedHash }">
            <a v-if="transaction.hash" :href="getUrl(transaction.hash)" target="_blank" class="link">
              {{ transaction.info }}
            </a>
            <template v-else>
              {{ transaction.info }}
            </template>

            <g-tooltip class="m-l-12" position="top" content="Copy hash" :custom-icon="false" animation>
              <button
                class="btn-only-icon-copy space-4"
                :class="{ 'copied': isHashCopied == currentCopiedHash }"
                @click="
                  (e) => {
                    setIsCopiedHash(transaction.hash);
                    return copy(transaction.hash, e);
                  }
                "
              ></button>
            </g-tooltip>
          </div>

          <div class="transaction-list-state" :class="`transaction-list-state-${transaction.state}`">
            {{ transaction.state }}
          </div>
        </li>
      </ul>

      <a
        v-if="Object.keys(transactionList).length"
        href="#"
        class="btn-medium btn-multicolor invert width-full m-t-20"
        @click.prevent="$root.transactions.clear()"
      >
        Clear all
      </a>

      <div v-else>No transactions yet.</div>
    </template>
  </g-modal>
</template>

<script>
export default {
  name: 'TransactionList',

  data: () => ({
    isShowModal: true,
    isHashCopied: false,
    currentCopiedHash: null,
  }),

  computed: {
    transactionList() {
      return this.$root.transactions.list?.[this.$root.account] || {};
    },
  },

  methods: {
    showModal() {
      this.$vfm.show('transactionList');
    },

    closeModal() {
      this.$vfm.hide('transactionList');
    },

    setIsCopiedHash(hash) {
      this.currentCopiedHash = hash;

      setTimeout(() => {
        this.currentCopiedHash = null;
      }, 1000);
    },

    copy(text, event) {
      const el = event.target;

      navigator.clipboard
        .writeText(text)
        .then(() => {
          el.classList.add('copied');
          setTimeout(() => el.classList.remove('copied'), 2000);
        })
        .catch(() => {
          /* do nothing */
        });
    },
    getUrl(txHash) {
      return this.$root.allowedChains[this.$root.chainId]?.explorerUrl + 'tx/' + txHash;
    },
  },
};
</script>
