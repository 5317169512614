import { createApp } from 'vue';
import router from './router.js';
import { createPinia } from 'pinia';
import App from './App';
import mitt from 'mitt';
import vueUniqueId from './vueUniqueId';
import './assets/styles/main.scss';
import { vfmPlugin } from 'vue-final-modal';
import jsonClient from './jsonClient.js';
import formatters from './formatters.js';

import GDropdown from '@/components/g-dropdown.vue';
import GTooltip from '@/components/g-tooltip.vue';
import GModal from '@/components/g-modal.vue';
import GBanner from '@/components/g-banner.vue';
import { FocusTrap } from 'focus-trap-vue';
import GDate from '@/components/g-date.vue';
import GFormField from '@/components/g-form-field.vue';
import GDatePicker from '@/components/g-date-picker.vue';

const eventBus = mitt();

const app = createApp(App);

app.config.globalProperties.$eventBus = eventBus;

app
  .use(createPinia())
  .use(router)
  .use(vueUniqueId)
  .use(jsonClient)
  .use(formatters)
  .use(
    vfmPlugin({
      key: '$vfm',
      componentName: 'VueFinalModal',
      dynamicContainerName: 'ModalsContainer',
    })
  );

app.component('GDropdown', GDropdown);
app.component('GTooltip', GTooltip);
app.component('GModal', GModal);
app.component('GBanner', GBanner);
app.component('FocusTrap', FocusTrap);
app.component('GDate', GDate);
app.component('GFormField', GFormField);
app.component('GDatePicker', GDatePicker);

app.mount('#app');
