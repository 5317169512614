import { createWebHistory, createRouter } from 'vue-router';
import PoolsPage from '@/views/PoolsPage';
import IdoPage from '@/views/IdoPage';
import PageNotFound from '@/views/PageNotFound';
import StakingPage from '@/views/StakingPage';
import CalendarPage from '@/views/CalendarPage';
import ClaimPage from '@/views/ClaimPage';
import DaoPage from '@/views/DaoPage';
import ProposalsPage from '@/views/ProposalsPage';
import CreateProposalsIDO from '@/views/ProposalsPage/CreateProposalsIDO';
import ViewProposalsIDO from '@/views/ProposalsPage/ViewProposalsIDO';

const routes = [
  {
    path: '/',
    redirect: '/pools',
  },
  {
    path: '/pools',
    name: 'PoolsPage',
    component: PoolsPage,
  },
  {
    path: '/pool/:poolAddress',
    name: 'IdoPage',
    component: IdoPage,
    props: true,
  },
  {
    path: '/staking',
    name: 'StakingPage',
    component: StakingPage,
  },
  {
    path: '/claim',
    name: 'ClaimPage',
    component: ClaimPage,
  },
  {
    path: '/dao',
    name: 'DaoPage',
    component: DaoPage,
  },
  {
    path: '/calendar',
    name: 'CalendarPage',
    component: CalendarPage,
  },
  {
    path: '/proposals',
    name: 'ProposalsPage',
    component: ProposalsPage,
  },
  {
    path: '/proposals/:proposalAddress',
    name: 'CreateProposalsIDO',
    component: CreateProposalsIDO,
    props: true,
  },
  {
    path: '/proposals/view/:createdAt',
    name: 'ViewProposalsIDO',
    component: ViewProposalsIDO,
    props: true,
  },
  {
    path: '/:catchAll(.*)', // Unrecognized path automatically matches 404
    component: PageNotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ left: 0, top: 0 });
      }, 50);
    });
  },
});

export default router;
