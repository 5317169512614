<template>
  <div class="container proposal-page">
    <template v-if="!isShowPage">
      <template v-if="!web3Instance.active">
        <div class="text-multicolor animation-blink">Please, connect wallet first.</div>

        <div class="btn-big btn-transp m-t-24 m-r-a" @click="connect">Connect wallet</div>
      </template>

      <template v-else>
        <div class="text-multicolor animation-blink">
          Please, connect to {{ $root.allowedChains[homeChain].name }} network first.
        </div>

        <div class="btn-big btn-transp m-t-24 m-r-a" @click="connectToHomeChain">
          Connect to {{ $root.allowedChains[homeChain].name }} network
        </div>
      </template>
    </template>

    <section v-else class="first-screen ball-shadow">
      <template v-if="createdAt">
        <div class="row">
          <div class="col-7 col-md-12 p-h-16 m-b-32">
            <proposal-prime-info :pool-info="proposalInfo" />
          </div>
          <div class="col-5 col-md-12 p-h-16 m-b-32">
            <proposal-voting :pool-info="proposalInfo" />
          </div>
        </div>

        <div class="row">
          <div class="col-7 col-md-12 p-h-16 m-b-32">
            <proposal-vesting-info :pool-info="proposalInfo" />
          </div>
          <div class="col-5 col-md-12 p-h-16 m-b-32">
            <proposal-ido-info :pool-info="proposalInfo" />
          </div>
        </div>
      </template>

      <template v-else>
        <g-loader :key="$id('loading')" />
      </template>
    </section>
  </div>
</template>

<script>
import ProposalPrimeInfo from './component/ProposalPrimeInfo';
import ProposalVoting from './component/ProposalVoting';
import ProposalVestingInfo from './component/ProposalVestingInfo';
import ProposalIdoInfo from './component/ProposalIdoInfo';
import GLoader from '@/components/g-loader.vue';
import { useWeb3 } from '../../store/web3';

export default {
  name: 'ViewProposalsIDO',

  components: {
    ProposalPrimeInfo,
    ProposalVoting,
    ProposalVestingInfo,
    ProposalIdoInfo,
    GLoader,
  },

  props: ['createdAt'],

  data() {
    return {
      homeChain: process.env.VUE_APP_HOME_CHAIN,
      web3Instance: useWeb3(),
    };
  },

  computed: {
    isShowPage() {
      if (!this.$root.account) {
        return false;
      }

      if (this.$root.chainId != this.homeChain) {
        return false;
      }

      return true;
    },

    proposalInfo() {
      if (!this.$root.proposals || Object.keys(this.$root.proposals).length <= 0) {
        this.$eventBus.emit('load-proposals');
        return;
      }

      return this.$root.proposals[this.createdAt] || null;
    },
  },

  mounted() {
    this.connectToHomeChain();
  },

  methods: {
    async connect() {
      await this.web3Instance.resetApp();
      await this.web3Instance.connect();
    },

    async connectToHomeChain() {
      if (this.$root.chainId != this.homeChain) {
        await this.$root.setActiveChain(parseInt(this.homeChain));
      }
    },
  },
};
</script>
