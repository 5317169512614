import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';

const TRANSACTION_LIST_LENGTH = 6;

const STATE = {
  INITIAL: 'initial',
  SENDING: 'sending',
  PENDING: 'pending',
  ERROR: 'error',
  SUCCESS: 'success',
};

export const useTransactions = defineStore('transactions', {
  state: () => ({
    list: useStorage('smartpad_transactions_v2', {}),
    account: null,
    isTransactionInProgress: false,
  }),

  getters: {
    pendingTransactionsCount() {
      if (!this.account || !this.list[this.account]) {
        return 0;
      }

      return Object.values(this.list[this.account]).filter(
        (tx) => tx.state == STATE.PENDING || tx.state == STATE.SENDING
      ).length;
    },

    isMetamaskOpened() {
      if (!this.account || !this.list[this.account]) {
        return false;
      }

      return (
        Object.values(this.list[this.account]).filter((tx) => tx.state == STATE.INITIAL || tx.state == STATE.SENDING)
          .length > 0
      );
    },

    isMetamaskBusy() {
      return this.isTransactionInProgress || this.isMetamaskOpen;
    },
  },

  actions: {
    register({ id, details }) {
      if (!this.list[this.account]) {
        this.list[this.account] = {};
      }

      this.list[this.account][id] = details;

      if (Object.keys(this.list[this.account]).length > TRANSACTION_LIST_LENGTH) {
        const id = Object.keys(this.list[this.account]).sort()[0];
        delete this.list[this.account][id];
      }
    },

    updateDetails({ id, details }) {
      if (this.list[this.account]?.[id]) {
        Object.assign(this.list[this.account][id], details);
      }
    },

    unregister({ id }) {
      if (this.list[this.account]?.[id]) {
        delete this.list[this.account][id];
      }
    },

    clear() {
      if (!this.list[this.account]) {
        return;
      }

      const keysLength = Object.keys(this.list[this.account]).length;

      for (const id in this.list[this.account]) {
        if (this.list[this.account][id].state == STATE.ERROR || this.list[this.account][id].state == STATE.SUCCESS) {
          delete this.list[this.account][id];
        }
      }

      if (Object.keys(this.list[this.account]).length === keysLength) {
        this.list[this.account] = {};
      }
    },

    async _listenTransactionRequest({ transactionRequest }) {
      let transactionResponse = null;
      try {
        transactionResponse = await transactionRequest;
      } catch (error) {
        if (error.code == 4001) {
          // user rejected
          return null;
        }

        console.log(error);

        throw error;
      }

      return transactionResponse;
    },

    async _listenTransactionResponse({ transactionResponse }) {
      let transactionResult = null;

      try {
        transactionResult = await transactionResponse.wait();
      } catch (error) {
        if (error.code == 4001) {
          // user rejected
          return null;
        }

        throw error;
      }

      return transactionResult;
    },

    async listen({
      info,
      promiEvent,
      transactionRequest,
      onHash = () => {},
      onDone = () => {},
      onCancel = () => {},
      onSuccess = () => {},
      onError = () => {},
    }) {
      if (this.isTransactionInProgress) {
        return false;
      }

      const id = Date.now();

      this.register({ id, details: { state: STATE.INITIAL, createdAt: id / 1000, info } });
      this.isTransactionInProgress = true;
      if (promiEvent) {
        this._listenPromiEvent({ id, promiEvent }); // not really working anymore
        return;
      }

      this.updateDetails({ id, details: { state: STATE.SENDING } });

      let transactionResponse = null;

      try {
        transactionResponse = await this._listenTransactionRequest({ id, transactionRequest });
      } catch (error) {
        console.error(error);
        this.updateDetails({ id, details: { state: STATE.ERROR, error } });
        this.isTransactionInProgress = false;
        await onError(error);
        await onDone();
        return;
      }

      if (transactionResponse === null) {
        this.unregister({ id });
        this.isTransactionInProgress = false;
        await onCancel();
        await onDone();
        return;
      }

      this.updateDetails({ id, details: { state: STATE.PENDING, hash: transactionResponse.hash } });

      await onHash(transactionResponse.hash);

      let transactionResult = null;

      try {
        transactionResult = await this._listenTransactionResponse({ id, transactionResponse });
      } catch (error) {
        console.error(error);
        this.updateDetails({ id, details: { state: STATE.ERROR, error } });
        this.isTransactionInProgress = false;
        await onError(error);
        await onDone();
        return;
      }

      if (transactionResult === null) {
        this.unregister({ id });
        this.isTransactionInProgress = false;
        await onCancel();
        await onDone();
        return;
      }

      this.updateDetails({ id, details: { state: STATE.SUCCESS } });

      this.isTransactionInProgress = false;
      await onSuccess(transactionResult);
      await onDone(true);
    },
  },
});
